import { queryClient } from "~/lib/query";
import store from "~/lib/store";
import meQuery from "~/query/meQuery";
import { dealershipAtom } from "~/state";

export const postAuthenticationAction = async () => {
  const me = await queryClient.fetchQuery(meQuery());
  const first = me.me?.dealershipPerms?.at(0);

  if (first && first.dealershipId && first.dealershipName && first.role) {
    store.instance.set(dealershipAtom, {
      activeDealershipPerms: {
        dealershipId: first.dealershipId,
        dealershipName: first.dealershipName,
        role: first.role,
      },
    });
  }
};
