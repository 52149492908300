import classNames from "classnames";
import { ReactNode } from "react";

// Wrapper for Borders, rounded, and shadow
export default function PlainCard({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        "rounded-radius-02 border border-tertiary w-full bg-primary",
        className
      )}
    >
      {children}
    </div>
  );
}
