import classNames from "classnames";

export default function MonthlyProgress({
  current,
  target,
  type,
}: {
  current: number;
  target: number;
  type: "THIS_MONTH" | "LAST_MONTH" | "LAST_2_MONTH";
}) {
  const progressPercentage = Math.min((current / target) * 100, 100);

  return (
    <div className="flex items-center space-x-spacing-02">
      <div className="relative flex-1 h-2 bg-tertiary rounded-full overflow-hidden">
        <div
          className={classNames(
            "absolute inset-0  transition-all duration-300 rounded-full",
            {
              "bg-primary-blue": type === "THIS_MONTH",
              "bg-gray-600": type === "LAST_MONTH",
              "bg-gray-700": type === "LAST_2_MONTH",
            }
          )}
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
      <span className="text-primary font-bold">{current}</span>
    </div>
  );
}
