import { Menu, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { Fragment } from "react";
import { Outlet } from "react-router";
import logoutAction from "~/actions/logoutAction";
import LogoIcon from "~/components/icons/LogoIcon";
import { getInitials } from "~/lib/helpers";
import meQuery from "~/query/meQuery";
import { Link } from "~/router";

export default function Layout() {
  const { data } = useQuery(meQuery());

  const me = data?.me;

  return (
    <main className="flex min-h-dvh max-h-dvh flex-col space-y-10 bg-[#F8F9FA] font-roboto px-16 overflow-y-scroll">
      <nav className="flex flex-col items-center space-y-11">
        <div className="flex flex-row">
          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-[#F8F9FA] flex flex-1 rounded-tr-full" />
          </div>

          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-[#F8F9FA] flex flex-1 rounded-tl-full" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row space-x-6">
            <Link to={"/dashboard"} className="relative w-24">
              <LogoIcon className="h-12 text-primary-blue" />
            </Link>
          </div>

          <div className="flex flex-row space-x-6 items-center">
            <div className="flex">
              <Menu
                as="div"
                className="inline-block relative text-left font-roboto"
              >
                <Menu.Button className="flex w-14 aspect-square bg-light-gray items-center justify-center text-base text-dark-gray font-medium rounded-full overflow-hidden focus:outline-none">
                  <span>
                    {getInitials(
                      me?.user?.firstName ?? undefined,
                      me?.user?.lastName ?? undefined
                    )}
                  </span>
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-light-gray rounded-lg bg-white shadow-lg focus:outline-none w-40 z-dropdown">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to={"/profile"}>
                          <button
                            className={classNames(
                              "text-body w-full px-4 py-2 text-start text-dark-gray",
                              {
                                "bg-very-light-blue": active,
                              }
                            )}
                          >
                            Profile
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => void logoutAction()}
                          className={classNames(
                            "text-body w-full px-4 py-2 text-start text-dark-gray",
                            {
                              "bg-very-light-blue": active,
                            }
                          )}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </main>
  );
}
