import { Tab } from "@headlessui/react";
import {
  ArrowDownward,
  ArrowUpward,
  InfoOutlined,
  TrendingDown,
  TrendingUp,
} from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import {
  DateRangePicker,
  Spinner,
  Tooltip,
} from "@thedealersconcierge/components";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import {
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth,
  subMonths,
} from "date-fns";
import { useAtomValue } from "jotai";
import { useState } from "react";
import {
  FinanceType,
  TransactionSource,
  TransactionStatus,
  VehicleLifeCycleStage,
} from "~/__generated__/backend/zeus";
import { useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import { useGetAnalytics } from "../_hooks/useGetAnalytics";
import PlainCard from "./_components/PlainCard";
import RankingCard from "./_components/RankingCard";
import { transactionUpdateSalesRanking } from "./_queries/analyticsQuery";

export default function ReportPage() {
  const navigate = useNavigate();
  const dealership = useAtomValue(dealershipAtom);

  // Start date and end date will be set automatically based on the Date Range picker initial value
  const [startDate, setStartDateFilter] = useState<Date | undefined>();
  const [endDate, setEndDateFilter] = useState<Date | undefined>();

  // Used to switch ranking based on the tab
  const [rankingTabStatus, setRankingTabStatus] = useState<TransactionStatus>(
    TransactionStatus.DELIVERED
  );
  const statusTabs: TransactionStatus[] = [
    TransactionStatus.DELIVERED,
    TransactionStatus.REGISTERED,
  ];
  const tabs = statusTabs.map(getReadableTransactionStatus);

  // Helper to get the previous month based on supplied start date
  const getPreviousMonth = (subMonth: number, startDate?: Date) => {
    const date = subMonths(startDate ?? new Date(), subMonth);
    return {
      start: startOfMonth(date),
      end: endOfMonth(date),
    };
  };

  const getFormattedDateRange = (
    selectedStartDate?: Date,
    selectedEndDate?: Date
  ) => {
    const formatDate = (date?: Date) => {
      if (!date) return "";
      return format(date, "MMMM d, yyyy");
    };

    if (selectedStartDate && selectedEndDate) {
      return `${formatDate(selectedStartDate)} - ${formatDate(
        selectedEndDate
      )}`;
    }

    return "";
  };

  // Total Counts
  const {
    count: totalDeliveredThisMonth,
    isLoading: totalDeliveredThisMonthLoading,
  } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  const {
    count: totalDeliveredLastMonth,
    isLoading: totalDeliveredLastMonthLoading,
  } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: getPreviousMonth(1, startDate).start,
    endDate: getPreviousMonth(1, startDate).end,
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  const {
    count: totalDeliveredLast2Month,
    isLoading: totalDeliveredLast2MonthLoading,
  } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: getPreviousMonth(2, startDate).start,
    endDate: getPreviousMonth(2, startDate).end,
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  const { count: totalWebLeads, isLoading: totalWebLeadsLoading } =
    useGetAnalytics({
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      startDate: startDate,
      endDate: endDate,
      transactionStatus: [TransactionStatus.REGISTERED],
      source: [TransactionSource.WEB_PREQUAL],
    });

  const { count: totalPrequal, isLoading: totalPrequalLoading } =
    useGetAnalytics({
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      startDate: startDate,
      endDate: endDate,
      transactionStatus: [TransactionStatus.PREQUAL_SUBMITTED],
    });

  const {
    count: totalCreditSubmitted,
    isLoading: totalCreditSubmmitedLoading,
  } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    transactionStatus: [TransactionStatus.CREDIT_APP_SUBMITTED],
  });

  const { count: lboCount, isLoading: lboCountLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    isLbo: true,
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  // By Source
  const { count: showroomUps, isLoading: showRoomupsLoading } = useGetAnalytics(
    {
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      startDate: startDate,
      endDate: endDate,
      source: [TransactionSource.KIOSK],
      transactionStatus: [TransactionStatus.DELIVERED],
    }
  );

  const { count: webLeads, isLoading: webLeadsLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    source: [TransactionSource.WEB_PREQUAL],
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  // Web App + Dashboard
  const { count: startFromHome, isLoading: startFromHomeLoading } =
    useGetAnalytics({
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      startDate: startDate,
      endDate: endDate,
      source: [
        TransactionSource.ADMIN_DASHBOARD,
        TransactionSource.WEB_APPLICATION,
        TransactionSource.WEB_PREQUAL,
      ],
      transactionStatus: [TransactionStatus.DELIVERED],
    });

  // By Finance Type
  const { count: financeCount, isLoading: financeCountLoading } =
    useGetAnalytics({
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      startDate: startDate,
      endDate: endDate,
      financeType: [FinanceType.FINANCE],
      transactionStatus: [TransactionStatus.DELIVERED],
    });

  const { count: cashCount, isLoading: cashCountLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    financeType: [FinanceType.CASH],
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  const { count: leaseCount, isLoading: leaseCountLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    financeType: [FinanceType.LEASE],
    transactionStatus: [TransactionStatus.DELIVERED],
  });

  // Vehicles
  const { count: usedCount, isLoading: usedCountLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    transactionStatus: [TransactionStatus.DELIVERED],
    purchaseVehicleLifeCycleStage: VehicleLifeCycleStage.IS_USED,
  });

  const { count: newCount, isLoading: newCountLoading } = useGetAnalytics({
    dealershipId: dealership?.activeDealershipPerms.dealershipId,
    startDate: startDate,
    endDate: endDate,
    transactionStatus: [TransactionStatus.DELIVERED],
    purchaseVehicleLifeCycleStage: VehicleLifeCycleStage.IS_NEW,
  });

  // Rankings
  const { data: salesRankingData, isLoading: salesRankingLoading } = useQuery(
    transactionUpdateSalesRanking({
      startDate: startDate,
      transactionStatus: [rankingTabStatus],
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      page: 1,
      pageSize: 5,
    })
  );

  const salesRanking =
    salesRankingData?.dealership?.transactionUpdateStatusSalesRankingGrouped ??
    [];

  const maxCount =
    salesRanking.length > 0
      ? salesRanking.reduce(
          (max, item) => Math.max(max, item.currentMonth ?? 0),
          0
        )
      : 0;

  // Get unique counts and sort it descendingly
  // Used to get computed ranks
  const sortedRankCounts = Array.from(
    new Set(salesRanking.map((item) => item.currentMonth ?? 0))
  ).sort((a, b) => b - a);

  /**
   * Compares two numbers and returns a trend indicator (UP | DOWN)
   *
   * @param value - The primary value.
   * @param  comparator - The value to compare against.
   *
   * Example usage:
   * console.log(getTrend(120, 100)); // "UP"
   * console.log(getTrend(80, 100));  // "DOWN"
   * console.log(getTrend(100, 100));  // "SAME"
   */
  const getTrend = (
    value?: number,
    comparator?: number
  ): "UP" | "DOWN" | "SAME" => {
    if (value == null || comparator == null || value === comparator)
      return "SAME";
    if (value > comparator) return "UP";
    if (value < comparator) return "DOWN";

    // Default return "SAME" this indicates that there is no changes
    return "SAME";
  };

  const thisMonthTrend = getTrend(
    totalDeliveredThisMonth,
    totalDeliveredLastMonth
  );

  const lastMonthTrend = getTrend(
    totalDeliveredLastMonth,
    totalDeliveredThisMonth
  );

  const last2MonthTrend = getTrend(
    totalDeliveredLast2Month,
    totalDeliveredThisMonth
  );

  return (
    <div className="flex flex-grow flex-col w-full pb-spacing-06">
      {/* Search Bar */}
      <div className="flex flex-row w-full space-x-spacing-05 items-center group h-[60px] justify-start mb-spacing-02">
        <div className="min-w-[200px]">
          <DateRangePicker
            backgroundType="LIGHT"
            placeholder="Select report dates"
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            initiallySelectedRangeType="THIS_MONTH"
            useVerboseDateRange={false}
            label={""}
            required={false}
            disabled={false}
            onChange={(start, end) => {
              setStartDateFilter(start ? startOfDay(start) : undefined);
              setEndDateFilter(end ? endOfDay(end) : undefined);
            }}
          />
        </div>
        <p className="font-bold text-large">
          {getFormattedDateRange(startDate, endDate)}
        </p>
      </div>

      {/* Cards  */}
      <div className="grid grid-cols-12 gap-spacing-05 w-full h-full items-stretch">
        {/* Left Section */}
        <div className="col-span-7 w-full h-full gap-spacing-05 flex flex-col">
          {/* Overview */}
          <div className="bg-primary border border-tertiary shadow-shadow-02 p-spacing-05 space-y-spacing-03 rounded-radius-02">
            {/* Leading Text Section*/}
            <div className="relative flex flex-col items-center">
              {/* Floating top-right tooltip */}
              <div className="absolute top-0 right-0">
                <Tooltip
                  content={
                    <div className="text-left space-y-2">
                      <div className="font-semibold label-02">
                        Update Notes:
                      </div>
                      <ul className="list-disc pl-spacing-04 space-y-spacing-02 label-03">
                        <li>
                          <strong>March 4, 2025</strong>: Analytics data
                          collection began. Earlier data is not included.
                        </li>
                        <li>
                          <strong>April 1, 2025</strong>: We've improved how
                          analytics data is captured to ensure better accuracy.
                          Some older data may still be incomplete, but all new
                          data will now be consistently captured.
                        </li>
                      </ul>
                    </div>
                  }
                  anchor={<InfoOutlined className="text-primary-brand" />}
                />
              </div>

              {/* Main content in the center */}
              <div className="flex space-x-spacing-03">
                <Tooltip
                  content={
                    <span>
                      {thisMonthTrend === "UP"
                        ? `This month delivered is up from last month`
                        : thisMonthTrend === "DOWN"
                          ? `This month delivered is down from last month`
                          : "Total delivered transactions"}
                    </span>
                  }
                  anchor={
                    <div className="flex space-x-spacing-03 items-center justify-center">
                      <span className="text-2xlarge font-bold flex spacing-x-2 items-center">
                        {thisMonthTrend === "UP" && (
                          <TrendingUp
                            className="text-success"
                            style={{ width: 40, height: 40 }}
                          />
                        )}
                        {thisMonthTrend === "DOWN" && (
                          <TrendingDown
                            className="text-danger"
                            style={{ width: 40, height: 40 }}
                          />
                        )}
                        {totalDeliveredThisMonthLoading ? (
                          <Spinner color="BLUE" size="SMALL" />
                        ) : (
                          totalDeliveredThisMonth
                        )}
                      </span>
                      <span className="flex items-baseline space-x-spacing-01">
                        <span className="text-2xlarge text-primary">
                          Delivered
                        </span>
                        <span className="text-xlarge text-tertiary">
                          / this month
                        </span>
                      </span>
                    </div>
                  }
                />
              </div>
            </div>

            {/* Delivered & Spots Statistic Cards */}
            <div className="flex space-x-spacing-02 mx-spacing-04">
              <PlainCard className="p-spacing-03 flex flex-row space-x-spacing-02">
                <div className="flex flex-col">
                  <Tooltip
                    content={
                      <span>
                        {lastMonthTrend === "UP"
                          ? `Last month's delivered is UP from this month`
                          : lastMonthTrend === "DOWN"
                            ? `Last month delivered is down from this month`
                            : "Total delivered transactions"}
                      </span>
                    }
                    anchor={
                      <div className="flex space-x-spacing-05 items-center justify-center">
                        <span className="text-large text-primary font-bold flex items-center gap-spacing-04 ">
                          {lastMonthTrend === "UP" && (
                            <ArrowUpward
                              className="text-success"
                              style={{
                                width: 28,
                                height: 28,
                              }}
                            />
                          )}
                          {lastMonthTrend === "DOWN" && (
                            <ArrowDownward
                              className="text-danger"
                              style={{
                                width: 28,
                                height: 28,
                              }}
                            />
                          )}
                          {totalDeliveredLastMonthLoading ? (
                            <Spinner color="BLUE" size="SMALL" />
                          ) : (
                            totalDeliveredLastMonth
                          )}
                        </span>

                        <span className="flex items-baseline space-x-spacing-01">
                          <span className="text-large text-primary">
                            Delivered
                          </span>
                          <span className="text-small text-tertiary">
                            / last month
                          </span>
                        </span>
                      </div>
                    }
                  />
                </div>
              </PlainCard>

              <PlainCard className="p-spacing-03 flex flex-row space-x-spacing-02">
                <div className="flex flex-col">
                  <Tooltip
                    content={
                      <span>
                        {last2MonthTrend === "UP"
                          ? `Last 2 month's delivered is UP from this month`
                          : last2MonthTrend === "DOWN"
                            ? `Last 2 month delivered is down from this month`
                            : "Total delivered transactions from last 2 month"}
                      </span>
                    }
                    anchor={
                      <div className="flex space-x-spacing-05 items-center justify-center">
                        <span className="text-large text-primary font-bold flex items-center gap-spacing-04 ">
                          {last2MonthTrend === "UP" && (
                            <ArrowUpward
                              className="text-success"
                              style={{
                                width: 28,
                                height: 28,
                              }}
                            />
                          )}
                          {last2MonthTrend === "DOWN" && (
                            <ArrowDownward
                              className="text-danger"
                              style={{
                                width: 28,
                                height: 28,
                              }}
                            />
                          )}
                          {totalDeliveredLast2MonthLoading ? (
                            <Spinner color="BLUE" size="SMALL" />
                          ) : (
                            totalDeliveredLast2Month
                          )}
                        </span>

                        <span className="flex items-baseline space-x-spacing-01">
                          <span className="text-large text-primary">
                            Delivered
                          </span>
                          <span className="text-small text-tertiary">
                            / two months ago
                          </span>
                        </span>
                      </div>
                    }
                  />
                </div>
              </PlainCard>
            </div>

            {/* Statistic by Category */}
            <div className="bg-secondary p-spacing-04 flex flex-col space-y-spacing-02">
              <div className="flex gap-spacing-02">
                <PlainCard className="p-spacing-03 flex flex-col ">
                  <p className="text-tertiary">New</p>
                  <div className="text-xlarge font-bold ">
                    {newCountLoading && <Spinner color="BLUE" size="SMALL" />}
                    {!newCountLoading && (newCount ?? 0)}
                  </div>
                </PlainCard>

                <PlainCard className="p-spacing-03 flex flex-col">
                  <p className="text-tertiary">Used</p>
                  <div className="text-xlarge font-bold ">
                    {usedCountLoading && <Spinner color="BLUE" size="SMALL" />}
                    {!usedCountLoading && (usedCount ?? 0)}
                  </div>
                </PlainCard>

                <PlainCard className="p-spacing-03 flex flex-col">
                  <p className="text-tertiary">LBOs</p>
                  <div className="text-xlarge font-bold ">
                    {lboCountLoading && <Spinner color="BLUE" size="SMALL" />}
                    {!lboCountLoading && lboCount}
                  </div>
                </PlainCard>
              </div>
              <div className="flex gap-spacing-02">
                <PlainCard className="p-spacing-03 flex flex-col gap-spacing-02">
                  <p className="font-bold">By Source</p>
                  <span className="flex justify-between">
                    <p className="text-tertiary">Showroom Ups</p>
                    <div className="font-bold">
                      {showRoomupsLoading && (
                        <Spinner color="BLUE" size="SMALL" />
                      )}
                      {!showRoomupsLoading && showroomUps}
                    </div>
                  </span>

                  <span className="flex justify-between">
                    <p className="text-tertiary">Web-Leads</p>
                    <div className="font-bold">
                      {webLeadsLoading && <Spinner color="BLUE" size="SMALL" />}
                      {!webLeadsLoading && webLeads}
                    </div>
                  </span>

                  <Tooltip
                    content={
                      <span>
                        This metric represents transactions initiated via the
                        Web Application, Web Prequal, or Admin Dashboard.
                      </span>
                    }
                    anchor={
                      <span className="flex justify-between">
                        <p className="text-tertiary">Start from Home</p>
                        <div className="font-bold">
                          {startFromHomeLoading && (
                            <Spinner color="BLUE" size="SMALL" />
                          )}
                          {!startFromHomeLoading && startFromHome}
                        </div>
                      </span>
                    }
                  />
                </PlainCard>

                <PlainCard className="p-spacing-03 flex flex-col gap-spacing-02">
                  <p className="font-bold">By Finance Type</p>
                  <span className="flex justify-between">
                    <p className="text-tertiary">Finance</p>
                    <div className="font-bold">
                      {financeCountLoading && (
                        <Spinner color="BLUE" size="SMALL" />
                      )}
                      {!financeCountLoading && financeCount}
                    </div>
                  </span>

                  <span className="flex justify-between">
                    <p className="text-tertiary">Lease</p>
                    <div className="font-bold">
                      {financeCountLoading && (
                        <Spinner color="BLUE" size="SMALL" />
                      )}
                      {!leaseCountLoading && leaseCount}
                    </div>
                  </span>

                  <span className="flex justify-between">
                    <p className="text-tertiary">Cash</p>
                    <div className="font-bold">
                      {cashCountLoading && (
                        <Spinner color="BLUE" size="SMALL" />
                      )}
                      {!cashCountLoading && cashCount}
                    </div>
                  </span>
                </PlainCard>
              </div>
            </div>
          </div>

          <div className="bg-primary border border-tertiary shadow-shadow-02 p-spacing-05 space-y-spacing-03 rounded-radius-02">
            <p className="text-large font-bold">Total Transaction Overview</p>

            <div className="w-full grid grid-cols-3 gap-spacing-02">
              <Tooltip
                content={<span>Transactions from kiosks</span>}
                anchor={
                  <PlainCard className="py-spacing-03 px-spacing-04 flex flex-col">
                    <p className="text-tertiary">Showroom Ups</p>
                    <div className="text-xlarge font-bold ">
                      {showRoomupsLoading && (
                        <Spinner color="BLUE" size="SMALL" />
                      )}
                      {!showRoomupsLoading && showroomUps}
                    </div>
                  </PlainCard>
                }
              />

              <PlainCard className="py-spacing-03 px-spacing-04 flex flex-col">
                <p className="text-tertiary">Web-Leads</p>
                <div className="text-xlarge font-bold ">
                  {totalWebLeadsLoading && (
                    <Spinner color="BLUE" size="SMALL" />
                  )}
                  {!totalWebLeadsLoading && totalWebLeads}
                </div>
              </PlainCard>
              <PlainCard className="py-spacing-03 px-spacing-04 flex flex-col">
                <p className="text-tertiary">Pre-Qualified</p>
                <div className="text-xlarge font-bold ">
                  {totalPrequalLoading && <Spinner color="BLUE" size="SMALL" />}
                  {!totalPrequalLoading && totalPrequal}
                </div>
              </PlainCard>
              <PlainCard className="py-spacing-03 px-spacing-04 flex flex-col">
                <p className="text-tertiary">Credit App Submitted</p>
                <div className="text-xlarge font-bold ">
                  {totalCreditSubmmitedLoading && (
                    <Spinner color="BLUE" size="SMALL" />
                  )}
                  {!totalCreditSubmmitedLoading && totalCreditSubmitted}
                </div>
              </PlainCard>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="col-span-5 h-full">
          <Tab.Group
            onChange={(index) => {
              const selectedTab = statusTabs.at(index);
              if (selectedTab) {
                setRankingTabStatus(selectedTab);
              }
            }}
          >
            <div className="p-spacing-05 bg-white rounded-md shadow-shadow-02 h-full flex flex-col min-h-0">
              {/* Heading */}
              <div className="flex flex-row mb-spacing-04 justify-between">
                <p className="text-large font-bold">Top 5 Sales Staff</p>
                <div
                  className="text-primary-blue hover:text-interactive cursor-pointer"
                  onClick={() => {
                    navigate("/dashboard/reports/sales-ranking");
                  }}
                >
                  View All
                </div>
              </div>

              {/* Tab List */}
              <Tab.List className="flex border-b border-gray-200">
                {tabs.map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "relative py-spacing-01 px-spacing-04 focus:outline-none",
                        {
                          "border-b-2 border-primary-blue text-primary font-bold":
                            selected,
                          "text-tertiary hover:text-primary-blue": !selected,
                        }
                      )
                    }
                  >
                    {tab}
                  </Tab>
                ))}
              </Tab.List>

              <div className="flex flex-col h-full justify-between mt-spacing-04">
                <div className="flex flex-col h-full">
                  {salesRankingLoading && (
                    <div className="w-full flex flex-row items-center justify-center h-full">
                      <Spinner color="BLUE" size="LARGE" />
                    </div>
                  )}

                  {!salesRankingLoading && salesRanking.length === 0 && (
                    <p>No data to display</p>
                  )}

                  {salesRanking.map((ranking, index) => {
                    const computedRank =
                      sortedRankCounts.indexOf(ranking.currentMonth ?? 0) + 1;

                    return (
                      <RankingCard
                        key={ranking.userId}
                        firstName={ranking.firstName ?? "FirstName"}
                        lastName={ranking.lastName ?? "LastName"}
                        ranking={computedRank}
                        target={maxCount}
                        thisMonth={ranking.currentMonth ?? 0}
                        lastMonth={ranking.lastMonth ?? 0}
                        last2Month={ranking.twoMonthsAgo ?? 0}
                        ordinalNumber={index + 1}
                      />
                    );
                  })}
                </div>

                <div className="p-spacing-03">
                  <ul className="list-none p-0 m-0">
                    <li className="flex items-center">
                      <span className="inline-block w-2 h-2 bg-blue-500 rounded-full mr-2"></span>
                      This month
                    </li>
                    <li className="flex items-center">
                      <span className="inline-block w-2 h-2 bg-gray-600 rounded-full mr-2"></span>
                      Last month
                    </li>
                    <li className="flex items-center">
                      <span className="inline-block w-2 h-2 bg-gray-400 rounded-full mr-2"></span>
                      Two months ago
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
