import { Add, Delete, FilterList, FilterListOff } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Button,
  DataStatus,
  DateRangePicker,
  SearchBox,
  Table,
  Tag,
  Tooltip,
} from "@thedealersconcierge/components";
import DropdownCheckbox, {
  DropdownCheckboxOption,
} from "@thedealersconcierge/components/src/input/dropdownCheckbox";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import {
  FinanceType,
  TransactionStatusSchema,
} from "@thedealersconcierge/lib/codecs/tdc";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import { endOfDay, format, startOfDay } from "date-fns";
import { useAtomValue } from "jotai";
import stringify from "json-stable-stringify";
import debounce from "lodash/debounce";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router";
import {
  Role,
  TransactionSource,
  TransactionStatus,
} from "~/__generated__/backend/zeus";
import Spinner from "~/components/Spinner";
import AssignButton from "~/components/StaffButton/AssignButton";
import SourceTag from "~/components/badges/SourceTag";
import StatusTag from "~/components/badges/StatusTag";
import ChevronRightIcon from "~/components/icons/ChevronRightIcon";
import MagnifierIcon from "~/components/icons/MagnifierIcon";
import config from "~/config";
import { gqlQueryClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import AnalyticsBanner from "../_components/AnalyticsBanner";
import AnalyticsBannerSkeleton from "../_components/AnalyticsBannerSkeleton";
import {
  Pagination,
  Row,
  dataTransform,
  formatDealType,
  selector,
  transformOrdering,
} from "./_dashboardUtils";
import { userQuery } from "./staff/_queries/userQuery";

const statusSchemaToGraphQlEnum = (
  e: TransactionStatusSchema
): TransactionStatus => {
  switch (e) {
    case "REGISTERED":
      return TransactionStatus.REGISTERED;
    case "SIGNED_UP":
      return TransactionStatus.SIGNED_UP;
    case "IN_PROGRESS":
      return TransactionStatus.IN_PROGRESS;

    case "PREQUAL_SUBMITTED":
      return TransactionStatus.PREQUAL_SUBMITTED;
    case "CREDIT_APP_SUBMITTED":
      return TransactionStatus.CREDIT_APP_SUBMITTED;
    case "FILE_COMPLETE":
      return TransactionStatus.FILE_COMPLETE;
    case "READY_FOR_FNI":
      return TransactionStatus.READY_FOR_FNI;

    case "DELIVERED":
      return TransactionStatus.DELIVERED;

    // Deprecated
    case "WEB_LEAD":
      return TransactionStatus.WEB_LEAD;
    case "WEB_APPLICATION":
      return TransactionStatus.WEB_APPLICATION;
    case "COMPLETED": {
      return TransactionStatus.COMPLETED;
    }
    case "DEAD": {
      return TransactionStatus.DEAD;
    }
    case "DISASSOCIATED": {
      return TransactionStatus.DISASSOCIATED;
    }
    case "RULE_90_DAYS": {
      return TransactionStatus.RULE_90_DAYS;
    }
    case "NOT_STARTED": {
      return TransactionStatus.NOT_STARTED;
    }
  }
};

export type SearchParams = {
  sorting?: SortingState;
  searchString?: string;
  statusFilter?: TransactionStatus[];
  sourceFilter?: TransactionSource[];
  assignedStaffIdsFilter?: string[];
  page: Pagination;
  startDateFilter?: Date;
  endDateFilter?: Date;
};

export default function Dashboard() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [inputValue, setInputValue] = useState("");
  const [statusFilter, setStatusFilter] = useState<TransactionStatus[]>([]);
  const [sourceFilter, setSourceFilter] = useState<TransactionSource[]>([]);
  const [selectedStaffFilter, setSelectedStaffFilter] = useState<string[]>([]);
  const [startDateFilter, setStartDateFilter] = useState<Date | undefined>();
  const [endDateFilter, setEndDateFilter] = useState<Date | undefined>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    pageSize: 10,
    direction: "after",
  });
  const navigate = useNavigate();
  const dealership = useAtomValue(dealershipAtom);
  const modals = useModals();
  const showNewTransactionButton = permissionChecker(
    "createTransction",
    dealership?.activeDealershipPerms
  );

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(location.search)
  );

  // Listen to search params update and save it to url
  useEffect(() => {
    const searchParams: SearchParams = {
      sorting: sorting.length ? sorting : [],
      searchString: searchString ?? "",
      statusFilter: statusFilter.length ? statusFilter : [],
      sourceFilter: sourceFilter.length ? sourceFilter : [],
      startDateFilter: startDateFilter,
      endDateFilter: endDateFilter,
      assignedStaffIdsFilter: selectedStaffFilter.length
        ? selectedStaffFilter
        : [],
      page: page,
    };
    const base64Params = btoa(JSON.stringify(searchParams));
    setSearchParams({ search: base64Params });
  }, [
    sorting,
    searchString,
    statusFilter,
    sourceFilter,
    selectedStaffFilter,
    page,
    startDateFilter,
    endDateFilter,
  ]);

  // Load the Search params from url to state on first page load
  useEffect(() => {
    const loadSearchFromUrl = () => {
      const encodedParams = searchParams.get("search");
      if (encodedParams) {
        const {
          sorting,
          searchString,
          sourceFilter,
          statusFilter,
          startDateFilter,
          endDateFilter,
          assignedStaffIdsFilter,
          page,
        }: Partial<SearchParams> = JSON.parse(atob(encodedParams));

        if (sorting) setSorting(sorting);
        if (searchString) {
          setInputValue(searchString);
          setSearchString(searchString);
        }
        if (statusFilter) setStatusFilter(statusFilter);
        if (sourceFilter) setSourceFilter(sourceFilter);
        if (assignedStaffIdsFilter)
          setSelectedStaffFilter(assignedStaffIdsFilter);
        if (startDateFilter) setStartDateFilter(new Date(startDateFilter));
        if (endDateFilter) setEndDateFilter(new Date(endDateFilter));

        // Show the filter if any of the params is set
        if (
          (searchString && searchString.length > 0) ||
          (statusFilter && statusFilter.length > 0) ||
          (sourceFilter && sourceFilter.length > 0) ||
          (assignedStaffIdsFilter && assignedStaffIdsFilter.length > 0) ||
          startDateFilter ||
          endDateFilter
        ) {
          setShowFilter(true);
        }

        if (page) setPage(page);
      }
    };

    // We delay for a few ms to ensure it is not overriden initial load
    const timer = setTimeout(loadSearchFromUrl, 300);
    return () => clearTimeout(timer);
  }, []);

  // This is a hack. Please avoid this construction anywhere else
  useEffect(() => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
  }, [sorting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchString = useCallback(
    debounce((term: string) => {
      setSearchString(term);
      setPage((p) => ({
        ...p,
        currentPage: 1,
        cursor: undefined,
        direction: "after",
      }));
    }, 500),
    []
  );

  const qKey = [
    "dashboard_table",
    dealership?.activeDealershipPerms.dealershipId,
    stringify(transformOrdering(sorting)),
    stringify(page),
    searchString,
    stringify(statusFilter),
    stringify(sourceFilter),
    stringify(selectedStaffFilter),
    stringify(startDateFilter),
    stringify(endDateFilter),
  ];

  const { data: tableData, isLoading: loading } = useQuery({
    queryKey: qKey,
    enabled: Boolean(dealership?.activeDealershipPerms.dealershipId),
    queryFn: () => {
      if (dealership?.activeDealershipPerms.dealershipId) {
        return gqlQueryClient()(
          selector(
            dealership.activeDealershipPerms.dealershipId,
            transformOrdering(sorting),
            page,
            searchString,
            statusFilter,
            sourceFilter,
            selectedStaffFilter,
            startDateFilter,
            endDateFilter
          )
        );
      }
    },
  });
  const reloadList = async () => {
    // We refetch everything - this is akin to reloading the the browser
    await queryClient.refetchQueries();
  };

  const trns = tableData?.dealership?.transactions?.edges ?? [];

  // Permissions
  const canViewDealJacket = permissionChecker(
    "viewDealJacket",
    dealership?.activeDealershipPerms
  );

  const totalCount = tableData?.dealership?.transactions?.totalCount ?? 0;
  const columns = useMemo<ColumnDef<Row>[]>(
    () => [
      //  Sticky headers
      {
        header: () => <span>Date</span>,
        id: "creationDate",
        accessorFn: (row: Row) => (
          <p className="body-02">{format(row.creationDate, "MM/dd/yyyy")}</p>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.creationDate.getTime() - b.original.creationDate.getTime(),
        size: 120,
      },
      {
        header: () => <span>Last Activity</span>,
        id: "lastActivityDate",
        accessorFn: (row) => (
          <p className="body-02">
            {format(row.lastActivityDate, "MM/dd/yyyy")}
          </p>
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.lastActivityDate.getTime() -
          b.original.lastActivityDate.getTime(),
        size: 135,
      },
      {
        header: () => <span>First Name</span>,
        id: "firstName",
        accessorFn: (row) => {
          const firstName = row.firstName ?? "-";
          return (
            <div className="flex justify-start w-full">
              {firstName.length > 16 ? (
                <Tooltip
                  anchor={
                    <span className="truncate max-w-xs body-02">
                      {firstName}
                    </span>
                  }
                  content={<p className="body-02">{firstName}</p>}
                />
              ) : (
                <p className="body-02">{firstName}</p>
              )}
            </div>
          );
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.firstName?.localeCompare(b.original.firstName ?? "") ?? 0,
        size: 170,
      },
      {
        header: () => <span>Last Name</span>,
        id: "lastName",
        accessorFn: (row) => {
          const lastName = row.lastName ?? "-";
          return (
            <div className="flex justify-start w-full">
              {lastName.length > 16 ? (
                <Tooltip
                  anchor={
                    <span className="truncate max-w-xs body-02">
                      {lastName}
                    </span>
                  }
                  content={<p className="body-02">{lastName}</p>}
                />
              ) : (
                <p className="body-02">{lastName}</p>
              )}
            </div>
          );
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          a.original.lastName?.localeCompare(b.original.lastName ?? "") ?? 0,
        size: 170,
      },

      // The rest
      {
        header: () => <span>Status</span>,
        id: "status",
        accessorFn: (row) => <StatusTag status={row.status} />,
        size: 180,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) => a.original.status.localeCompare(b.original.status),
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>Source</span>,
        id: "source",
        accessorFn: (row) => <SourceTag source={row.source} />,
        size: 160,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        sortingFn: (a, b) => a.original.source.localeCompare(b.original.source),
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>Forms</span>,
        id: "forms",
        accessorFn: (row) => (
          <Tooltip
            anchor={
              <div>
                <DataStatus
                  status={(() => {
                    // There are invalid compliance forms -> need to be re-triggered
                    if (row.hasInvalidComplianceForms)
                      return "REQUIRES_ATTENTION";
                    // The compliance forms are complete
                    if (row.hasCompletedComplianceForms) return "COMPLETE";
                    // The compliance forms have been requested but are not complete
                    if (row.hasRequestedComplianceForms) return "INCOMPLETE";

                    // The compliance forms have not been requested
                    return "NO_DATA";
                  })()}
                />
              </div>
            }
            content={
              <span>
                {(() => {
                  if (row.hasInvalidComplianceForms)
                    return "The compliance forms need to be re-requested";
                  if (row.hasCompletedComplianceForms)
                    return "The compliance forms are signed";
                  if (row.hasRequestedComplianceForms)
                    return "The compliance forms are not signed";

                  return "The compliance forms have not been requested";
                })()}
              </span>
            }
          />
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>ID</span>,
        id: "identifacation",
        accessorFn: (row) => (
          <DataStatus status={row.firstIdCard ? "COMPLETE" : "NO_DATA"} />
        ),
        cell: (info) => info.getValue(),
        size: 66,
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>Co-Buyer</span>,
        id: "co-buyer",
        size: 120,
        accessorFn: (row) => (
          <Tooltip
            anchor={
              <div>
                <DataStatus
                  status={row.coBuyerUserId ? "COMPLETE" : "NO_DATA"}
                />
              </div>
            }
            content={
              <>
                {row.coBuyerUserId
                  ? "The transaction has a co-buyer added"
                  : "The transaction has no co-buyer added"}
              </>
            }
          />
        ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },

      {
        header: () => <span>Trade</span>,
        id: "trade",
        accessorFn: (row) => {
          return (
            <Tooltip
              anchor={
                <div>
                  <DataStatus
                    status={row.tradeVehicle ? "COMPLETE" : "NO_DATA"}
                  />
                </div>
              }
              content={
                <>
                  {row.tradeVehicle
                    ? "The transaction has a trade vehicle added"
                    : "The transaction has no trade vehicle added"}
                </>
              }
            />
          );
        },
        cell: (info) => info.getValue(),
        size: 96,
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>Deal Type</span>,
        id: "deal-type",
        accessorFn: (row) => {
          return (
            <Tag
              color="GRAY"
              label={
                row.financeType
                  ? formatDealType(FinanceType.parse(row.financeType))
                  : ""
              }
            />
          );
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },

      {
        header: () => <span>Sales Person</span>,
        id: "sales-person",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 216,
        accessorFn: (row) => {
          const hasSalesPerson = Boolean(row.salesPerson);
          return (
            <>
              {hasSalesPerson && (
                <div className="line-clamp-1 body-02">{row.salesPerson}</div>
              )}
              {!hasSalesPerson && (
                <AssignButton
                  transactionId={row.transactionId}
                  assignRole={Role.SALES_PERSON}
                  onDidAssign={reloadList}
                />
              )}
            </>
          );
        },
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span> Sales Manager</span>,
        accessorKey: "sales-manager",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        size: 216,
        accessorFn: (row) => {
          const hasSalesManager = Boolean(row.salesManager);
          return (
            <>
              {hasSalesManager && (
                <div className="line-clamp-1 body-02">{row.salesManager}</div>
              )}
              {!hasSalesManager && (
                <AssignButton
                  transactionId={row.transactionId}
                  assignRole={Role.SALES_MANAGER}
                  onDidAssign={reloadList}
                />
              )}
            </>
          );
        },
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>F&I Manager</span>,
        id: "fni-manager",
        cell: (info) => info.getValue(),
        size: 216,
        accessorFn: (row) => {
          const hasFniManager = Boolean(row.fniManager);
          return (
            <>
              {hasFniManager && (
                <div className="line-clamp-1 body-02">{row.fniManager}</div>
              )}
              {!hasFniManager && (
                <AssignButton
                  transactionId={row.transactionId}
                  assignRole={Role.FNI_MANAGER}
                  onDidAssign={reloadList}
                />
              )}
            </>
          );
        },
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
      {
        header: () => <span>BDC</span>,
        id: "bdc",
        cell: (info) => info.getValue(),
        size: 216,
        accessorFn: (row) => {
          const hasBdc = Boolean(row.bdc);
          return (
            <>
              {hasBdc && <div className="line-clamp-1 body-02">{row.bdc}</div>}
              {!hasBdc && (
                <AssignButton
                  transactionId={row.transactionId}
                  assignRole={Role.BDC}
                  onDidAssign={reloadList}
                />
              )}
            </>
          );
        },
        enableSorting: false,
        meta: {
          isHeaderCentered: true,
          isCellCentered: true,
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const data = useMemo(() => dataTransform(tableData), [tableData]);

  const table = useReactTable({
    enablePinning: true,
    enableColumnPinning: true,
    data,
    columns,
    state: {
      sorting,
      columnPinning: {
        left: ["creationDate", "lastActivityDate", "firstName", "lastName"],
      },
      columnVisibility: {
        "deal-jacket": canViewDealJacket,
        forms:
          Boolean(tableData?.dealership?.hasEnabledComplianceForms) &&
          canViewDealJacket,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !config.isProduction,
  });

  //// Filter Section states
  // Filter By Status
  const statuses = TransactionStatusSchema.options.map((status) => ({
    label: getReadableTransactionStatus(status.value),
    value: statusSchemaToGraphQlEnum(status.value),
    key: status.value.toLocaleLowerCase(),
    deprecated: [
      "WEB_LEAD",
      "WEB_APPLICATION",
      "COMPLETED",
      "DISASSOCIATED",
      "DEAD",
      "RULE_90_DAYS",
      "NOT_STARTED",
    ].includes(status.value),
  }));

  const [statusOptions, setStatusOptions] =
    useState<DropdownCheckboxOption<TransactionStatus>[]>(statuses);

  const onSelectStatusFilter = (selectedStatus: TransactionStatus[]) => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
    setStatusFilter(selectedStatus);
  };

  const onSearchStatusFilter = (searchString: string) => {
    if (!searchString || !searchString.length) setStatusOptions(statuses); // Reset filter
    const filteredOptions = statuses.filter((option) =>
      option.label.toLowerCase().includes(searchString.toLowerCase())
    );
    setStatusOptions(filteredOptions);
  };

  // Filter by Assigned Staff
  const { data: users } = useQuery(
    userQuery(
      ["BDC", "FNI_MANAGER", "SALES_MANAGER", "SALES_PERSON"],
      {
        pageSize: 100,
        currentPage: 0,
        direction: "after",
      },
      dealership?.activeDealershipPerms.dealershipId,
      false
    )
  );

  const initialStaffOptions = users?.dealership?.users?.edges?.map(
    ({ node }) => {
      return {
        label: `${node?.user?.firstName} ${node?.user?.lastName}`,
        value: node?.user?.id ?? "no-user-id",
        key: node?.user?.id ?? "no-user-id",
      };
    }
  );

  const [staffOptions, setStaffOptions] = useState<
    DropdownCheckboxOption<string>[]
  >([]);

  // This is a quick to prevent rerender loop when the if condition is met
  // This will be reworked into something that does not not involve a useEffect
  useEffect(() => {
    // This prevents empty dropdown
    if (users && staffOptions.length === 0) {
      setStaffOptions(initialStaffOptions ?? []);
    }
  }, [users, staffOptions.length]);

  const onSelectStafFilter = (selectedIds: string[]) => {
    setPage((p) => ({
      ...p,
      currentPage: 1,
      cursor: undefined,
      direction: "after",
    }));
    setSelectedStaffFilter(selectedIds);
  };

  const onSearchStaffFilter = (searchString: string) => {
    if (!searchString || !searchString.length)
      setStaffOptions(initialStaffOptions ?? []); // Reset filter
    if (!initialStaffOptions) return;
    const filteredOptions =
      initialStaffOptions.length > 0
        ? initialStaffOptions.filter((option) =>
            option.label.toLowerCase().includes(searchString.toLowerCase())
          )
        : [];
    setStaffOptions(filteredOptions);
  };

  const handleClearFilter = () => {
    setSearchString("");
    setInputValue("");
    setStatusFilter([]);
    setSourceFilter([]);
    setSelectedStaffFilter([]);
    setStartDateFilter(undefined);
    setEndDateFilter(undefined);
  };

  return (
    <div className="flex flex-grow flex-col pb-8">
      {/* Analytics Banner */}
      {tableData?.dealership?.hasEnabledReportingDashboard && (
        <Suspense
          fallback={
            <div className="flex w-full justify-start items-center">
              <AnalyticsBannerSkeleton />
            </div>
          }
        >
          <AnalyticsBanner
            dealershipId={dealership?.activeDealershipPerms.dealershipId}
          />
        </Suspense>
      )}

      {/* The search bar */}
      <div className="flex flex-row w-full space-x-6 items-center group h-[60px] justify-between mb-4">
        <SearchBox
          onChange={(e: string) => {
            // We need to manage 2 state here to manage the user input to seamlessly display the value and keep it debounced
            setInputValue(e);
            updateSearchString(e);
          }}
          value={inputValue}
          placeholder="Search"
          disabled={false}
          dataTestId="transaction-search"
          backgroundType="DARK"
          iconLeft={<MagnifierIcon />}
          className="w-fit"
        />

        <div className="w-full flex flex-row gap-4 items-center justify-end">
          {showFilter && (
            <Fragment>
              <Button
                iconLeft={<Delete />}
                variant="GHOST"
                size="MEDIUM"
                label="Clear"
                onClick={handleClearFilter}
              />

              <div className="min-w-[200px]">
                <DateRangePicker
                  placeholder="Date Range"
                  selectedStartDate={startDateFilter}
                  selectedEndDate={endDateFilter}
                  label={""}
                  required={false}
                  disabled={false}
                  onChange={(start, end) => {
                    setStartDateFilter(start ? startOfDay(start) : undefined);
                    setEndDateFilter(end ? endOfDay(end) : undefined);
                  }}
                />
              </div>

              <div className="w-[200px]">
                <DropdownCheckbox<TransactionStatus>
                  displayFilter={true}
                  onClearAll={() => {
                    setStatusFilter([]);
                  }}
                  placeholder={`Status ${statusFilter.length > 0 ? `(${statusFilter.length})` : ""}`.trim()}
                  options={statusOptions}
                  selectedOptions={statusFilter}
                  onSelect={onSelectStatusFilter}
                  onSearch={onSearchStatusFilter}
                  align="LEFT"
                  label=""
                />
              </div>

              <div className="w-[200px]">
                <DropdownCheckbox<string>
                  displayFilter={true}
                  onClearAll={() => {
                    setSelectedStaffFilter([]);
                  }}
                  placeholder={`Staff Member ${selectedStaffFilter.length > 0 ? `(${selectedStaffFilter.length})` : ""}`.trim()}
                  options={staffOptions}
                  selectedOptions={selectedStaffFilter}
                  onSelect={onSelectStafFilter}
                  onSearch={onSearchStaffFilter}
                  align="LEFT"
                  label=""
                />
              </div>

              <Button
                iconLeft={<FilterListOff />}
                variant="GHOST"
                size="MEDIUM"
                onClick={() => {
                  setShowFilter(false);
                }}
              />
            </Fragment>
          )}

          {!showFilter && (
            <Button
              iconLeft={<FilterList />}
              label={"Filter"}
              variant="GHOST"
              size="MEDIUM"
              onClick={() => {
                setShowFilter(true);
              }}
            />
          )}

          {showNewTransactionButton && (
            <Button
              iconLeft={<Add />}
              label="New Transaction"
              variant="PRIMARY"
              size="MEDIUM"
              onClick={() => {
                modals.open("/dashboard/transaction/create");
              }}
              dataTestId="new-transaction-button"
            />
          )}
        </div>
      </div>
      {loading && (
        <div
          className="flex relative flex-grow justify-center items-center"
          data-test-id={"transaction-dashboard-loading-spiner"}
        >
          <Spinner />
        </div>
      )}
      {!loading && (
        <Fragment>
          {/* Main Table */}
          <div
            className="rounded-radius-02 overflow-hidden shadow-shadow-02 mb-spacing-04"
            data-test-id="dealer-dashboard-table-body"
          >
            <div className="relative flex flex-grow overflow-y-auto">
              <Table
                table={table}
                rowOnClick={(row) => {
                  if (row.transactionId) {
                    navigate("/dashboard/transaction/:transactionId", {
                      params: {
                        transactionId: row.transactionId,
                      },
                    });
                  } else {
                    console.warn("No buyer on the transaction");
                  }
                }}
                dataTestId={"transaction-dashboard-table"}
              />
            </div>
          </div>

          <div className="w-full flex justify-between">
            {/* Explanatory legend */}
            <div className="flex items-center space-x-4 text-dark-gray">
              <div className="inline-flex items-center space-x-2">
                <DataStatus status="COMPLETE" />

                <span className="uppercase">Action Completed</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <DataStatus status="INCOMPLETE" />

                <span className="uppercase">None / Invalid</span>
              </div>

              <div className="inline-flex items-center space-x-2">
                <DataStatus status="NO_DATA" />

                <span className="uppercase">No data</span>
              </div>
            </div>

            {/* Pagination */}
            <div>
              <div className="flex flex-row space-x-2 items-center text-dark-gray">
                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    { "opacity-40": !(page.currentPage > 1) }
                  )}
                  disabled={!(page.currentPage > 1)}
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      direction: "before",
                      cursor: trns[0].cursor,
                      currentPage: p.currentPage - 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square rotate-180" />
                  </div>
                </button>

                <div className="flex flex-row space-x-2">
                  <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
                    {page.currentPage} / {Math.ceil(totalCount / page.pageSize)}
                  </div>
                </div>

                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    {
                      "opacity-40": !(
                        Math.ceil(totalCount / page.pageSize) > page.currentPage
                      ),
                    }
                  )}
                  disabled={
                    !(Math.ceil(totalCount / page.pageSize) > page.currentPage)
                  }
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      direction: "after",
                      cursor: trns[trns.length - 1].cursor,
                      currentPage: p.currentPage + 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
