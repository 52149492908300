import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { captureException } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import {
  SelectBase,
  Tag,
  Tooltip,
  useConfirmationModal,
  UserConfirmationModal,
} from "@thedealersconcierge/components";
import { DropdownCheckboxOption } from "@thedealersconcierge/components/src/input/dropdownCheckbox";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import {
  DashboardUserRole,
  DashboardUserRoleSchema,
  UserRole,
} from "@thedealersconcierge/lib/codecs/tdc";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import { Role } from "~/__generated__/backend/zeus";
import suspendStaffAction from "~/actions/userDealerships/suspendStaffAction";
import updateUserDealershipRoleAction from "~/actions/userDealerships/updateUserDealershipRoleAction";
import createUserGroupAction from "~/actions/userGroups/createUserGroupAction";
import suspendGroupUserAction from "~/actions/userGroups/suspendUserGroupAction";
import updateUserGroupRoleAction from "~/actions/userGroups/updateUserGroupRoleAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/design-system-components/Button";
import EditValueButton from "~/components/EditValueButton";
import { gqlMutationClient, gqlQueryClient } from "~/lib/backend";
import { getReadableRole } from "~/lib/enumReadable";
import { getInitials } from "~/lib/helpers";
import meQuery from "~/query/meQuery";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import DisplayOnHoverWrapper from "../../transaction/[transactionId]/_components/DisplayOnHoverWrapper";
import { DashboardRoleBadge } from "../_components/UserRoleBadge";
import CDKConfiguration from "./_components/CDKConfiguration";

const StaffPage = () => {
  const { showWarningModal, ConfirmationModalComponent } =
    useConfirmationModal();
  const dealership = useAtomValue(dealershipAtom);
  const currentDealership = useAtomValue(dealershipAtom);
  const dealershipId = currentDealership?.activeDealershipPerms.dealershipId;
  const { userId } = useParams("/dashboard/staff/:userId");
  const { data: meData } = useQuery(meQuery());
  const meGroupUserData = meData?.me?.user?.userGroup?.edges?.at(0)?.node;
  const isGroupAdmin = !!meGroupUserData && meGroupUserData.role === "ADMIN";

  // Resetting password
  const [isUserConfirmationModalOpen, setIsUserConfirmationModalOpen] =
    useState(false);
  const resetStaffPassword = () => {
    const promise = gqlMutationClient()({
      resendStaffPassword: [
        {
          userId: userId,
        },
        {
          __typename: true,
          "...on GraphQLError": { message: true },
          "...on MutationResendStaffPasswordSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });
    toast.promise(promise, {
      pending: "Resetting password",
      error: "An error happened",
      success: "Password successfully reset",
    });
    setIsUserConfirmationModalOpen(false);
  };

  const { data: userData, refetch: refetchUserData } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () =>
      gqlQueryClient()({
        userDealership: [
          { userId, dealershipId: dealershipId ?? "no-dealership-id" },
          {
            user: {
              id: true,
              firstName: true,
              lastName: true,
              email: true,
              phoneNumber: true,

              userGroup: [
                { first: 1 },
                {
                  edges: { node: { id: true, role: true, isSuspended: true } },
                },
              ],
            },
            role: true,
            dealershipId: true,
            isSuspended: true,
          },
        ],
      }),
    enabled: !!dealershipId,
  });

  const user = userData?.userDealership?.user;
  const dealershipRole = userData?.userDealership?.role;
  const isDealershipSuspended = userData?.userDealership?.isSuspended;

  const groupRole =
    userData?.userDealership?.user?.userGroup?.edges?.at(0)?.node?.role;
  const isGroupSuspended =
    userData?.userDealership?.user?.userGroup?.edges?.at(0)?.node?.isSuspended;

  const canManageUser = permissionChecker(
    "manageUsers",
    dealership?.activeDealershipPerms
  );

  const handleUpdateUserDealershipStatus = async (
    userId: string,
    isSuspended: boolean
  ) => {
    try {
      const wording = isSuspended ? "activate" : "deactivate";

      const confirmed = await showWarningModal({
        message: `You're about to ${wording} this user account. The user still stays associated to existing transactions after being deactivated.`,
      });

      if (confirmed) {
        await suspendStaffAction(userId, !isSuspended);
        toast.success("Succeed updated user status");
        await refetchUserData();
      }
    } catch (e) {
      captureException(e);
      toast.error("Failed to update user status, contact admin");
    }
  };

  const handleUpdateUserGroupStatus = async (
    userId: string,
    isSuspended: boolean
  ) => {
    try {
      const wording = isSuspended ? "activate" : "deactivate";

      const confirmed = await showWarningModal({
        message: `You're about to ${wording} user group account. The user still stays associated to existing transactions after being deactivated.`,
      });

      if (confirmed) {
        await suspendGroupUserAction(userId, !isSuspended);
        toast.success("Succeed updated user group status");

        await refetchUserData();
      }
    } catch (e) {
      captureException(e);
      toast.error("Failed to update user group status, contact admin");
    }
  };

  const handleUpdateUserDealershipRole = async (
    userId: string,
    newRole: string
  ) => {
    try {
      const role = UserRole.parse(newRole) as Role;
      if (!role) {
        toast.error("Invalid Role");
        return;
      }

      if (!dealershipId) {
        toast.error("Invalid Dealership id");
        return;
      }

      const confirmed = await showWarningModal({
        message: `Are you sure you want to change staff role into ${getReadableRole(newRole)}`,
      });

      if (confirmed) {
        await updateUserDealershipRoleAction(userId, role);
        await refetchUserData();
        toast.success("Succeed updated staff role");
      }
    } catch (e) {
      captureException(e);
      toast.error("Failed to update staff role, contact admin");
    }
  };

  const handleUpsertUserGroupRole = async (userId: string, newRole: string) => {
    try {
      const role = UserRole.parse(newRole) as Role;
      if (!role) {
        toast.error("Invalid Role");
        return;
      }

      if (!dealershipId) {
        toast.error("Invalid Dealership id");
        return;
      }

      const confirmed = await showWarningModal({
        message: `Are you sure you want to change the user group role into ${getReadableRole(newRole)}?`,
      });

      if (confirmed) {
        // Already have role then we update
        // Else create new user
        if (groupRole) {
          await updateUserGroupRoleAction(userId, role);
        } else {
          await createUserGroupAction(userId, dealershipId, role);
        }

        await refetchUserData();
        toast.success("Succeed updated user group role");
      }
    } catch (e) {
      captureException(e);
      toast.error("Failed to update user group role, contact admin");
    }
  };

  const roleOptions: DropdownCheckboxOption<DashboardUserRole>[] =
    DashboardUserRoleSchema.options.map((role) => ({
      label: getReadableRole(role.value) ?? "",
      value: DashboardUserRoleSchema.parse(role.value),
      key: role.value,
    }));

  return (
    <div
      className="flex flex-col space-y-8"
      data-test-id={"staff-profile-page"}
    >
      <BreadCrumbsContainer>
        <BreadCrumb title="Dealership">
          <Link to={"/dashboard"}>Dealership</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link to={"/dashboard/staff"}>Users</Link>
        </BreadCrumb>

        {user && (
          <BreadCrumb
            title={`${user.firstName} ${user.lastName}${
              isDealershipSuspended ? " (Deactivated)" : ""
            }`}
            className="text-very-dark-gray" // We have to explicitly set the darker text color hear as conditionally changing the title doesn't properly update the color
          />
        )}
      </BreadCrumbsContainer>

      <div className="flex flex-col w-full items-center space-y-8 pb-16 overflow-y-scroll">
        <div className="flex flex-col space-y-8 bg-white rounded-lg shadow-lg p-8 w-full max-w-3xl">
          <div className="flex flex-row space-x-4">
            <div>
              <div className="flex items-center justify-center min-w-40 h-full rounded-lg text-xl font-roboto font-medium bg-very-light-gray text-dark-gray">
                {getInitials(user?.firstName, user?.lastName)}
              </div>
            </div>

            <div className="flex flex-col space-y-8 w-full">
              <div className="flex flex-row justify-between">
                <div className="text-heading-2 flex">
                  {user?.firstName} {user?.lastName}
                  {isDealershipSuspended ? (
                    <span className="text-dark-gray"> (Deactivated)</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-4 w-full">
                <div className="grid grid-cols-2">
                  <p className="text-secondary">Email</p>
                  <div
                    className="flex flex-row gap-2 items-center justify-between group w-full"
                    data-test-id="staff-profile-page-email"
                  >
                    {user?.email && (
                      <>
                        {user?.email ?? ""}
                        <DisplayOnHoverWrapper>
                          <EditValueButton
                            onSubmit={async (newValue) => {
                              const resp = await gqlMutationClient()({
                                updateStaffAttributes: [
                                  { userId, attributes: { email: newValue } },
                                  {
                                    __typename: true,
                                    "...on GraphQLError": { message: true },
                                    "...on MutationUpdateStaffAttributesSuccess":
                                      { data: { status: true } },
                                  },
                                ],
                              });
                              await refetchUserData();
                              if (
                                resp.updateStaffAttributes?.__typename !==
                                "MutationUpdateStaffAttributesSuccess"
                              ) {
                                throw new Error(
                                  resp.updateStaffAttributes?.message ??
                                    "Failed to update"
                                );
                              }
                            }}
                            type="text"
                            value={user?.email}
                            title="Edit Email"
                            subtitle="Email"
                            paragraph="Note: Changing the email will reset the users authentication method to SMS based if they had enabled authenticator app"
                          />
                        </DisplayOnHoverWrapper>
                      </>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-secondary">Phone number</p>
                  <div
                    className="flex flex-row gap-2 items-center justify-between group w-full"
                    data-test-id="staff-profile-page-phone"
                  >
                    {user?.phoneNumber && (
                      <>
                        {formatPhoneNumber(user?.phoneNumber ?? "")}
                        {/* The EditValueButton needs to support phone numbers for the following to works  */}
                        <DisplayOnHoverWrapper>
                          <EditValueButton
                            onSubmit={async (newValue) => {
                              const resp = await gqlMutationClient()({
                                updateStaffAttributes: [
                                  {
                                    userId,
                                    attributes: { phoneNumber: newValue },
                                  },
                                  {
                                    __typename: true,
                                    "...on GraphQLError": { message: true },
                                    "...on MutationUpdateStaffAttributesSuccess":
                                      { data: { status: true } },
                                  },
                                ],
                              });
                              await refetchUserData();
                              if (
                                resp.updateStaffAttributes?.__typename ===
                                "GraphQLError"
                              ) {
                                throw new Error(
                                  resp.updateStaffAttributes.message ??
                                    "Failed to update"
                                );
                              }
                            }}
                            type="phoneNumber"
                            value={user?.phoneNumber}
                            title="Edit Phone Number"
                            subtitle="Phone Number"
                          />
                        </DisplayOnHoverWrapper>
                      </>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-secondary"></p>
                  <div
                    className="flex flex-row gap-2 items-center justify-between group w-full"
                    data-test-id="staff-profile-page-phone"
                  >
                    <div>
                      <UserConfirmationModal
                        isOpen={isUserConfirmationModalOpen}
                        message={"Are you sure you want to reset the password?"}
                        onClose={() => setIsUserConfirmationModalOpen(false)}
                        onConfirm={resetStaffPassword}
                      />
                      <Tooltip
                        anchor={
                          <div>
                            <Button
                              onClick={() =>
                                setIsUserConfirmationModalOpen(true)
                              }
                            >
                              Resend Password
                            </Button>
                          </div>
                        }
                        content={
                          <p className="body-02">
                            Resend password for staff that did not sign in yet
                          </p>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8">
            <div className="flex flex-col gap-4">
              <p className="font-semibold">Dealership Level Permissions</p>

              <div className="grid grid-cols-6 gap-4 items-center">
                <p className="text-body text-dark-gray">Role</p>
                <div className="col-span-2 border rounded-radius-02">
                  {canManageUser ? (
                    <SelectBase
                      onSelect={(option) => {
                        if (!userData?.userDealership?.user?.id) return;

                        void handleUpdateUserDealershipRole(
                          userData?.userDealership?.user?.id,
                          option.value
                        );
                      }}
                      disabled={false}
                      label="Select a Role"
                      required={false}
                      options={roleOptions}
                      placeholder="Select a Role"
                      value={dealershipRole ?? undefined}
                      dataTestId="staff-profile-page-dealership-level-role"
                      inputElement={(open) => {
                        return (
                          <div
                            className={
                              "flex justify-between items-center w-full px-spacing-03 py-spacing-02 hover:text-interactive"
                            }
                          >
                            {dealershipRole
                              ? (roleOptions.find(
                                  (o) => o.value === dealershipRole
                                )?.label ?? dealershipRole)
                              : "Select A Dealership"}
                            {open ? <ExpandLess /> : <ExpandMore />}
                          </div>
                        );
                      }}
                    />
                  ) : (
                    <DashboardRoleBadge role={dealershipRole ?? undefined} />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-4 items-center">
                <p className="text-body text-dark-gray">Status</p>
                <div className="flex">
                  <Tag
                    color={isDealershipSuspended ? "LIGHT_RED" : "LIGHT_GREEN"}
                    label={isDealershipSuspended ? "Inactive" : "Active"}
                    dataTestId="staff-profile-page-active-status"
                  />
                </div>

                {canManageUser && (
                  <Button
                    variant={isDealershipSuspended ? "GHOST" : "GHOST_DANGER"}
                    size="XS"
                    onClick={() => {
                      if (typeof isDealershipSuspended !== "boolean") return;
                      void handleUpdateUserDealershipStatus(
                        userId,
                        isDealershipSuspended
                      );
                    }}
                    dataTestId="staff-profile-page-deactivate-button"
                  >
                    {isDealershipSuspended ? "Activate" : "Deactivate"}
                  </Button>
                )}
              </div>
            </div>

            {isGroupAdmin && (
              <div className="flex flex-col gap-4">
                <p className="font-semibold ">Group Level Permissions</p>
                <div className="grid grid-cols-6 gap-4 items-center">
                  <p className="text-body text-dark-gray">Role</p>
                  <div className="col-span-2 border rounded-radius-02">
                    {canManageUser ? (
                      <SelectBase
                        onSelect={(option) => {
                          if (!userData?.userDealership?.user?.id) return;

                          void handleUpsertUserGroupRole(
                            userData?.userDealership?.user?.id,
                            option.value
                          );
                        }}
                        disabled={false}
                        label="Select a Role"
                        required={false}
                        options={roleOptions}
                        placeholder="Select a Role"
                        value={groupRole ?? undefined}
                        dataTestId="staff-profile-page-dealership-group-role"
                        inputElement={(open) => {
                          return (
                            <div
                              className={
                                "flex justify-between items-center w-full px-spacing-03 py-spacing-02 hover:text-interactive"
                              }
                            >
                              {groupRole
                                ? (roleOptions.find(
                                    (o) => o.value === groupRole
                                  )?.label ?? groupRole)
                                : "Select A Dealership"}
                              {open ? <ExpandLess /> : <ExpandMore />}
                            </div>
                          );
                        }}
                      />
                    ) : (
                      <DashboardRoleBadge role={groupRole ?? undefined} />
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-4">
                  <p className="text-body text-dark-gray items-center">
                    Status
                  </p>

                  {groupRole ? (
                    <Fragment>
                      <div className="flex">
                        <Tag
                          color={isGroupSuspended ? "LIGHT_RED" : "LIGHT_GREEN"}
                          label={isGroupSuspended ? "Inactive" : "Active"}
                        />
                      </div>
                      {canManageUser && (
                        <Button
                          variant={isGroupSuspended ? "GHOST" : "GHOST_DANGER"}
                          size="XS"
                          onClick={() => {
                            if (typeof isGroupSuspended !== "boolean") return;
                            void handleUpdateUserGroupStatus(
                              userId,
                              isGroupSuspended
                            );
                          }}
                        >
                          {isGroupSuspended ? "Activate" : "Deactivate"}
                        </Button>
                      )}
                    </Fragment>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            )}
          </div>

          {dealership?.activeDealershipPerms.role === "ADMIN" && ( // Only admins can configure the CDK attributes
            <CDKConfiguration
              userId={userId}
              dealershipId={dealership?.activeDealershipPerms.dealershipId}
              isSuspended={!!userData?.userDealership?.isSuspended}
            />
          )}
        </div>
      </div>
      {ConfirmationModalComponent}
    </div>
  );
};
export default StaffPage;
