import { SortingState } from "@tanstack/react-table";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import { getFormStatus } from "~/lib/formStatus";
import { getDateFromUnknown } from "~/lib/helpers";
import {
  FieldOrdering,
  FormCollectionType,
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector,
  TransactionSource,
  TransactionStatus,
} from "../../../__generated__/backend/zeus";

export const formatDealType = (dealtype: FinanceType): string => {
  switch (dealtype) {
    case "CASH":
      return "Cash";
    case "FINANCE":
      return "Finance";
    case "LEASE":
      return "Lease";
    case "OUTSIDE_FINANCING":
      return "Outside financing";
    case "UNDECIDED":
      return "Undecided";
    default:
      return dealtype;
  }
};

export type Ordering = {
  firstName?: FieldOrdering;
  lastName?: FieldOrdering;
  createdAt?: FieldOrdering;
  status?: FieldOrdering;
  source?: FieldOrdering;
};

export const transformOrdering = (d: SortingState): Ordering => {
  const ordering: Ordering = {};
  d.forEach((column) => {
    if (column.id === "creationDate")
      ordering.createdAt = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "status")
      ordering.status = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "firstName")
      ordering.firstName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "lastName")
      ordering.lastName = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
    if (column.id === "source")
      ordering.source = column.desc ? FieldOrdering.desc : FieldOrdering.asc;
  });

  return ordering;
};

export type Pagination = {
  currentPage: number;
  pageSize: number;
  cursor?: string;
  direction: "before" | "after";
};

export const selector = (
  dealershipId: string,
  ordering: Ordering,
  pagination: Pagination,
  searchString: string,
  statusFilter: TransactionStatus[],
  sourceFilter: TransactionSource[],
  assigedStaffIdsFilter: string[],
  startTime?: Date,
  endTime?: Date
) =>
  Selector("Query")({
    dealership: [
      { id: dealershipId },
      {
        transactions: [
          {
            searchString: searchString.length > 0 ? searchString : undefined,

            filter: {
              status:
                statusFilter.length > 0
                  ? {
                      in: statusFilter,
                    }
                  : undefined,
              source:
                sourceFilter.length > 0 ? { in: sourceFilter } : undefined,
              OR:
                assigedStaffIdsFilter.length > 0
                  ? [
                      { bdcId: { in: assigedStaffIdsFilter } },
                      { salesPersonId: { in: assigedStaffIdsFilter } },
                      { fniManagerId: { in: assigedStaffIdsFilter } },
                      { salesManagerId: { in: assigedStaffIdsFilter } },
                    ]
                  : undefined,

              createdAt: {
                ...(startTime ? { gte: startTime } : {}),
                ...(endTime ? { lte: endTime } : {}),
              },
            },
            first:
              pagination.direction === "after"
                ? pagination.pageSize
                : undefined,
            last:
              pagination.direction === "before"
                ? pagination.pageSize
                : undefined,
            after:
              pagination.direction === "after" ? pagination.cursor : undefined,
            before:
              pagination.direction === "before" ? pagination.cursor : undefined,
            orderby: ordering,
          },
          {
            totalCount: true,
            edges: {
              cursor: true,
              node: {
                id: true,
                createdAt: true,
                status: true,
                source: true,
                financeType: true,
                buyer: {
                  userId: true,
                  firstName: true,
                  lastName: true,
                  hasInsurance: true,
                  idCards: {
                    id: true,
                  },
                  hasCoBuyer: true,
                  hasTradeVehicle: true,
                  hasPrequalApplication: true,
                  // Used to determine whether the user has a hard credit application
                  hardCreditApplications: [
                    { first: 1 },
                    {
                      edges: {
                        node: {
                          id: true,
                        },
                      },
                    },
                  ],
                  wantsToPreQualify: true,
                },
                coBuyer: {
                  userId: true,
                  firstName: true,
                  lastName: true,
                },
                salesPerson: {
                  firstName: true,
                  lastName: true,
                },
                fniManager: {
                  firstName: true,
                  lastName: true,
                },
                salesManager: {
                  firstName: true,
                  lastName: true,
                },
                bdc: {
                  firstName: true,
                  lastName: true,
                },
                tradeVehicle: {
                  year: true,
                  model: true,
                  make: true,
                },
                vehicle: {
                  year: true,
                  model: true,
                  make: true,
                },
                __alias: {
                  latestPrePurchaseCollection: {
                    formSubmissionCollections: [
                      {
                        filter: {
                          type: {
                            equals: FormCollectionType.PRE_PURCHASE,
                          },
                        },
                        orderBy: {
                          createdAt: OrderBy.Desc,
                        },
                        first: 1,
                      },
                      {
                        edges: {
                          node: {
                            createdAt: true,
                            status: true,
                          },
                        },
                      },
                    ],
                  },
                  latestPostPurchaseCollection: {
                    formSubmissionCollections: [
                      {
                        filter: {
                          type: {
                            equals: FormCollectionType.POST_PURCHASE,
                          },
                        },
                        orderBy: {
                          createdAt: OrderBy.Desc,
                        },
                        first: 1,
                      },
                      {
                        edges: {
                          node: {
                            createdAt: true,
                            status: true,
                          },
                        },
                      },
                    ],
                  },
                  latestTransactionLog: {
                    logs: [
                      {
                        orderBy: {
                          createdAt: OrderBy.Desc,
                        },
                      },
                      {
                        edges: {
                          node: {
                            createdAt: true,
                          },
                        },
                      },
                    ],
                  },
                  // Tried to be descriptive. Feel free to change if you find a better naming.
                  latestTransactionLogThatShouldRetriggerComplianceDocs: {
                    logs: [
                      {
                        orderBy: {
                          createdAt: OrderBy.Desc,
                        },
                        filter: {
                          shouldRetriggerComplianceDocs: true,
                        },
                        first: 1,
                      },
                      {
                        edges: {
                          node: {
                            createdAt: true,
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
          },
        ],
        hasEnabledComplianceForms: true,
        hasEnabledPostPurchaseDocs: true,
        hasEnabledReportingDashboard: true,
      },
    ],
  });

export type QueryResultType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof selector>
>;

export type Row = {
  financeType?: string;
  firstIdCard?: { id?: string | null };
  buyerHasInsurance?: boolean;
  vehicle?: string;
  buyerHasTradeVehicle?: boolean;
  buyerHasHardCreditApplications?: boolean;
  buyerHasPrequalApplications?: boolean;
  buyerWantsToPrequalify?: boolean;
  tradeVehicle?: string;
  salesPerson?: string;
  fniManager?: string;
  salesManager?: string;
  bdc?: string;
  transactionId: string;
  creationDate: Date;
  lastActivityDate: Date;
  status: TransactionStatus;
  source: TransactionSource;
  firstName?: string;
  lastName?: string;
  finishedId: boolean;
  finishedPreQual: boolean;
  finishedCredit: boolean;
  buyerUserId?: string;
  coBuyerUserId?: string;
  hasCoBuyer?: boolean;
  hasRequestedComplianceForms?: boolean;
  hasCompletedComplianceForms?: boolean;
  hasInvalidComplianceForms?: boolean;
};

export const dataTransform = (data?: QueryResultType): Row[] => {
  if (!data?.dealership?.transactions?.edges) {
    return [];
  }
  return data.dealership.transactions.edges
    .filter((t) => !!t)
    .map((transaction): Row => {
      const tn = transaction.node;
      const buyer = tn?.buyer;
      /**
       * Get form status
       */
      const formStatus = getFormStatus({
        prePurchaseCollection:
          tn?.latestPrePurchaseCollection?.edges?.[0]?.node,
        postPurchaseCollection:
          tn?.latestPostPurchaseCollection?.edges?.[0]?.node,
        latestTransactionLogThatShouldRetriggerComplianceDocs:
          tn?.latestTransactionLogThatShouldRetriggerComplianceDocs?.edges?.[0]
            ?.node,
        hasEnabledPostPurchaseDocs: Boolean(
          data.dealership?.hasEnabledPostPurchaseDocs
        ),
      });

      return {
        financeType: tn?.financeType ?? undefined,
        firstIdCard: buyer?.idCards?.[0] ?? undefined,
        buyerHasInsurance: tn?.buyer?.hasInsurance ?? undefined,
        buyerHasTradeVehicle: tn?.buyer?.hasTradeVehicle ?? undefined,
        buyerHasHardCreditApplications: tn?.buyer?.hardCreditApplications?.edges
          ?.length
          ? true
          : undefined,
        buyerHasPrequalApplications: buyer?.hasPrequalApplication,
        vehicle: tn?.vehicle
          ? `${tn.vehicle.year} ${tn.vehicle.make} ${tn.vehicle.model}`
          : undefined,
        tradeVehicle: tn?.tradeVehicle
          ? `${tn.tradeVehicle.year} ${tn.tradeVehicle.make} ${tn.tradeVehicle.model}`
          : undefined,
        fniManager: tn?.fniManager
          ? `${tn.fniManager.firstName} ${tn.fniManager.lastName}`
          : undefined,
        salesPerson: tn?.salesPerson
          ? `${tn.salesPerson.firstName} ${tn.salesPerson.lastName}`
          : undefined,
        salesManager: tn?.salesManager
          ? `${tn.salesManager.firstName} ${tn.salesManager.lastName}`
          : undefined,
        bdc: tn?.bdc ? `${tn.bdc.firstName} ${tn.bdc.lastName}` : undefined,
        transactionId: tn?.id ?? "no-transaction-id",
        creationDate: tn?.createdAt
          ? new Date(tn.createdAt as Date)
          : new Date(),
        lastActivityDate:
          getDateFromUnknown(
            tn?.latestTransactionLog?.edges?.[0]?.node?.createdAt
          ) ?? new Date(),
        status: tn?.status ?? TransactionStatus.NOT_STARTED, // This should never happen
        source: tn?.source ?? TransactionSource.KIOSK, // This should never happen
        firstName: buyer?.firstName ?? undefined,
        lastName: buyer?.lastName ?? undefined,
        coBuyerUserId: tn?.coBuyer?.userId ?? undefined,
        hasCoBuyer: tn?.buyer?.hasCoBuyer ?? undefined,
        buyerUserId: tn?.buyer?.userId ?? undefined,
        finishedId: false,
        finishedCredit: false,
        finishedPreQual: false,
        hasRequestedComplianceForms: Boolean(
          // The pre-purchase forms are requested always first. So it is sufficient to just check that these have been requested
          formStatus.requestedPrePurchaseFormsAt
        ),
        hasInvalidComplianceForms:
          formStatus.hasInvalidPrePurchaseForms ||
          formStatus.hasInvalidPostPurchaseForms,
        hasCompletedComplianceForms:
          formStatus.hasCompletedPrePurchaseForms &&
          formStatus.hasCompletedPostPurchaseForms,
      };
    });
};

// Can be used to extract single element out of array of items
// E.g: Select a type of Customer from a list of Customer
export type ExtractionType<T> = T extends (infer U)[] ? U : never;
