import { useSuspenseQuery } from "@tanstack/react-query";
import {
  Banner,
  Button,
  DataStatus,
  Tooltip,
  useConfirmationModal,
} from "@thedealersconcierge/components";
import { DataStatusType } from "@thedealersconcierge/components/src/dataStatus/dataStatusConfig";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtom } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  ComplianceTriple,
  FormCollectionStatus,
  FormCollectionType,
  OrderBy,
  TransactionLifecycle,
} from "~/__generated__/backend/zeus";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlQueryClient } from "~/lib/backend";
import { getFormStatus } from "~/lib/formStatus";
import { dealershipAtom } from "~/state";
import {
  refetchTransactionQuery,
  TransactionQueryType,
} from "../_queries/transactionQuery";
import ReqSignaturesConfirmModal from "./ReqSignaturesConfirmModal";

const TransactionDocuments: FC<{
  transactionId: string;

  /**
   * @deprecated
   */
  transaction: TransactionQueryType["transaction"];
}> = ({ transactionId, transaction }) => {
  const { showWarningModal, ConfirmationModalComponent } =
    useConfirmationModal();
  const [prePurchaseModalIsOpen, setPrePurchaseModalIsOpen] = useState(false);
  const [postPurchaseModalIsOpen, setPostPurchaseModalIsOpen] = useState(false);
  const [activeDealershipPerms] = useAtom(dealershipAtom);
  const { t } = useTranslation();

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(2000);

  const complianceInfo = useSuspenseQuery({
    queryKey: ["transaction", transactionId, "complianceInfo"],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          { id: transactionId },
          {
            complianceFormsData: {
              prePurchasePreCondition: {
                hasBuyerSubmittedHca: true,
                hasCoBuyerSubmittedHca: true,
                hasStaffAssigned: true,
                hasVehicleForPurchase: true,
              },
              canRequestPrePurchaseForms: true,
              canRequestPostPurchaseForms: true,
            },
            dealership: {
              hasEnabledComplianceForms: true,
              hasEnabledPostPurchaseDocs: true,
              hasEnabledRecallMasters: true,
            },
            __alias: {
              latestRecallFormCollection: {
                formSubmissionCollections: [
                  {
                    filter: {
                      type: {
                        equals: FormCollectionType.VEHICLE_RECALL,
                      },
                    },
                    orderBy: {
                      createdAt: OrderBy.Desc,
                    },
                    first: 1,
                  },
                  {
                    edges: {
                      node: {
                        status: true,
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      }),
  });

  if (!complianceInfo.data.transaction) {
    return <Banner type="ERROR" message={<span>No such transaction</span>} />;
  }

  /**
   * Dealership info
   */
  const dealership = complianceInfo.data.transaction.dealership;

  /**
   * Recall info
   */
  const recallFormCollection =
    complianceInfo.data.transaction.latestRecallFormCollection?.edges?.[0]
      ?.node;

  /**
   * Compliance info
   */
  const complInfo = complianceInfo.data.transaction.complianceFormsData;
  const formStatus = getFormStatus({
    prePurchaseCollection:
      transaction?.latestPrePurchaseCollection?.edges?.[0]?.node,
    postPurchaseCollection:
      transaction?.latestPostPurchaseCollection?.edges?.[0]?.node,
    latestTransactionLogThatShouldRetriggerComplianceDocs:
      transaction?.latestTransactionLogThatShouldRetriggerComplianceDocs
        ?.edges?.[0]?.node,
    hasEnabledPostPurchaseDocs: Boolean(
      transaction?.dealership?.hasEnabledPostPurchaseDocs
    ),
  });

  // Compute the current state of the pre purchase docs
  const completedPrePurchaseDocuments: {
    status: DataStatusType;
    text: string;
  } = (() => {
    if (formStatus.hasInvalidPrePurchaseForms) {
      return {
        status: "REQUIRES_ATTENTION",
        text: "Pre-purchase forms need to be re-requested",
      };
    }

    if (
      formStatus.requestedPrePurchaseFormsAt &&
      formStatus.hasCompletedPrePurchaseForms
    ) {
      return { status: "COMPLETE", text: "Pre-purchase forms are complete" };
    }

    return { status: "INCOMPLETE", text: "Pre-purchase forms are incomplete" };
  })();

  // Compute current state for post-purchase docs
  const completedPostPurchaseDocuments: {
    status: DataStatusType;
    text: string;
  } = (() => {
    if (formStatus.hasInvalidPostPurchaseForms) {
      return {
        status: "REQUIRES_ATTENTION",
        text: "Post-purchase forms need to be re-requested",
      };
    }

    if (
      formStatus.requestedPostPurchaseFormsAt &&
      formStatus.hasCompletedPostPurchaseForms
    ) {
      return { status: "COMPLETE", text: "Post-purchase forms are complete" };
    }

    return { status: "INCOMPLETE", text: "Post-purchase forms are incomplete" };
  })();

  /**
   * Whether the logged in user has the permission to request signatures
   */
  const canRequestSignature = permissionChecker(
    "requestSignatures",
    activeDealershipPerms?.activeDealershipPerms
  );
  const ReRequestWarningMessage = (
    <span>
      You are about to request a new set of compliance forms to be executed,
      even though signatures have already been requested or the forms have been
      executed.
      <br />
      <br />
      Please note:
      <br />
      <br />
      <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
        <li>
          Requesting signatures again will require the customer to log into the
          Buyer Portal and sign the compliance forms.
        </li>
        <li>
          This action will{" "}
          <b>cancel any previously executed compliance forms</b>.
        </li>
      </ul>
      <br />
      Are you sure you want to proceed?
    </span>
  );
  const handleRequestPrePurchaseForms = async () => {
    /**
     * If we haven't requested the pre-purchase forms yet, we are directly showing the
     * request signature modal.
     */
    if (!formStatus.requestedPrePurchaseFormsAt) {
      setPrePurchaseModalIsOpen(true);
    } else {
      /**
       * If we've already requested the pre-purchase forms previously we are showing
       * a warning message and let the user confirm they understand the consequences.
       */
      const confirmed = await showWarningModal({
        message: ReRequestWarningMessage,
      });

      if (confirmed) {
        setPrePurchaseModalIsOpen(true);
      }
    }
  };
  const handleRequestPostPurchaseForms = async () => {
    /**
     * If we haven't requested the post-purchase forms yet, we are directly showing the
     * request signature modal.
     */
    if (!formStatus.requestedPostPurchaseFormsAt) {
      setPostPurchaseModalIsOpen(true);
    } else {
      /**
       * If we've already requested the post-purchase forms previously we are showing
       * a warning message and let the user confirm they understand the consequences.
       */
      const confirmed = await showWarningModal({
        message: ReRequestWarningMessage,
      });

      if (confirmed) {
        setPostPurchaseModalIsOpen(true);
      }
    }
  };

  return (
    <>
      {ConfirmationModalComponent}

      <ReqSignaturesConfirmModal
        lastRequestedAt={formStatus.requestedPrePurchaseFormsAt ?? "NEVER"}
        forLifeCycle={TransactionLifecycle.PRE_PURCHASE}
        transaction={transaction}
        isOpen={prePurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPrePurchaseModalIsOpen(false);
        }}
        onSuccess={() => {
          toast.success(
            "Successfully requested signatures for pre-purchase documents"
          );
          setPrePurchaseModalIsOpen(false);

          // Fire and forget
          Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            complianceInfo.refetch(),
          ]).catch(console.error);
        }}
        onCancel={() => {
          setPrePurchaseModalIsOpen(false);
        }}
      />

      <ReqSignaturesConfirmModal
        lastRequestedAt={formStatus.requestedPostPurchaseFormsAt ?? "NEVER"}
        forLifeCycle={TransactionLifecycle.POST_PURCHASE}
        transaction={transaction}
        isOpen={postPurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPostPurchaseModalIsOpen(false);
        }}
        onSuccess={() => {
          toast.success(
            t("Successfully requested signatures for post-purchase documents")
          );
          setPostPurchaseModalIsOpen(false);

          // Fire and forget
          Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            complianceInfo.refetch(),
          ]).catch(console.error);
        }}
        onCancel={() => {
          setPostPurchaseModalIsOpen(false);
        }}
      />

      {dealership?.hasEnabledComplianceForms && (
        <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
          <div className="flex flex-row justify-between">
            <h2 className="text-heading-1">Forms</h2>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center">
              {/**
               * Pre-purchase forms
               */}
              <div className="flex flex-row space-x-6 w-1/2">
                <div className="relative">
                  <Tooltip
                    hide={completedPrePurchaseDocuments.status === "COMPLETE"}
                    anchor={
                      <div>
                        <DataStatus
                          status={completedPrePurchaseDocuments.status}
                        />
                      </div>
                    }
                    content={<p>{completedPrePurchaseDocuments.text}</p>}
                  />
                </div>

                <div className="text-body text-dark-gray">
                  {t("Pre-Purchase Forms")}
                </div>
              </div>

              {canRequestSignature && (
                <Tooltip
                  hide={complInfo.canRequestPrePurchaseForms}
                  anchor={
                    <Button
                      label={
                        formStatus.requestedPrePurchaseFormsAt
                          ? t("Request Signatures Again")
                          : t("Request Signatures")
                      }
                      variant="GHOST"
                      size="SMALL"
                      onClick={() => {
                        void handleRequestPrePurchaseForms();
                      }}
                      disabled={!complInfo.canRequestPrePurchaseForms}
                      dataTestId="request-pre-purchase-signature-button"
                    />
                  }
                  content={
                    <div className="flex flex-col space-y-2">
                      {!complInfo.prePurchasePreCondition
                        .hasVehicleForPurchase && (
                        <DataStatus
                          label={t("Transaction has no vehicle")}
                          status={"INCOMPLETE"}
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                      {!complInfo.prePurchasePreCondition.hasStaffAssigned && (
                        <DataStatus
                          label={t("Staff is assigned")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                      {complInfo.prePurchasePreCondition
                        .hasBuyerSubmittedHca === ComplianceTriple.FALSE && (
                        <DataStatus
                          label={t("Missing buyers credit application")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}

                      {complInfo.prePurchasePreCondition
                        .hasCoBuyerSubmittedHca === ComplianceTriple.FALSE && (
                        <DataStatus
                          label={t("Missing co-buyers credit application")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                    </div>
                  }
                />
              )}
            </div>

            {dealership.hasEnabledPostPurchaseDocs && (
              <div className="flex flex-row items-center">
                {/**
                 * Post-purchase forms
                 */}
                <div className="flex flex-row space-x-6 w-1/2">
                  <div className="relative">
                    <Tooltip
                      hide={
                        completedPostPurchaseDocuments.status === "COMPLETE"
                      }
                      anchor={
                        <div>
                          <DataStatus
                            status={completedPostPurchaseDocuments.status}
                          />
                        </div>
                      }
                      content={<p>{completedPostPurchaseDocuments.text}</p>}
                    />
                  </div>

                  <div className="text-body text-dark-gray col-span-3">
                    Post-Purchase Forms
                  </div>
                </div>

                {canRequestSignature && (
                  <Tooltip
                    hide={complInfo.canRequestPostPurchaseForms}
                    anchor={
                      <Button
                        label={
                          formStatus.requestedPostPurchaseFormsAt
                            ? t("Request Signatures Again")
                            : t("Request Signatures")
                        }
                        variant="GHOST"
                        size="SMALL"
                        onClick={() => {
                          void handleRequestPostPurchaseForms();
                        }}
                        disabled={!complInfo.canRequestPostPurchaseForms}
                        dataTestId="request-post-purchase-signature-button"
                      />
                    }
                    content={
                      <div className="flex flex-col space-y-2">
                        {completedPrePurchaseDocuments.status !==
                          "COMPLETE" && (
                          <DataStatus
                            label={t(
                              "Pre-purchase forms have not yet been signed by the customer"
                            )}
                            status={completedPrePurchaseDocuments.status}
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        )}
                      </div>
                    }
                  />
                )}
              </div>
            )}

            {/**
             * Recall forms
             */}
            {dealership.hasEnabledRecallMasters && // We only consider this for dealerships that have the feature enabled
              recallFormCollection && ( // Only when there is a collection to be signed, we show the indicator as this is not required for all transactions. Only the ones with a used purchase vehicle.
                <div className="flex flex-row space-x-6 w-1/2">
                  <DataStatus
                    status={
                      recallFormCollection.status === FormCollectionStatus.FINAL
                        ? "COMPLETE"
                        : "INCOMPLETE"
                    }
                  />

                  <div className="text-body text-dark-gray col-span-3">
                    Recall Forms
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default withSuspense(
  TransactionDocuments,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
