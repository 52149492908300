import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { DataStatus } from "@thedealersconcierge/components";
import { FC, Fragment, useState } from "react";
import Button from "~/components/Button";
import { gqlQueryClient } from "~/lib/backend";

type Props = {
  transactionId: string;
  userId: string;
};

interface VerificationItem {
  title: string;
  status: string;
  isExactMatch: boolean;
}

import { IntellicheckRawPersistedSchema } from "@thedealersconcierge/lib/codecs/agility-credit";
import { z } from "zod";

// Match detail types from the provided schemas
const MatchDetailSchema = z.object({
  details: z.string().nullish(),
  similarityScore: z.number().nullish(),
  similarityThreshold: z.number().nullish(),
});
type MatchDetail = z.infer<typeof MatchDetailSchema>;

const isExactMatch = (matchDetail: MatchDetail | null | undefined): boolean => {
  if (!matchDetail) return false;
  return (
    matchDetail.details?.includes("Exact match") === true ||
    matchDetail.similarityScore === 100
  );
};

const processLicenseData = (
  rawData: IntellicheckRawPersistedSchema
): VerificationItem[] => {
  return [
    {
      title: "Driver License Expiry",
      status:
        rawData.idcheck.data.expired === "No"
          ? "Driver license is not expired"
          : "Driver license is expired",
      isExactMatch: false,
    },
    {
      title: "Barcode",
      status: "Barcode is OK",
      isExactMatch: false,
    },
    {
      title: "Document Recognized",
      status:
        rawData.OCR.data.documentRecognized === 1
          ? "Document is recognized"
          : "Document is not recognized",
      isExactMatch: false,
    },
    {
      title: "Address",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.addressMatchDetails),
    },
    {
      title: "Driver License Class",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.dlClassMatchDetails),
    },
    {
      title: "Driver License Endorsement",
      status: "",
      isExactMatch: isExactMatch(
        rawData.ocr_match.data.dlEndorsementMatchDetails
      ),
    },
    {
      title: "Driver License Restrictions",
      status: "",
      isExactMatch: isExactMatch(
        rawData.ocr_match.data.dlRestrictionsMatchDetails
      ),
    },
    {
      title: "Date of Birth",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.dobMatchDetails),
    },
    {
      title: "Document Number",
      status: "",
      isExactMatch: isExactMatch(
        rawData.ocr_match.data.documentNumberMatchDetails
      ),
    },
    {
      title: "Expiration Date",
      status: "",
      isExactMatch: isExactMatch(
        rawData.ocr_match.data.expirationDateMatchDetails
      ),
    },
    {
      title: "Eye Color",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.eyeColorMatchDetails),
    },
    {
      title: "Height",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.heightMatchDetails),
    },
    {
      title: "Issue Date",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.issueDateMatchDetails),
    },
    {
      title: "Issuer Name",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.issuerNameMatchDetails),
    },
    {
      title: "Name",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.nameMatchDetails),
    },
    {
      title: "Real ID",
      status: "",
      isExactMatch: isExactMatch(rawData.ocr_match.data.realIdMatchDetails),
    },
  ];
};

const VerificationItem: FC<{
  number: number;
  title: string;
  status?: string;
  isExactMatch: boolean;
}> = ({ number, title, status, isExactMatch }) => {
  const statusColor =
    status?.includes("not expired") ||
    status?.includes("OK") ||
    status?.includes("recognized")
      ? "text-green-500"
      : "text-green-500";

  const matchText = isExactMatch
    ? "Exact match between barcode and front data"
    : "Considered match with slight discrepancy in either barcode and/or front data";

  return (
    <div className="flex items-center bg-gray-100 p-4 rounded-md mb-2">
      <div className="flex-shrink-0 w-8 text-center font-medium">{number}</div>
      <div className="flex-grow ml-4">
        <div className="font-medium text-gray-700">{title}</div>
        <div className={statusColor}>{status || matchText}</div>
      </div>
      <div className="flex-shrink-0">
        <DataStatus status={"COMPLETE"} />
      </div>
    </div>
  );
};

/**
 * TODO: Move this into an individual page
 *
 * For our AI efforts we are going to prefer direct routes over modal or other
 * in page visual elements (When there is significant data to show).
 */
export default function IdvModal({ transactionId, userId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery({
    // We can safely reuse the same queryKey for both the trade and purchase vehicle
    queryKey: ["additional-idv", transactionId, userId],
    queryFn: async () =>
      gqlQueryClient()({
        customer: [
          {
            transactionId,
            userId,
          },
          {
            compliance: {
              hasRequestedIdv: { verificationFields: true },
            },
          },
        ],
      }),
  });

  const verificationFields =
    IntellicheckRawPersistedSchema.optional().safeParse(
      data?.customer?.compliance.hasRequestedIdv.verificationFields
    );

  const verificationItems =
    verificationFields.success && verificationFields.data
      ? processLicenseData(verificationFields.data)
      : undefined;

  return (
    <>
      <Button
        variant="TEXT_ONLY"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        ID Verifications
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-heading-2">
                    ID Verification
                  </Dialog.Title>

                  <div className="h-[50vh] overflow-auto my-5">
                    {verificationItems?.map((item, idx) => (
                      <VerificationItem
                        key={idx}
                        number={idx}
                        title={item.title}
                        status={item.status}
                        isExactMatch={item.isExactMatch}
                      />
                    ))}
                  </div>

                  <div className="flex flex-col space-y-2 p-4">
                    <div className="text-center text-sm">Source </div>
                  </div>
                  <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                    <button
                      type="button"
                      className="button-primary"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
