import { useForm } from "@tanstack/react-form-old";
import { useState } from "react";
import { newPasswordAction } from "~/actions/cognitoNewPasswordAction";
import Button from "~/components/Button";
import { useNavigate } from "~/router";
import { UiState } from "./types";

type Props = {
  setUiState: (state: UiState) => void;
};

export default function SetNewPassword({ setUiState }: Props) {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const handleFormChange = (key: string, value?: string | boolean) => {
    if (!value) {
      return "This field is required";
    }
  };
  const form = useForm({
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: async (value) => {
      setError(null);

      try {
        const res = await newPasswordAction(value.newPassword);

        if (res === "OK") {
          navigate("/dashboard");

          return;
        }

        setUiState(res);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Unknown error");
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-10 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex flex-col space-y-4 items-center">
          <h2 className="text-2xl font-bold">New Password</h2>

          <p>To secure your account you need to set a new password.</p>
        </div>

        <div className="space-y-4">
          <form.Field
            name="newPassword"
            onChange={(value) => handleFormChange("newPassword", value)}
            children={(field) => (
              <input
                name={field.name}
                value={field.state.value ?? ""}
                onBlur={field.handleBlur}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                placeholder="New Password"
                type="password"
                className="w-full border border-[#E9EBED] p-4 rounded-lg"
              />
            )}
          />

          <form.Field
            name="confirmNewPassword"
            onChange={(value) => handleFormChange("password", value)}
            children={(field) => (
              <input
                name={field.name}
                value={field.state.value ?? ""}
                onBlur={field.handleBlur}
                onChange={(e) => {
                  field.handleChange(e.target.value);
                }}
                placeholder="Confirm New Password"
                type="password"
                className="w-full border border-[#E9EBED] p-4 rounded-lg"
              />
            )}
          />

          <p className="text-body-3">
            Your password must contain at least one special character.
          </p>
        </div>

        <div className="flex flex-col space-y-4 items-center">
          <div className="h-[1rem]">
            {error && <p className="text-error">{error}</p>}
          </div>

          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit, isSubmitting]) => (
              <Button
                type="submit"
                disabled={!canSubmit}
                loading={isSubmitting}
              >
                Set New Password
              </Button>
            )}
          />
        </div>
      </form>
    </form.Provider>
  );
}
