import { Fragment } from "react";

export default function AnalyticsBannerSkeleton() {
  return (
    <div className="flex flex-col space-y-spacing-04 animate-pulse">
      {/* Date Skeleton */}
      <div className="bg-gray-300 h-6 w-48 rounded" />

      {/* Boxes Container */}
      <div className="flex flex-row gap-spacing-04">
        <div className="bg-primary p-spacing-06 rounded-radius-02 flex flex-col space-y-spacing-03 max-h-[112px] max-w-[256px]">
          <div className="bg-gray-300 h-6 w-12 rounded" />
          <div className="flex justify-start items-center gap-spacing-02">
            <Fragment>
              <div className="flex flex-row items-baseline gap-spacing-02">
                {/* Today label and count placeholders */}
                <div className="bg-gray-300 h-6 w-8 rounded" />
                <div className="bg-gray-300 h-6 w-12 rounded" />
              </div>
            </Fragment>
          </div>
        </div>

        <div className="bg-primary p-spacing-06 rounded-radius-02 flex flex-col space-y-spacing-03 max-h-[112px] max-w-[256px]">
          <div className="bg-gray-300 h-6 w-12 rounded" />
          <div className="flex justify-start items-center gap-spacing-02">
            <Fragment>
              <div className="flex flex-row items-baseline gap-spacing-02">
                {/* Today label and count placeholders */}
                <div className="bg-gray-300 h-6 w-8 rounded" />
                <div className="bg-gray-300 h-6 w-12 rounded" />
              </div>
            </Fragment>
          </div>
        </div>

        <div className="bg-primary p-spacing-06 rounded-radius-02 flex flex-col space-y-spacing-03 max-h-[112px] max-w-[256px]">
          <div className="bg-gray-300 h-6 w-12 rounded" />
          <div className="flex justify-start items-center gap-spacing-02">
            <Fragment>
              <div className="flex flex-row items-baseline gap-spacing-02">
                {/* Today label and count placeholders */}
                <div className="bg-gray-300 h-6 w-8 rounded" />
                <div className="bg-gray-300 h-6 w-12 rounded" />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
}
