import {
  Cancel,
  CheckCircleOutline,
  ErrorOutline,
  InfoOutlined
} from '@mui/icons-material';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { BannerType } from './bannerConfig';

/**
 * Banners display messages about important changes, persistent conditions, and announcements above the section they apply to.
 * They communicate most errors caused by the user that are not on the field level.
 *
 * Details:
 * - Banners are persistent (stays visible)
 * - If the message applies to the entire page, the banner is located at the top of the page (beneath the header). Otherwise, it is located above the section it applies to.
 * - Messages contain 100 characters or less and can contain links.
 *
 * Banner types:
 * - `INFORMATIONAL`: General information, updates, or advice
 * - `SUCCESS`: Success messages that can contain a non-urgent call to action (e.g. Review changes, View details, etc.)
 * - `WARNING`: Warning messages (e.g. fix a problem before proceeding to the next step, upcoming expirations, changing a setting that may have unintended consequences)
 * - `ERROR`: This is an error banner. Here is a notification to let you know that something is wrong.
 *
 * Example usage:
 *
 * ```tsx
 * <Banner
 *  message="There have been material changes to the transaction. The compliance forms need to be re-requested."
 *  type="WARNING"
 * />
 * ```
 *
 * @param message: Message to display
 * @param type: The type of banner (`INFORMATIONAL`, `SUCCESS`, `WARNING`, `ERROR`)
 * @param className: Optional custom classname applied to the outer div
 */
const Banner: FC<{
  message: ReactElement;
  type: BannerType;
  className?: string;
}> = ({ message, type, className }) => {
  return (
    <div
      className={twMerge(
        classNames(
          'flex flex-row w-full justify-center py-spacing-01 px-spacing-04 space-x-spacing-02 rounded-radius-02 border',
          {
            'bg-info-light border-info-dark': type === 'INFORMATIONAL',
            'bg-success-light border-success-dark': type === 'SUCCESS',
            'bg-warning-light border-warning-dark': type === 'WARNING',
            'bg-danger-light border-danger-dark': type === 'ERROR'
          }
        ),
        className
      )}
    >
      <div
        className={classNames('flex items-center justify-center', {
          'text-info-dark': type === 'INFORMATIONAL',
          'text-success-dark': type === 'SUCCESS',
          'text-warning-dark': type === 'WARNING',
          'text-danger-dark': type === 'ERROR'
        })}
      >
        {type === 'INFORMATIONAL' && <InfoOutlined className="size-5" />}

        {type === 'SUCCESS' && <CheckCircleOutline className="size-5" />}

        {type === 'WARNING' && <ErrorOutline className="size-5" />}

        {type === 'ERROR' && <Cancel className="size-5" />}
      </div>

      <p className="w-full body-02 text-primary">{message}</p>
    </div>
  );
};

export default Banner;
