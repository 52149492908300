import { QueryClient } from "@tanstack/react-query";
import config from "./config";
import {
  DemoPrefillVehicle,
  NullPrefillVehicle,
  PrefillVehicle,
  PrefillVehicleInterface,
} from "./service/prefill-vehicle";

type Container = {
  prefillVehicle: PrefillVehicleInterface;
  queryClient: QueryClient;
};

// This is a only a setup
export const g: { reg: Container } = {
  reg: {
    prefillVehicle: new NullPrefillVehicle(),
    queryClient: new QueryClient(),
  },
};

// This script needs to be run at the very fiorst place when application run for the registery to be initiliazed properly
export const mkProductionRegistry = () => {
  g.reg = {
    prefillVehicle: config.useDemoPrefillVehicle
      ? new DemoPrefillVehicle()
      : new PrefillVehicle(),
    queryClient: new QueryClient(),
  };
};
