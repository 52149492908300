// This selector is not handling pagination yet

import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";

const formsubmissionSelector = Selector("FormSubmission")({
  id: true,
  status: true,
  access: true,
  formSubmissionData: true,
  userId: true,
  file: {
    id: true,
    url: true,
    uploader: {
      firstName: true,
      lastName: true,
    },
  },
  form: {
    displayTitle: true,
  },
  customer: {
    firstName: true,
    lastName: true,
  },
  createdAt: true,
  type: true,
});

const documentSelector = Selector("Document")({
  id: true,
  access: true,
  transactionId: true,
  title: true,
  category: true,
  createdAt: true,
  userId: true,
  pdfIsEncrypted: true,
  file: {
    id: true,
    url: true,
    uploader: {
      firstName: true,
      lastName: true,
    },
  },
  customer: {
    firstName: true,
    lastName: true,
  },
});

const consumerCustomerSelector = () =>
  Selector("Customer")({
    userId: true,
    disassociatedRole: true,
    residentialAddresses: [
      {
        first: 3,
      },
      {
        edges: {
          node: {
            fullAddress: true,
            timelinePosition: true,
          },
        },
      },
    ],
    transaction: {
      id: true,
      buyerId: true,
      coBuyerId: true,
      createdAt: true,
      status: true,
    },
  });

export type ConsumerCustomer = InputType<
  GraphQLTypes["Customer"],
  ReturnType<typeof consumerCustomerSelector>
>;

// Have to implement pagination in the future
export const consumerDatabaseSelector = (
  userId: string,
  dealershipId: string
) =>
  Selector("Query")({
    userDealership: [
      {
        userId,
        dealershipId,
      },
      {
        user: {
          id: true,
          firstName: true,
          lastName: true,
          phoneNumber: true,
          email: true,
          customers: [
            // Needs to handle pagination later on
            {
              first: 20,
            },
            {
              edges: {
                node: consumerCustomerSelector(),
              },
            },
          ],
        },
      },
    ],
  });

export const customerFilesSelector = (
  userId: string,
  transactionId: string
) => {
  return Selector("Query")({
    customer: [
      {
        transactionId: transactionId,
        userId: userId,
      },
      {
        firstName: true,
        lastName: true,
        formSubmissions: [{}, { edges: { node: formsubmissionSelector } }],
        // TODO: Should add pagination later on here
        documents: [
          { first: 15 },
          {
            edges: {
              node: documentSelector,
            },
          },
        ],
      },
    ],
  });
};

export type ConsumerDatabaseQueryResultType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof consumerDatabaseSelector>
>;
