import { dateToString } from "@thedealersconcierge/lib/utils/dates";
import stringify from "json-stable-stringify";
import {
  FinanceType,
  GraphQLTypes,
  InputType,
  Selector,
  TransactionSource,
  TransactionStatus,
  VehicleLifeCycleStage,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

export const TransactionStatusUpdateDealershipAnalyticsSelector = ({
  dealershipId,
  startDate,
  endDate,
  isLeaseBuyOut,
  source,
  financeType,
  purchaseVehicleLifeCycleStage,
  transactionStatus,
}: {
  dealershipId?: string;
  startDate?: Date;
  endDate?: Date;
  transactionStatus: TransactionStatus[];

  isLeaseBuyOut?: boolean;
  source?: TransactionSource[];
  financeType?: FinanceType[];
  purchaseVehicleLifeCycleStage?: VehicleLifeCycleStage;
}) => {
  return Selector("Query")({
    dealership: [
      {
        id: dealershipId,
      },
      {
        id: true,

        transactionStatusUpdateDealershipAnalyticsCount: [
          {
            filter: {
              status: { in: transactionStatus },
              ...(startDate || endDate
                ? {
                    eventAt: {
                      ...(startDate ? { gte: startDate } : {}),
                      ...(endDate ? { lte: endDate } : {}),
                    },
                  }
                : {}),

              ...(source !== undefined ? { source: { in: source } } : {}),
              ...(financeType !== undefined
                ? { financeType: { in: financeType } }
                : {}),

              ...(purchaseVehicleLifeCycleStage !== undefined
                ? {
                    purchaseVehicleLifeCycleStage: {
                      equals: purchaseVehicleLifeCycleStage,
                    },
                  }
                : {}),

              ...(isLeaseBuyOut !== undefined
                ? { isLeaseBuyOut: isLeaseBuyOut }
                : {}),
            },
          },
          true,
        ],
      },
    ],
  });
};

export const transactionAnalyticQuery = ({
  dealershipId,
  startDate,
  endDate,
  isLbo,
  source,
  financeType,
  purchaseVehicleLifeCycleStage,
  transactionStatus,
}: {
  dealershipId?: string;
  startDate?: Date;
  endDate?: Date;
  isLbo?: boolean;
  source?: TransactionSource[];
  transactionStatus: TransactionStatus[];
  financeType?: FinanceType[];
  purchaseVehicleLifeCycleStage?: VehicleLifeCycleStage;
}) => {
  return {
    // Ensure query leverage indexes
    enabled: Boolean(dealershipId && startDate && endDate && transactionStatus),
    queryKey: [
      "transaction-status-update-analytic-query",
      dealershipId,
      stringify(startDate),
      stringify(endDate),
      stringify(isLbo),
      stringify(source),
      stringify(financeType),
      stringify(transactionStatus),
      stringify(purchaseVehicleLifeCycleStage),
    ],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: async () =>
      gqlQueryClient()(
        TransactionStatusUpdateDealershipAnalyticsSelector({
          dealershipId,
          startDate,
          endDate,
          isLeaseBuyOut: isLbo,
          source,
          purchaseVehicleLifeCycleStage,
          financeType,
          transactionStatus,
        })
      ),
  };
};

export type TransactionStatusUpdateAnalyticsQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof TransactionStatusUpdateDealershipAnalyticsSelector>
>;

export const TransactionStatusUpdateSalesRankingSelector = ({
  dealershipId,
  startDate,
  transactionStatus,
  page,
  pageSize,
}: {
  dealershipId?: string;
  startDate: Date;
  transactionStatus: TransactionStatus[];
  page: number;
  pageSize: number;
}) => {
  return Selector("Query")({
    dealership: [
      {
        id: dealershipId,
      },
      {
        id: true,

        transactionUpdateStatusSalesRankingGrouped: [
          {
            filter: {
              status: { in: transactionStatus },
            },

            // Right now we are passing it as a string of date; this will need a better handling
            startDate: dateToString(startDate),

            page,
            pageSize,
          },
          {
            userId: true,
            firstName: true,
            currentMonth: true,
            lastName: true,
            lastMonth: true,
            twoMonthsAgo: true,
          },
        ],
      },
    ],
  });
};

export const transactionUpdateSalesRanking = ({
  dealershipId,
  startDate,
  transactionStatus,
  page,
  pageSize,
}: {
  dealershipId?: string;
  startDate?: Date;
  transactionStatus: TransactionStatus[];
  page: number;
  pageSize: number;
}) => {
  return {
    // Ensure query leverage indexes
    enabled: Boolean(dealershipId && startDate && transactionStatus),
    queryKey: [
      "transaction-status-update-analytic-ranking-query",
      stringify(startDate),
      stringify(transactionStatus),
      stringify(dealershipId),
      stringify(page),
      stringify(pageSize),
    ],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: () => {
      if (startDate && dealershipId) {
        return gqlQueryClient()(
          TransactionStatusUpdateSalesRankingSelector({
            dealershipId,
            startDate,
            transactionStatus,
            page,
            pageSize,
          })
        );
      }
    },
  };
};

export type SalesRankingQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof TransactionStatusUpdateSalesRankingSelector>
>;
