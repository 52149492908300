import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { ChangeEvent, Fragment, useState } from 'react';
import { ChevronDown } from 'react-feather';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

export type DropdownCheckboxOption<T> = {
  key: string;
  label: string;
  value: T;
  deprecated?: boolean;
};

export type DropdownCheckboxProps<T> = {
  /**
   * Array of dropdown options
   */
  options: DropdownCheckboxOption<T>[];

  /**
   * Called when the user toggles a checkbox;
   *  returns the *entire* new list of selected values
   */
  onSelect: (checkedItems: T[]) => void;

  /**
   * Called on each keystroke in the search field
   */
  onSearch?: (inputValue: string) => void;

  /**
   * Clears all selections
   */
  onClearAll: () => void;

  /**
   * Pre-selected option values
   */
  selectedOptions: T[];

  /**
   * Align the dropdown menu to the 'LEFT' or 'RIGHT'
   */
  align?: 'LEFT' | 'RIGHT';

  /**
   * Whether to display the search filter at the top
   */
  displayFilter?: boolean;

  /**
   * Label for the InputBase
   */
  label: string;

  /**
   * Optional help text below the input
   */
  assistiveMessage?: string;

  /**
   * Error text below the input (displays in red)
   */
  errorMessage?: string;

  /**
   * Whether the field is required
   */
  required?: boolean;

  /**
   * Disable the entire component
   */
  disabled?: boolean;

  /**
   * Additional dataTestId for E2E
   */
  dataTestId?: string;

  /**
   * Tailwind background style e.g. LIGHT / DARK
   */
  backgroundType?: InputBackgroundType;

  /**
   * Additional classes for top-level styling
   */
  className?: string;

  /**
   * Placeholder text in the button area (before selecting)
   */
  placeholder?: string;
};

const DropdownCheckbox = <T,>({
  options,
  onSelect,
  onSearch,
  onClearAll,
  selectedOptions,
  align = 'RIGHT',
  displayFilter = false,

  label,
  assistiveMessage,
  errorMessage,
  required = false,
  disabled = false,
  dataTestId,
  backgroundType,
  className,
  placeholder
}: DropdownCheckboxProps<T>) => {
  const [searchValue, setSearchValue] = useState('');

  // Derived text for the input field:
  const selectedCount = selectedOptions.length;
  const displayText = selectedCount
    ? `Selected (${selectedCount})`
    : placeholder || 'Select items';

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setSearchValue(val);
    if (onSearch) {
      onSearch(val);
    }
  };

  const handleOptionChange = (value: T) => {
    // Build a new array of selected items
    const isAlreadySelected = selectedOptions.includes(value);
    const newSelected = isAlreadySelected
      ? selectedOptions.filter((v) => v !== value)
      : [...selectedOptions, value];

    onSelect(newSelected);
  };

  return (
    <Menu as="div" className="relative w-full" data-test-id={dataTestId}>
      {({ open }) => (
        <>
          {/* The Clickable button to open the checkbox */}
          <Menu.Button
            disabled={disabled}
            className="w-full text-left"
            data-test-id={`${dataTestId}-button`}
          >
            <InputBase
              label={label}
              assistiveMessage={assistiveMessage}
              errorMessage={errorMessage}
              required={required}
              disabled={disabled}
              backgroundType={backgroundType}
              className={className}
              isBeingUpdated={open}
              inputComponent={
                <Fragment>
                  <span className="flex w-full items-center px-spacing-02 py-[6px] text-tertiary">
                    <p className="truncate body-01">{displayText}</p>
                    <ChevronDown className="ml-auto shrink-0 text-gray-400" />
                  </span>
                </Fragment>
              }
            />
          </Menu.Button>

          {/* The dropdown menu */}
          <Menu.Items
            className={classNames(
              'absolute z-dropdown bg-white border border-gray-300 rounded-md shadow-lg w-[20rem] -mt-2',
              {
                'right-0': align === 'RIGHT',
                'left-0': align === 'LEFT'
              }
            )}
            // Stop click inside from closing
            onClick={(e) => {
              // Prevent click inside from closing
              e.stopPropagation();
            }}
          >
            <div className="flex flex-col max-h-[320px] overflow-y-auto p-2">
              {/* Optional search filter */}
              {displayFilter && (
                <input
                  type="search"
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  className="w-full px-spacing-03 py-spacing-02 mb-2 border border-gray-300 rounded-md focus:outline-none"
                />
              )}

              <div className="flex-grow overflow-y-auto">
                {/* Render each checkbox item */}
                {options.map((opt) => {
                  const isSelected = selectedOptions.includes(opt.value);
                  const isDeprecated = opt.deprecated;

                  return (
                    <Menu.Item key={opt.key} as="div">
                      {() => (
                        <label
                          onClick={(e) => {
                            e.preventDefault(); // <-- This keeps the menu open
                            handleOptionChange(opt.value);
                          }}
                          className={classNames(
                            'flex items-center px-3 py-2 mb-1 text-sm rounded cursor-pointer hover:bg-gray-100',
                            {
                              'text-gray-400': isDeprecated,
                              'text-gray-700': !isDeprecated
                            }
                          )}
                          data-test-id={`${dataTestId}-element-${opt.key}`}
                        >
                          <input
                            type="checkbox"
                            className="mr-2"
                            checked={isSelected}
                            onChange={() => {
                              handleOptionChange(opt.value);
                            }}
                          />
                          {opt.label}
                        </label>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>

              {/* Clear all button */}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onClearAll();
                  setSearchValue('');
                }}
                className="mt-2 block w-full px-3 py-2 text-sm text-blue-500 hover:bg-gray-100 text-left rounded"
                data-test-id={`${dataTestId}-clear-all`}
              >
                Clear all
              </button>
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default DropdownCheckbox;
