import { captureException } from "@sentry/react";
import { UserConfirmationModal } from "@thedealersconcierge/components";
import classNames from "classnames";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { removeCoBuyerAction } from "~/actions/removeCoBuyerAction";
import Button from "~/components/Button";
import { queryClient } from "~/lib/query";
import { useNavigate } from "~/router";
import { CustomerType } from "../_queries/transactionQuery";

const TransactionCoBuyerCard: FC<{
  transactionId: string;
  coBuyerName: string;
  coBuyer?: CustomerType;
}> = ({ transactionId, coBuyerName, coBuyer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [
    isRemoveCoBuyerConfirmationModalOpen,
    setIsRemoveCoBuyerConfirmationModalOpen,
  ] = useState(false);
  const [isRemovingCoBuyer, setIsRemovingCoBuyer] = useState(false);

  const handleGotoCoBuyer = () => {
    if (!coBuyer) return;
    navigate("/dashboard/transaction/:transactionId/co-buyer", {
      params: {
        transactionId,
      },
    });
  };

  const removeCoBuyer = async () => {
    try {
      setIsRemovingCoBuyer(true);

      await removeCoBuyerAction(transactionId);
      await queryClient.resetQueries({
        queryKey: ["transaction", transactionId],
      });
    } catch (error) {
      console.error(error);
      captureException(error);

      if (error instanceof Error && error.message) {
        toast.error(error.message);
      } else {
        toast.error("Failed to remove co-buyer. Please contact support.");
      }
    } finally {
      setIsRemovingCoBuyer(false);
    }
  };

  return (
    <Fragment>
      <UserConfirmationModal
        isOpen={isRemoveCoBuyerConfirmationModalOpen}
        message="You are about to remove the co-buyer from this transaction. This action is permanent and cannot be undone. Are you sure you want to proceed?"
        onClose={() => {
          setIsRemoveCoBuyerConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsRemoveCoBuyerConfirmationModalOpen(false);
          void removeCoBuyer();
        }}
      />

      <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 h-full w-full">
        <div className="flex flex-row space-x-2 items-center">
          <h2 className="text-heading-1">Co-Buyer</h2>
        </div>

        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-3 lg:grid-cols-4 gap-8">
            <div className="text-body text-dark-gray lg:col-span-2">
              Co-Applicant
            </div>
            <div>
              {!coBuyer?.userId && (
                <Button
                  variant="TEXT_ONLY"
                  onClick={() => {
                    navigate(
                      "/dashboard/transaction/:transactionId/co-buyer/add",
                      { params: { transactionId } }
                    );
                  }}
                >
                  {t("+ Add ")}
                </Button>
              )}

              {!!coBuyer?.userId && (
                <Button
                  variant="TEXT_ONLY"
                  loading={isRemovingCoBuyer}
                  onClick={() => {
                    setIsRemoveCoBuyerConfirmationModalOpen(true);
                  }}
                >
                  <span className="text-error">Remove</span>
                </Button>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 lg:grid-cols-4 gap-8">
            <div className="text-body text-dark-gray lg:col-span-2">
              Co-Buyer
            </div>
            <div
              className={classNames({
                "text-blue-400 cursor-pointer": coBuyerName.length,
              })}
              onClick={handleGotoCoBuyer}
            >
              <p>{coBuyerName.length ? coBuyerName : "-"}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TransactionCoBuyerCard;
