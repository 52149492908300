import { Menu } from '@headlessui/react';
import { CalendarToday } from '@mui/icons-material';
import classNames from 'classnames';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from 'date-fns';
import { FC, Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputBase from '..';
import Button from '../../button';
import { InputBackgroundType } from '../inputConfig';

type DateRangeType = 'TODAY' | 'YESTERDAY' | 'THIS_WEEK' | 'THIS_MONTH';
const dateRanges: DateRangeType[] = [
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'THIS_MONTH'
];

const getReadableDateRangeLabel = (rangeType: DateRangeType) => {
  const dateRangeLabels: Record<DateRangeType, string> = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month'
  };

  return dateRangeLabels[rangeType];
};

const RangeTypeButton = ({
  label,
  isActive,
  onClick
}: {
  label: string;
  isActive?: boolean;
  onClick: () => void;
}) => (
  <button
    onClick={onClick}
    className={classNames(
      'w-full text-left px-4 py-2 text-sm font-medium rounded-md min-w-28',
      {
        'bg-gray-200 text-black': isActive,
        'hover:bg-gray-100 text-gray-700': !isActive
      }
    )}
  >
    {label}
  </button>
);

const DateRangePicker: FC<{
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  onChange: (selectedStartDate?: Date, selectedEndDate?: Date) => void;

  minDate?: Date;
  maxDate?: Date;
  initiallySelectedRangeType?: DateRangeType;
  useDateRange?: boolean;
  useVerboseDateRange?: boolean;
  placeholder?: string;

  label: string;
  assistiveMessage?: string;
  errorMessage?: string;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  selectedStartDate,
  selectedEndDate,
  onChange,
  minDate,
  maxDate,
  disabled,
  initiallySelectedRangeType,
  useDateRange = true,
  useVerboseDateRange = true,

  placeholder,
  label,
  assistiveMessage,
  errorMessage,
  required,
  className,
  backgroundType,
  onBlur
}) => {
  const [activeRange, setActiveRange] = useState<DateRangeType | undefined>();

  const today = new Date();

  // Used for min date
  const startOfThisMonth = startOfMonth(today); // Start of the current month
  const endOfLastMonth = endOfMonth(subMonths(today, 1)); // End of the previous month

  useEffect(() => {
    if (initiallySelectedRangeType) {
      handleDateRangeSelection(initiallySelectedRangeType);
    }
  }, []);

  const handleDateRangeSelection = (range: DateRangeType) => {
    setActiveRange(range);

    const now = new Date();

    if (range === 'TODAY') {
      const start = startOfDay(now);
      const end = start;
      onChange(start, end);
    }

    if (range === 'YESTERDAY') {
      const start = startOfDay(subDays(now, 1));
      const end = start;
      onChange(start, end);
    }

    if (range === 'THIS_WEEK') {
      const start = startOfWeek(now, { weekStartsOn: 0 });
      const end = endOfWeek(now, { weekStartsOn: 0 });
      onChange(start, end);
    }

    if (range === 'THIS_MONTH') {
      const start = startOfMonth(now);
      const end = endOfMonth(now);
      onChange(start, end);
    }
  };

  // We accept null here to satisfy react date-picker on change
  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setActiveRange(undefined);
    onChange(start ?? undefined, end ?? undefined);
  };

  const getFormattedDateRange = (
    placeHolder?: string,
    activeRange?: DateRangeType,
    selectedStartDate?: Date,
    selectedEndDate?: Date
  ) => {
    const formatDate = (date?: Date, includeYear?: boolean) => {
      if (!date) return '';
      return includeYear
        ? format(date, 'MMMM d, yyyy')
        : format(date, 'MMMM d');
    };

    if (activeRange && selectedStartDate && selectedEndDate) {
      //TODO: Wrap the return String with translate, we don't do this right now since this component only used in dealer dash right now
      switch (activeRange) {
        case 'TODAY':
          if (!useVerboseDateRange) return `Today`;
          return `Today (${formatDate(selectedStartDate, false)})`;
        case 'YESTERDAY':
          if (!useVerboseDateRange) return `Yesterday`;
          return `Yesterday (${formatDate(selectedStartDate, false)})`;
        case 'THIS_WEEK':
          if (!useVerboseDateRange) return `This Week`;
          return `This Week (${formatDate(selectedStartDate, false)} - ${formatDate(
            selectedEndDate,
            false
          )})`;
        case 'THIS_MONTH':
          if (!useVerboseDateRange) return `This Month`;
          return `This Month (${formatDate(selectedStartDate, false)} - ${formatDate(
            selectedEndDate,
            false
          )})`;
        default:
          return placeHolder ? placeHolder : 'Select a date range';
      }
    }

    if (selectedStartDate && selectedEndDate) {
      const includeYear =
        selectedStartDate.getFullYear() !== selectedEndDate.getFullYear();
      return `${formatDate(selectedStartDate, includeYear)} - ${formatDate(
        selectedEndDate,
        includeYear
      )}`;
    }

    return placeHolder ? placeHolder : 'Select a date range';
  };

  return (
    <Menu as="div" className={'relative'}>
      {({ close }) => (
        <Menu.Button
          disabled={disabled}
          className="relative outline-none w-full group"
          onBlur={onBlur}
        >
          {({ open }) => {
            return (
              <InputBase
                inputComponent={
                  <Fragment>
                    <span className="flex truncate text-start body-01 w-full px-spacing-02 py-[6px] outline-none bg-transparent space-x-2 text-tertiary pr-4 items-center">
                      <CalendarToday
                        style={{
                          width: 16
                        }}
                      />
                      <p>
                        {getFormattedDateRange(
                          placeholder,
                          activeRange,
                          selectedStartDate,
                          selectedEndDate
                        )}
                      </p>
                    </span>

                    <Menu.Items
                      className={
                        'absolute z-dropdown bg-white border border-gray-300 rounded-md shadow-lg outline-none left-0 top-full -mt-2'
                      }
                      onClick={(e) => {
                        // Prevent click inside from closing
                        e.stopPropagation();
                      }}
                    >
                      <div className={'flex flex-row'}>
                        {useDateRange && (
                          <div className="flex flex-col border-r-2 px-2 py-4">
                            {dateRanges.map((range) => (
                              <RangeTypeButton
                                key={range}
                                label={getReadableDateRangeLabel(range)}
                                isActive={activeRange === range}
                                onClick={() => {
                                  handleDateRangeSelection(range);
                                }}
                              />
                            ))}
                          </div>
                        )}

                        <div className="flex flex-row gap-4 py-4 px-4">
                          <DatePicker
                            selected={selectedStartDate}
                            onChange={handleDateChange}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            minDate={minDate}
                            maxDate={endOfLastMonth}
                            openToDate={endOfLastMonth}
                            inline
                            disabled={disabled}
                            selectsRange
                            disabledKeyboardNavigation
                          />
                          <DatePicker
                            selected={selectedEndDate}
                            onChange={handleDateChange}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            minDate={startOfThisMonth}
                            maxDate={maxDate}
                            openToDate={startOfThisMonth}
                            inline
                            disabled={disabled}
                            selectsRange
                            disabledKeyboardNavigation
                          />
                        </div>
                      </div>
                      <div className="flex justify-end gap-4 border-t-2 p-4">
                        <Button
                          onClick={() => {
                            onChange(undefined, undefined);
                          }}
                          disabled={disabled}
                          label="Clear"
                          size="MEDIUM"
                          variant="GHOST"
                        >
                          Clear
                        </Button>
                        <Button
                          variant="PRIMARY"
                          label="Apply"
                          size="MEDIUM"
                          disabled={disabled}
                          onClick={close}
                        />
                      </div>
                    </Menu.Items>
                  </Fragment>
                }
                label={label}
                assistiveMessage={assistiveMessage}
                errorMessage={errorMessage}
                required={required}
                disabled={disabled}
                backgroundType={backgroundType}
                isBeingUpdated={open}
                className={className}
              />
            );
          }}
        </Menu.Button>
      )}
    </Menu>
  );
};

export default DateRangePicker;
