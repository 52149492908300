import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form-old";
import { UserConfirmationModal } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import { useAtom } from "jotai";
import { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import { GraphQLTypes, Role } from "~/__generated__/backend/zeus";
import updateTransactionAction from "~/actions/transactions/updateTransactionAction";
import EditValueButton from "~/components/EditValueButton";
import FormattedNumber from "~/components/FormattedNumber";
import Modal from "~/components/Modal";
import StaffField from "~/components/StaffButton/StaffField";
import Button from "~/components/design-system-components/Button";
import { useConfirmationModal } from "~/components/design-system-components/ConfirmationModal";
import Dropdown from "~/components/design-system-components/Dropdown";
import {
  MultiStateCheckbox,
  MultiStateCheckboxState,
} from "~/components/design-system-components/MultiStateCheckbox";
import TextInput from "~/components/inputs/TextInput";
import financeTypeOptions from "~/config/formSelectionOptions/financeTypeOptions";
import { getDateFromUnknown } from "~/lib/helpers";
import { queryClient } from "~/lib/query";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import {
  refetchTransactionQuery,
  TransactionQueryType,
} from "../_queries/transactionQuery";
import DisplayOnHoverWrapper from "./DisplayOnHoverWrapper";

const TransactionDealCard: FC<{
  transaction: TransactionQueryType["transaction"];
}> = ({ transaction }) => {
  const navigate = useNavigate();
  const modals = useModals();
  const [
    isDealScenarioConfirmationModalOpen,
    setIsDealScenarioConfirmationModalOpen,
  ] = useState(false);
  const { showModal, ConfirmationModalComponent } = useConfirmationModal();
  const [dealership] = useAtom(dealershipAtom);
  const [showDealNumberModal, setShowDealNumberModal] = useState(false);
  const [isUpdatingDealNumber, setIsUpdatingDealNumber] = useState(false);

  const customer = transaction?.buyer;
  const coBuyer = transaction?.coBuyer;
  const transactionId = transaction?.id ?? "no-transaction-id";

  const stockNumber =
    transaction?.vehicle?.stockNumber ?? transaction?.tradeVehicle?.stockNumber;
  const cdkDmsDealId = transaction?.cdkDmsDealId;

  const salesPerson = transaction?.salesPerson;
  const fniManager = transaction?.fniManager;
  const salesManager = transaction?.salesManager;
  const bdc = transaction?.bdc;

  const financeType = transaction?.financeType
    ? FinanceType.parse(transaction.financeType)
    : undefined;

  const isLeaseBuyOut = transaction?.isLeaseBuyOut;
  const requestedPrePurchaseFormsAt = getDateFromUnknown(
    transaction?.latestPrePurchaseCollection?.edges?.[0]?.node?.createdAt
  );
  const requestedPostPurchaseFormsAt = getDateFromUnknown(
    transaction?.latestPostPurchaseCollection?.edges?.[0]?.node?.createdAt
  );

  const handleDidAssign = async () => {
    // We refetch all active queries - this is akin to reloading and makes sense
    // as other parts of the application is dependent on this data
    await queryClient.refetchQueries();
    toast.success("Staff updated", {
      toastId: "staff-update-toast",
    });
  };

  const handleOpenDealScenarios = () => {
    if (!customer) return;
    modals.open("/dashboard/transaction/[transactionId]/dealScenarios", {
      params: { transactionId, userId: customer.userId ?? undefined },
    });
  };

  const handleGoToDealJacket = async () => {
    await navigate("/dashboard/transaction/:transactionId/deal-jacket", {
      params: { transactionId },
    });
  };
  const handleOpenDealNumberEditModal = () => {
    setShowDealNumberModal(true);
  };
  const handleCloseDealNumberEditModal = () => {
    setShowDealNumberModal(false);
  };
  const cdkDmsDealIdForm = useForm({
    defaultValues: {
      cdkDmsDealId: cdkDmsDealId ?? "",
    },
    onSubmit: async (values) => {
      setIsUpdatingDealNumber(true);

      await updateTransactionAction({ ...values, id: transactionId });
      await refetchTransactionQuery(transactionId);

      setIsUpdatingDealNumber(false);
      handleCloseDealNumberEditModal();
    },
  });

  const handleUpdateFinanceType = async (newFinanceType: string) => {
    try {
      const financeType = FinanceType.parse(newFinanceType);
      if (!financeType) {
        toast.error("Invalid finance type");
        return;
      }

      /**
       * The current editing behavior of the deal section is not optimal and we will revisit
       * the UX of this, which will likely switch to our standard form editing behavior.
       *
       * In that strucuture, it is easier to understand when using the `UserConfirmationModal` from the
       * shared component library.
       *
       * For now, we leave the old approach and show the warning message this way.
       */
      const confirmed =
        /**
         * We need the user to confirm they really want to change the finance type
         * if they already requested compliance forms
         */
        Boolean(requestedPrePurchaseFormsAt) ||
        Boolean(requestedPostPurchaseFormsAt)
          ? await showModal({
              content: (
                <span>
                  You are about to change the Type of Financing, even though the
                  compliance forms have already been executed by the customer.
                  <br />
                  <br />
                  Please note:
                  <br />
                  <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
                    <li>
                      Changing the financing type will require{" "}
                      <b>cancelling the previously executed forms</b>.
                    </li>

                    <li>
                      These forms will be removed from the deal jacket for this
                      transaction.
                    </li>

                    <li>
                      The customer will be required to sign a{" "}
                      <b>new set of forms</b>.
                    </li>
                  </ul>
                  <br />
                  Are you sure you want to proceed with the change?
                </span>
              ),
              title: "Warning",
            })
          : true;

      if (confirmed) {
        await updateTransactionAction({
          id: transactionId,
          financeType: financeType,
        });
        await refetchTransactionQuery(transactionId);
        toast.success("Successfully updated finance type");
      }
    } catch (e) {
      captureException(`Failed to update finance type: ${e}`);
      toast.error("Failed to update finance type, contact admin");
    }
  };

  const handleUpdateLBO = async (checkState: MultiStateCheckboxState) => {
    try {
      const newLBO =
        checkState === MultiStateCheckboxState.SELECTED ? true : false;

      const confirmed = await showModal({
        content: "Are you sure you want to change LBO?",
        title: "Change LBO",
      });

      if (confirmed) {
        await updateTransactionAction({
          id: transactionId,
          isLeaseBuyOut: newLBO,
        });
        await refetchTransactionQuery(transactionId);
        toast.success("Successfully updated LBO");
      }
    } catch (e) {
      captureException(`Failed to update LBO:  ${e}`);
      toast.error("Failed to update LBO, contact admin");
    }
  };

  const handleUpdate = async (
    updatePayload: GraphQLTypes["UpdateTransactionInput"]
  ): Promise<void> => {
    try {
      await updateTransactionAction(updatePayload);
      await refetchTransactionQuery(transactionId);
    } catch (e) {
      toast.error("An error occurred");
      captureException(`Failed to update transaction: ${e}`);
    }
  };

  const canViewDealJacket = permissionChecker(
    "viewDealJacket",
    dealership?.activeDealershipPerms
  );

  return (
    <Fragment>
      {/**
       * Confirmation modal when changing deal scenarios
       */}
      <UserConfirmationModal
        message={
          <span>
            You are about to change the consumer roles, even though the
            compliance forms have already been executed by the customers.
            <br />
            <br />
            Please note:
            <br />
            <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
              <li>
                Changing the deal scenario{" "}
                <b>will require cancelling the previously executed forms</b>.
              </li>

              <li>
                These forms will have to be removed from the deal jacket for
                this transaction.
              </li>

              <li>
                The customer will be required to sign <b>a new set of forms</b>.
              </li>
            </ul>
            <br />
            Are you sure you want to proceed with the change?
          </span>
        }
        isOpen={isDealScenarioConfirmationModalOpen}
        onClose={() => {
          setIsDealScenarioConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsDealScenarioConfirmationModalOpen(false);
          handleOpenDealScenarios();
        }}
      />

      {/* TODO: Refactor this into using the same button as the rest of the editable fields */}
      <Modal
        title="Edit Deal Number"
        isOpen={showDealNumberModal}
        onClose={handleCloseDealNumberEditModal}
        className="flex flex-col p-6 space-y-6"
      >
        <cdkDmsDealIdForm.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void cdkDmsDealIdForm.handleSubmit();
            }}
          >
            <cdkDmsDealIdForm.Field name="cdkDmsDealId">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText="Deal number"
                    placeholder="Deal number"
                    subtitleText="Deal number"
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </cdkDmsDealIdForm.Field>
          </form>

          <div className="flex flex-row space-x-4 justify-end">
            <Button
              variant="GHOST"
              disabled={isUpdatingDealNumber}
              onClick={handleCloseDealNumberEditModal}
            >
              Cancel
            </Button>

            <Button
              onClick={cdkDmsDealIdForm.handleSubmit}
              loading={isUpdatingDealNumber}
            >
              Save
            </Button>
          </div>
        </cdkDmsDealIdForm.Provider>
      </Modal>

      <div className="rounded-2xl bg-white shadow-md p-8 flex flex-col justify-between mb-4 gap-4">
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row items-center">
            <h2 className="text-heading-1">Deal</h2>

            {coBuyer?.userId && (
              <div className="ml-2 pl-2  border-l-2">
                <Button
                  variant="LINK"
                  onClick={() => {
                    // If some compliance forms have been signed already, we show the confirmation modal
                    if (
                      requestedPrePurchaseFormsAt ||
                      requestedPostPurchaseFormsAt
                    ) {
                      setIsDealScenarioConfirmationModalOpen(true);
                    } else {
                      // No forms have been requested yet, so changing the roles does not require extra attention.
                      handleOpenDealScenarios();
                    }
                  }}
                >
                  <p className="text-heading-2 h-full">Deal Scenarios</p>
                </Button>
              </div>
            )}
          </div>

          {canViewDealJacket && (
            <Button
              size="SMALL"
              onClick={handleGoToDealJacket}
              variant="SECONDARY"
            >
              Deal Jacket
            </Button>
          )}
        </div>

        <div className="w-full grid grid-cols-8 mb-4 border-b-2 pb-8">
          <div className="col-span-5 grid grid-cols-4 gap-16">
            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">Finance Type</p>
              <div className="text-body">
                <Dropdown
                  onSelect={(e) => {
                    void handleUpdateFinanceType(e);
                  }}
                  options={financeTypeOptions}
                  placeholder="Finance Type"
                  size="XS"
                  value={financeType}
                />
              </div>
            </div>

            {transaction?.dealership?.hasEnabledCdkDms && (
              <div className="flex flex-col gap-4">
                <p className="text-body text-dark-gray">Deal Number</p>

                <div className="flex flex-row h-fit items-center justify-between">
                  <p className="text-body">
                    {cdkDmsDealId?.length ? cdkDmsDealId : "-"}
                  </p>

                  <Button
                    variant="LINK"
                    onClick={handleOpenDealNumberEditModal}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            )}

            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">Stock Number</p>
              <p className="text-body">{stockNumber ?? "-"}</p>
            </div>

            <div className="flex flex-col gap-4">
              <p className="text-body text-dark-gray">LBO</p>
              <div className="text-body">
                <MultiStateCheckbox
                  mode="SIMPLE"
                  value={
                    isLeaseBuyOut
                      ? MultiStateCheckboxState.SELECTED
                      : MultiStateCheckboxState.UNSELECTED
                  }
                  onChange={handleUpdateLBO}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-8 gap-12 lg:gap-40">
          <div className="col-span-5">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-start">
              {/* ALL DEALS GROUP */}
              <div className="flex flex-col gap-4 ">
                <p className="font-semibold text-sm">All Deals</p>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Consumer Rebates</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={
                        (transaction?.consumerRebatesInUsdCents ?? 0) / 100
                      }
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? parseFloat(newValue) * 100
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            consumerRebatesInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={
                          (transaction?.consumerRebatesInUsdCents ?? 0) / 100
                        }
                        title="Edit Consumer Rebates"
                        suffix="$"
                        min={0}
                        subtitle="Consumer Rebates"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Down Payment</p>
                  <div className="flex flex-row gap-2 items-center w-full justify-between group w-full">
                    <FormattedNumber
                      value={(transaction?.downPaymentInUsdCents ?? 0) / 100}
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? parseFloat(newValue) * 100
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            downPaymentInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={(transaction?.downPaymentInUsdCents ?? 0) / 100}
                        title="Edit Down Payment"
                        suffix="$"
                        min={0}
                        subtitle="Down Payment"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">Sales Tax</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={
                        transaction?.salesTaxInMiliBsp
                          ? transaction?.salesTaxInMiliBsp / 100_000
                          : 0
                      }
                      decimalScale={3}
                      fixedDecimalScale={false}
                      suffix="%"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInMiliBasisPoints = !isNaN(
                            parseFloat(newValue)
                          )
                            ? parseFloat(newValue) * 100_000
                            : null;
                          if (!valueInMiliBasisPoints) return;
                          await handleUpdate({
                            salesTaxInMiliBsp: valueInMiliBasisPoints,
                            id: transactionId,
                          });
                        }}
                        min={0}
                        max={20}
                        type="number"
                        value={
                          transaction?.salesTaxInMiliBsp
                            ? transaction?.salesTaxInMiliBsp / 100_000
                            : 0
                        }
                        title="Edit Sales Tax"
                        subtitle="Sales Tax"
                        suffix="%"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <div className="flex flex-col">
                    <p className="text-body text-dark-gray">
                      Vehicle Trade Dollar
                    </p>
                    <p className="text-body text-dark-gray">(Trade-In Price)</p>
                  </div>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <FormattedNumber
                      value={(transaction?.tradeInPriceInUsdCents ?? 0) / 100}
                      suffix="$"
                    />
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          const valueInCents = !isNaN(parseFloat(newValue))
                            ? Math.round(parseFloat(newValue) * 100)
                            : null;
                          if (!valueInCents) return;
                          await handleUpdate({
                            tradeInPriceInUsdCents: valueInCents,
                            id: transactionId,
                          });
                        }}
                        type="number"
                        value={(transaction?.tradeInPriceInUsdCents ?? 0) / 100}
                        title="Edit Vehicle Trade Dollar (Trade-In Price)"
                        suffix="$"
                        min={0}
                        subtitle="Vehicle Trade Dollar"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>

                <div className="grid grid-cols-2">
                  <p className="text-body text-dark-gray">We Owe</p>
                  <div className="flex flex-row gap-2 items-center justify-between group w-full">
                    <p className="text-body">
                      {transaction?.dealershipCommitment ?? "-"}
                    </p>
                    <DisplayOnHoverWrapper>
                      <EditValueButton
                        onSubmit={async (newValue) => {
                          await handleUpdate({
                            dealershipCommitment: newValue,
                            id: transactionId,
                          });
                        }}
                        type="text"
                        value={transaction?.dealershipCommitment ?? ""}
                        title="Edit We Owe"
                        subtitle="We Owe"
                      />
                    </DisplayOnHoverWrapper>
                  </div>
                </div>
              </div>

              {/* RETAIL & LEASE */}
              <div className="flex flex-col gap-4">
                {/* RETAIL GROUP */}
                <div className="flex flex-col gap-4">
                  <p className="font-semibold text-sm">Retail</p>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">
                      Vehicle Sale Price
                    </p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={(transaction?.salesPriceInUsdCents ?? 0) / 100}
                        suffix="$"
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            const valueInCents = !isNaN(parseFloat(newValue))
                              ? Math.round(parseFloat(newValue) * 100)
                              : null;
                            if (!valueInCents) return;
                            await handleUpdate({
                              salesPriceInUsdCents: valueInCents,
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={(transaction?.salesPriceInUsdCents ?? 0) / 100}
                          title="Edit Sale Price"
                          suffix="$"
                          min={0}
                          subtitle="Sale Price"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>
                </div>

                {/* LEASE GROUP */}
                <div className="flex flex-col gap-4">
                  <p className="font-semibold text-sm">Lease</p>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">Monthly Payment</p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={
                          (transaction?.monthlyLeasePaymentInUsdCents ?? 0) /
                          100
                        }
                        suffix="$"
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            const valueInCents = !isNaN(parseFloat(newValue))
                              ? parseFloat(newValue) * 100
                              : null;
                            if (!valueInCents) return;
                            await handleUpdate({
                              monthlyLeasePaymentInUsdCents: valueInCents,
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={
                            (transaction?.monthlyLeasePaymentInUsdCents ?? 0) /
                            100
                          }
                          title="Edit Monthly Payment"
                          suffix="$"
                          min={0}
                          subtitle="Monthly Payment"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">Miles per Year</p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <FormattedNumber
                        value={transaction?.milesPerYearInLease}
                        decimalScale={0}
                      />
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            await handleUpdate({
                              milesPerYearInLease: parseInt(newValue),
                              id: transactionId,
                            });
                          }}
                          type="number"
                          value={transaction?.milesPerYearInLease ?? undefined}
                          title="Edit Miles per Year"
                          min={0}
                          subtitle="Miles per Year"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>

                  <div className="grid grid-cols-2">
                    <p className="text-body text-dark-gray">
                      Lease Terms (Months)
                    </p>
                    <div className="flex flex-row gap-2 items-center justify-between group w-full">
                      <p className="text-body">
                        {transaction?.leaseTermInMonths ?? "-"}
                      </p>
                      <DisplayOnHoverWrapper>
                        <EditValueButton
                          onSubmit={async (newValue) => {
                            await handleUpdate({
                              leaseTermInMonths: parseInt(newValue),
                              id: transactionId,
                            });
                          }}
                          type="text"
                          value={transaction?.leaseTermInMonths ?? undefined}
                          title="Edit Lease Terms (Month)"
                          min={0}
                          subtitle="Lease Terms (Month)"
                        />
                      </DisplayOnHoverWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-3">
            <p className="font-semibold text-sm mb-4">Staff</p>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-4">
              <p className="text-body text-dark-gray">Sales Person</p>

              <StaffField
                transactionId={transactionId}
                role={Role.SALES_PERSON}
                onDidAssign={handleDidAssign}
                selectedUserId={salesPerson?.id ?? undefined}
                currentAssignee={
                  salesPerson
                    ? `${salesPerson.firstName} ${salesPerson.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">Sales Manager</p>
              <StaffField
                transactionId={transactionId}
                role={Role.SALES_MANAGER}
                onDidAssign={handleDidAssign}
                selectedUserId={salesManager?.id ?? undefined}
                currentAssignee={
                  salesManager
                    ? `${salesManager.firstName} ${salesManager.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">F&I Manager</p>

              <StaffField
                transactionId={transactionId}
                role={Role.FNI_MANAGER}
                onDidAssign={handleDidAssign}
                selectedUserId={fniManager?.id ?? undefined}
                currentAssignee={
                  fniManager
                    ? `${fniManager.firstName} ${fniManager.lastName}`
                    : undefined
                }
              />

              <p className="text-body text-dark-gray">BDC</p>

              <StaffField
                transactionId={transactionId}
                role={Role.BDC}
                onDidAssign={handleDidAssign}
                selectedUserId={bdc?.id ?? undefined}
                currentAssignee={
                  bdc ? `${bdc.firstName} ${bdc.lastName}` : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>
      {ConfirmationModalComponent}
    </Fragment>
  );
};

export default TransactionDealCard;
