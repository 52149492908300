import { gqlMutationClient } from "~/lib/backend";

export const removeCoBuyerAction = async (transactionId: string) => {
  const resp = await gqlMutationClient()({
    removeCoBuyer: [
      {
        transactionId,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationRemoveCoBuyerSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (!resp.removeCoBuyer || resp.removeCoBuyer.__typename === "GraphQLError") {
    throw new Error(resp.removeCoBuyer?.message ?? "Unexpected error");
  }
};
