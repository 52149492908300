import {
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector,
} from "~/__generated__/backend/zeus";

export const vehicleSelector = Selector("Vehicle")({
  id: true,
  vin: true,
  model: true,
  make: true,
  year: true,
  registrationCard: {
    id: true,
    url: true,
  },
  registrationName: true,
  registrationState: true,
  color: true,
  condition: true,
  bodyType: true,
  mileage: true,
  lifeCycleStage: true,
  trim: true,
  stockNumber: true,
  principalPriorUse: true,
  pictureFront: {
    url: true,
  },
  pictureBack: {
    url: true,
  },
  pictureLeftSide: {
    url: true,
  },
  pictureRightSide: {
    url: true,
  },
  pictureInterior: {
    url: true,
  },
  pictureOdometer: {
    url: true,
  },
  pictureVinNumber: {
    url: true,
  },
  msrp: true,
  __alias: {
    latestRecallMastersReport: {
      recallMastersRecallReports: [
        {
          first: 1,
          orderBy: {
            createdAt: OrderBy.Desc,
          },
        },
        {
          edges: {
            node: {
              id: true,
            },
          },
        },
      ],
    },
  },
});

export type VehicleType = InputType<
  GraphQLTypes["Vehicle"],
  typeof vehicleSelector
>;
