import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@thedealersconcierge/components";
import { useAtomValue } from "jotai";
import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import dealershipQuery from "~/query/dealershipQuery";
import meQuery from "~/query/meQuery";
import { useNavigate } from "~/router";
import { authStateAtom, dealershipAtom } from "~/state";
import ChatButton from "./_components/Chat/Button";

const NavigationHooks = () => {
  const [delayed, setDelayed] = useState(false);
  const authState = useAtomValue(authStateAtom);
  const navigate = useNavigate();

  // Automatically redirect if there is not user data
  const { data, isLoading } = useQuery(meQuery());

  // This is a major hack. The problem is, that the atoms are not loading
  // properly as they are not async. Feel free to remove this, when that is
  // fixed.
  useEffect(() => {
    setTimeout(() => {
      setDelayed(true);
    }, 500);
  }, []);

  // Navigation effect on auth state
  useEffect(() => {
    if (!delayed) {
      return;
    }
    if (!authState) {
      navigate("/");
    }

    // We are not logged in, redirect to frontpage
    if (!isLoading && !data?.me) {
      navigate("/");
    }
  }, [authState, delayed, data, isLoading, navigate]);

  return null;
};

export default function Layout() {
  const activeDealership = useAtomValue(dealershipAtom);
  const { data: dealerData } = useQuery(
    dealershipQuery(activeDealership?.activeDealershipPerms.dealershipId)
  );
  return (
    <>
      <NavigationHooks />
      <Suspense
        fallback={
          // Fallback loading spinner
          <div className="flex w-full h-screen justify-center items-center">
            <Spinner size="LARGE" color="GREY" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      {dealerData?.dealership?.hasEnabledChatBot && <ChatButton />}
    </>
  );
}
