import { z } from "zod";

const UserMessagePersistedSchema = z.object({
  type: z.literal("USER_MSG"),
  message: z.string(),
});

const AiMessagePersistedSchema = z.object({
  type: z.literal("AI_MSG"),
  message: z.string(),
});

const SupportMessagePersistedSchema = z.object({
  type: z.literal("SUPPORT_MSG"),
  message: z.string(),
});

/**
 * It tells a bit about the app and rudimentary information
 */
const InitialContextPersistedSchema = z.object({
  type: z.literal("INITIAL_CTX"),
  message: z.string(),
});

/**
 * The user navigated to a new page
 */
const NavigationChangePersistedSchema = z.object({
  type: z.literal("NAVIGATION_CHANGE"),
  fromRoute: z.string(),
  toRoute: z.string(),
});

export const ChatMessagePersistedSchema = z.union([
  UserMessagePersistedSchema,
  AiMessagePersistedSchema,
  SupportMessagePersistedSchema,
  InitialContextPersistedSchema,
  NavigationChangePersistedSchema,
]);
export type ChatMessagePersistedSchema = z.TypeOf<
  typeof ChatMessagePersistedSchema
>;
