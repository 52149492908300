import { z } from "zod";

/**
 * This schema is used to parse the Recall Masters API response.
 *
 * WARNING: This schema is prone to changes by Recall Masters and we need to ensure we are able to parse old responses.
 *
 * The the fields in the API response are either of type string, number, or boolean.
 * Whether a field is nullable seems to be indicated by "if applicable" or "if known" in the documentation (e.g., expiration date).
 * In most cases, when there is no value, the API seems to return an empty string.
 *
 * The API documentation is uploaded on Notion: https://www.notion.so/Recall-Masters-1b2f6cfdda038078b9c9da2db5481b06
 */
export const RMRecallResValidationSchema = z.object({
  api_request_id: z.string(), // Contrary to the documentation this is not an Int anymore that is incremented but an alphanumeric string
  vin: z.string(), // Keeping this intentionally as string and not vin to avoid unnecessary Zod errors
  status: z.string(),
  make: z.string(),
  model_name: z.string(),
  model_year: z.number(),
  use_type: z.string(),
  user: z.string(),
  user_description: z.string(),
  error_description: z.string(),
  recalls: z
    .object({
      recall_id: z.number(),
      nhtsa_id: z.string(),
      oem_id: z.string(),
      effective_date: z.string(),
      is_remedy_available: z.boolean(),
      parts_available_date: z.string().nullable(), // It is annotated as "if known" in the documentation
      labor_min: z.string(),
      labor_max: z.string(),
      profit_rank: z.number(),
      labor_difficulty: z.number(),
      remedy: z.string(),
      risk_type: z.string(),
      risk: z.string(),
      name: z.string(),
      are_parts_available: z.boolean(),
      risk_rank: z.number(),
      overall_rank: z.number(),
      expiration_date: z.string().nullable(), // It is annotated as "if applicable" in the documentation and test requests did contain null values for this field
      description: z.string(),
      stop_sale: z.boolean(),
      dont_drive: z.boolean(),
      reimbursement: z.number(),
      campaign_type: z.string(),
      government_id: z.string(),
      recall_last_updated: z.string(),
      is_preinspection_required: z.boolean(),
      is_forbidden: z.boolean(),
      forbidden_reason: z.string(),
      recall_age: z.number(),
    })
    .array(),
  recall_count: z.number(),
  last_updated: z.string(),
});

export type RMRecallResValidationSchema = z.TypeOf<
  typeof RMRecallResValidationSchema
>;
