import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Tooltip,
  UserConfirmationModal,
} from "@thedealersconcierge/components";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { VehicleLifeCycleStage } from "~/__generated__/backend/zeus";
import SectionDataRow from "~/components/SectionDataRow";
import VehicleIcon from "~/components/icons/VehicleIcon";
import {
  getReadableBodyType,
  getReadablePrincipalPriorUse,
} from "~/lib/enumReadable";
import { useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../_queries/transactionQuery";

const TransactionPurchaseVehicle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [dealership] = useAtom(dealershipAtom);
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const { data } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const transaction = data?.transaction;
  const purchaseVehicle = transaction?.vehicle;
  const recallReportId =
    transaction?.vehicle?.latestRecallMastersReport?.edges?.[0]?.node?.id;
  const hasRecallReport = Boolean(recallReportId);

  const handleEditPurchaseVehicle = async () => {
    await navigate(
      {
        pathname: "/dashboard/transaction/:transactionId/vehicle/purchase",
      },
      { params: { transactionId } }
    );
  };
  const handleViewRecallReport = () => {
    /**
     * This check is for typing as the button is disabled if there is no report.
     */
    if (!recallReportId) {
      throw new Error("Recall report id not available");
    }

    navigate(
      {
        pathname:
          "/dashboard/transaction/:transactionId/vehicle/purchase/recall-masters/:recallReportId",
      },
      { params: { transactionId, recallReportId } }
    );
  };

  return (
    <Fragment>
      <UserConfirmationModal
        isOpen={isConfirmationModalOpen}
        message={
          <span>
            You are about to change the vehicle for purchase, even though the
            compliance forms have already been executed by the customer.
            <br />
            <br />
            Please note:
            <br />
            <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
              <li>
                Changing the vehicle will require{" "}
                <b>cancelling the previously executed forms</b>.
              </li>

              <li>
                These forms will be removed from the deal jacket for this
                transaction.
              </li>

              <li>
                The customer will be required to sign a <b>new set of forms</b>.
              </li>
            </ul>
            <br />
            Are you sure you want to proceed with the change?
          </span>
        }
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmationModalOpen(false);
          handleEditPurchaseVehicle();
        }}
      />

      <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-spacing-04 justify-between w-full">
            <div className="flex flex-row space-x-2 items-center">
              <div className="relative">
                <VehicleIcon className="w-5 aspect-square text-dark-gray" />
              </div>

              <h2 className="text-heading-2 text-very-dark-gray">
                {t("Vehicle for Purchase")}
              </h2>
            </div>

            <div className="flex flex-row space-x-spacing-04">
              {purchaseVehicle &&
                // We check that the dealership has Recall Masters enabled
                transaction.dealership?.hasEnabledRecallMasters &&
                // Only if it's a used vehicle, we expect recalls
                purchaseVehicle.lifeCycleStage ===
                  VehicleLifeCycleStage.IS_USED && (
                  <Tooltip
                    hide={hasRecallReport}
                    content={
                      <p className="body-02">Recall report not available</p>
                    }
                    anchor={
                      <Button
                        label="Recalls"
                        variant="SECONDARY"
                        size="SMALL"
                        disabled={!hasRecallReport}
                        onClick={handleViewRecallReport}
                      />
                    }
                  />
                )}

              <Button
                variant="GHOST"
                size="SMALL"
                label={purchaseVehicle ? t("Edit") : t("Add")}
                onClick={() => {
                  /**
                   * If the compliance forms have been previously requested,
                   * we want the user to confirm they understand the consequences.
                   */
                  if (
                    transaction?.latestPrePurchaseCollection?.edges?.[0]?.node
                      ?.createdAt ||
                    transaction?.latestPostPurchaseCollection?.edges?.[0]?.node
                      ?.createdAt
                  ) {
                    setIsConfirmationModalOpen(true);
                  } else {
                    handleEditPurchaseVehicle();
                  }
                }}
              />

              {/**
               * TODO: Hook up CARFAX
               */}
              {/* <Button variant="SECONDARY">CARFAX</Button> */}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col gap-2">
            <SectionDataRow
              label="Type"
              value={(() => {
                if (!purchaseVehicle) return "-";
                if (!purchaseVehicle?.lifeCycleStage) return "-";

                if (
                  purchaseVehicle.lifeCycleStage ===
                  VehicleLifeCycleStage.IS_USED
                ) {
                  return "Used";
                }
                return "New";
              })()}
            />

            <SectionDataRow
              label="Principal Prior Use"
              value={
                purchaseVehicle?.principalPriorUse
                  ? getReadablePrincipalPriorUse(
                      purchaseVehicle.principalPriorUse
                    )
                  : "-"
              }
            />

            <SectionDataRow
              label="Stock No."
              value={purchaseVehicle?.stockNumber ?? "-"}
            />

            <SectionDataRow label="VIN#" value={purchaseVehicle?.vin ?? "-"} />

            <SectionDataRow
              label="Body Type"
              value={
                purchaseVehicle?.bodyType
                  ? getReadableBodyType(purchaseVehicle.bodyType)
                  : "-"
              }
            />
          </div>

          <div className="flex flex-col gap-2">
            <SectionDataRow label="Make" value={purchaseVehicle?.make ?? "-"} />

            <SectionDataRow
              label="Model"
              value={purchaseVehicle?.model ?? "-"}
            />

            <SectionDataRow label="Year" value={purchaseVehicle?.year ?? "-"} />

            <SectionDataRow
              label="Mileage"
              value={purchaseVehicle?.mileage?.toLocaleString() ?? "-"}
            />

            <SectionDataRow label="Trim" value={purchaseVehicle?.trim ?? "-"} />

            <SectionDataRow
              label="MSRP"
              value={
                purchaseVehicle?.msrp
                  ? `$${purchaseVehicle?.msrp?.toLocaleString()}`
                  : "-"
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TransactionPurchaseVehicle;
