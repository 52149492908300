import { z } from "zod";

// Define the PersistedSchema for image quality findings, which appears in multiple places
const ImageQualityFindingPersistedSchema = z.object({
  code: z.number(),
  message: z.string(),
});

// Define the PersistedSchema for intellicheck_raw.idcheck
const IdCheckPersistedSchema = z.object({
  message: z.string(),
  data: z.object({
    organDonor: z.string().nullish(),
    duplicateDate: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    docCategory: z.string().nullish(),
    eyeColor: z.string().nullish(),
    transactionIdentifier: z.string().nullish(),
    isDuplicate: z.string().nullish(),
    extendedResultCode: z.string().nullish(),
    heightFeetInches: z.string().nullish(),
    isRealID: z.string().nullish(),
    weightPounds: z.string().nullish(),
    dLIDNumberFormatted: z.string().nullish(),
    expired: z.string().nullish(),
    issuingJurisdictionAbbrv: z.string().nullish(),
    stateIssuerMismatch: z.string().nullish(),
    weightKilograms: z.string().nullish(),
    address2: z.string().nullish(),
    state: z.string().nullish(),
    expirationDate: z.string().nullish(),
    issueDate: z.string().nullish(),
    dLIDNumberRaw: z.string().nullish(),
    city: z.string().nullish(),
    middleName: z.string().nullish(),
    testCard: z.boolean().nullish(),
    driverClass: z.string().nullish(),
    heightCentimeters: z.string().nullish(),
    uniqueID: z.number().nullish(),
    hairColor: z.string().nullish(),
    age: z.number().nullish(),
    restrictions: z.string().nullish(),
    socialSecurity: z.string().nullish(),
    mediaType: z.string().nullish(),
    endorsements: z.string().nullish(),
    postalCode: z.string().nullish(),
    processResult: z.string().nullish(),
    docType: z.string().nullish(),
    dateOfBirth: z.string().nullish(),
    issuingJurisdictionCvt: z.string().nullish(),
    address1: z.string().nullish(),
    gender: z.string().nullish(),
  }),
  success: z.boolean(),
  result: z.boolean(),
  imageQualityFindings: z.array(ImageQualityFindingPersistedSchema).optional(),
});

// Define the PersistedSchema for intellicheck_raw.OCR
const OCRPersistedSchema = z.object({
  data: z.object({
    lastName: z.string().nullish(),
    dateOfIssue: z.string().nullish(),
    sex: z.string().nullish(),
    nationality: z.string().nullish(),
    firstName: z.string().nullish(),
    dateOfExpiry: z.string().nullish(),
    age: z.number().nullish(),
    countryCode: z.string().nullish(),
    fullName: z.string().nullish(),
    placeOfBirth: z.string().nullish(),
    faceImageBase64: z.string().nullish(),
    dlRestrictions: z.string().nullish(),
    isRealID: z.string().nullish(),
    address: z.string().nullish(),
    errorMessage: z.string().nullish(),
    dlClass: z.string().nullish(),
    weightKilograms: z.number().nullish(),
    dateOfBirth: z.string().nullish(),
    dateOfBirthFormatted: z.string().nullish(),
    documentNumber: z.string().nullish(),
    issuerName: z.string().nullish(),
    fullDocumentImageBase64: z.string().nullish(),
    dateOfExpiryFormatted: z.string().nullish(),
    dlEndorsement: z.string().nullish(),
    documentRecognized: z.number().nullish(),
    eyeColor: z.string().nullish(),
    height: z.string().nullish(),
    dateOfIssueFormatted: z.string().nullish(),
  }),
  message: z.string(),
  imageQualityFindings: z.array(ImageQualityFindingPersistedSchema).optional(),
  success: z.boolean(),
  result: z.boolean(),
});

// Define the MatchDetail subschema for reuse
const MatchDetailSchema = z.object({
  details: z.string().nullish(),
  similarityScore: z.number().nullish(),
  similarityThreshold: z.number().nullish(),
});

// Define the PersistedSchema for intellicheck_raw.ocr_match
export const OcrMatchDataPersistedSchema = z.object({
  nameMatchDetails: MatchDetailSchema.nullish(),
  dlClassMatchDetails: MatchDetailSchema.nullish(),
  weightMatchDetails: MatchDetailSchema.nullish(),
  dobMatchDetails: MatchDetailSchema.nullish(),
  eyeColorMatchDetails: MatchDetailSchema.nullish(),
  sexMatchDetails: MatchDetailSchema.nullish(),
  isDlRestrictionsMatch: z.boolean().nullish(),
  isDocumentNumberMatch: z.boolean().nullish(),
  expirationDateMatchDetails: MatchDetailSchema.nullish(),
  issuerNameMatchDetails: MatchDetailSchema.nullish(),
  isCountryCodeMatch: z.boolean().nullish(),
  isNationalityMatch: z.boolean().nullish(),
  isExpirationDateMatch: z.boolean().nullish(),
  isIssuerNameMatch: z.boolean().nullish(),
  issueDateMatchDetails: MatchDetailSchema.nullish(),
  isEyeColorMatch: z.boolean().nullish(),
  realIdMatchDetails: MatchDetailSchema.nullish(),
  documentNumberMatchDetails: MatchDetailSchema.nullish(),
  addressMatchDetails: MatchDetailSchema.nullish(),
  isNameMatch: z.boolean().nullish(),
  isDobMatch: z.boolean().nullish(),
  dlRestrictionsMatchDetails: MatchDetailSchema.nullish(),
  dlEndorsementMatchDetails: MatchDetailSchema.nullish(),
  isAddressMatch: z.boolean().nullish(),
  isDlEndorsementMatch: z.boolean().nullish(),
  heightMatchDetails: MatchDetailSchema.nullish(),
  isHeightMatch: z.boolean().nullish(),
  isSexMatch: z.boolean().nullish(),
  isDlClassMatch: z.boolean().nullish(),
  isWeightMatch: z.boolean().nullish(),
  isIssueDateMatch: z.boolean().nullish(),
  isRealIdMatch: z.boolean().nullish(),
});
export type OcrMatchDataPersistedSchema = z.TypeOf<
  typeof OcrMatchDataPersistedSchema
>;

const OcrMatchPersistedSchema = z.object({
  data: OcrMatchDataPersistedSchema,
  result: z.boolean(),
  success: z.boolean(),
  message: z.string(),
});

// Define the PersistedSchema for intellicheck_raw
export const IntellicheckRawPersistedSchema = z.object({
  idcheck: IdCheckPersistedSchema,
  OCR: OCRPersistedSchema,
  ocr_match: OcrMatchPersistedSchema,
});
export type IntellicheckRawPersistedSchema = z.TypeOf<
  typeof IntellicheckRawPersistedSchema
>;

// Define the main response PersistedSchema
export const AgilityCreditDlVerificationResponsePersistedSchema = z.object({
  session_id: z.string(),
  applicant_id: z.string(),
  completed: z.boolean(),
  retakes: z.array(z.string()),
  result: IntellicheckRawPersistedSchema,
});
export type AgilityCreditDlVerificationResponsePersistedSchema = z.TypeOf<
  typeof AgilityCreditDlVerificationResponsePersistedSchema
>;
