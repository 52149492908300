import { Tab } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { DateRangePicker, Spinner } from "@thedealersconcierge/components";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import classNames from "classnames";
import { endOfDay, format, startOfDay } from "date-fns";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { TransactionStatus } from "~/__generated__/backend/zeus";
import ChevronRightIcon from "~/components/icons/ChevronRightIcon";
import { dealershipAtom } from "~/state";
import { Pagination } from "../../_dashboardUtils";
import RankingCard from "../_components/RankingCard";
import { transactionUpdateSalesRanking } from "../_queries/analyticsQuery";

export default function ReportPage() {
  const [startDate, setStartDateFilter] = useState<Date | undefined>();
  const [endDate, setEndDateFilter] = useState<Date | undefined>();
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    pageSize: 16,
    direction: "after",
  });

  // Used to switch ranking based on the tab
  const [rankingTabStatus, setRankingTabStatus] = useState<TransactionStatus>(
    TransactionStatus.DELIVERED
  );
  const statusTabs: TransactionStatus[] = [
    TransactionStatus.DELIVERED,
    TransactionStatus.REGISTERED,
  ];
  const tabs = statusTabs.map(getReadableTransactionStatus);

  const dealership = useAtomValue(dealershipAtom);

  const getFormattedDateRange = (
    selectedStartDate?: Date,
    selectedEndDate?: Date
  ) => {
    const formatDate = (date?: Date) => {
      if (!date) return "";
      return format(date, "MMMM d, yyyy");
    };

    if (selectedStartDate && selectedEndDate) {
      return `${formatDate(selectedStartDate)} - ${formatDate(
        selectedEndDate
      )}`;
    }

    return "";
  };

  // Rankings
  const { data: currentRanking, isLoading } = useQuery(
    transactionUpdateSalesRanking({
      startDate: startDate,
      transactionStatus: [rankingTabStatus],
      dealershipId: dealership?.activeDealershipPerms.dealershipId,
      page: page.currentPage,
      pageSize: page.pageSize,
    })
  );

  const salesRankings =
    currentRanking?.dealership?.transactionUpdateStatusSalesRankingGrouped ??
    [];

  const leftGroup = salesRankings.slice(0, 8);
  const rightGroup = salesRankings.slice(8, 16);

  const sortedRankCounts = Array.from(
    new Set(salesRankings.map((item) => item.currentMonth ?? 0))
  ).sort((a, b) => b - a);
  const pageOffset = (page.currentPage - 1) * page.pageSize;

  const maxTarget =
    salesRankings.length > 0
      ? salesRankings.reduce(
          (max, item) => Math.max(max, item.currentMonth ?? 0),
          0
        )
      : 0;

  const isNextDisabled = salesRankings.length < page.pageSize;
  const isPrevDisabled = page.currentPage <= 1;

  return (
    <div className="flex flex-grow flex-col w-full pb-spacing-06">
      {/* Search Bar */}
      <div className="flex flex-row w-full space-x-spacing-05 items-center group h-[60px] justify-start mb-spacing-02">
        <div className="min-w-[200px]">
          <DateRangePicker
            backgroundType="LIGHT"
            placeholder="Select report dates"
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            initiallySelectedRangeType="THIS_MONTH"
            useVerboseDateRange={false}
            label={""}
            required={false}
            disabled={false}
            onChange={(start, end) => {
              setStartDateFilter(start ? startOfDay(start) : undefined);
              setEndDateFilter(end ? endOfDay(end) : undefined);
            }}
          />
        </div>
        <p className="font-bold text-large">
          {getFormattedDateRange(startDate, endDate)}
        </p>
      </div>

      {/* Cards  */}
      <div className="grid grid-cols-12 gap-spacing-05 w-full h-full items-stretch">
        {/* Right Section */}
        <div className="col-span-12 h-full">
          <Tab.Group
            onChange={(index) => {
              const selectedTab = statusTabs.at(index);
              if (selectedTab) {
                setRankingTabStatus(selectedTab);
                setPage((p) => ({
                  ...p,
                  currentPage: 1,
                }));
              }
            }}
          >
            <div className="h-full flex flex-col min-h-0">
              {/* Tab List */}
              <Tab.List className="flex border-b border-gray-200">
                {tabs.map((tab) => (
                  <Tab
                    key={tab}
                    className={({ selected }) =>
                      classNames(
                        "relative py-spacing-01 px-spacing-04 focus:outline-none",
                        {
                          "border-b-2 border-primary-blue text-primary font-bold":
                            selected,
                          "text-tertiary hover:text-primary-blue": !selected,
                        }
                      )
                    }
                  >
                    {tab}
                  </Tab>
                ))}
              </Tab.List>

              {/* Tab Panels */}
              <div className="flex flex-row w-full h-full gap-spacing-06 justify-between mt-spacing-05">
                {isLoading && (
                  <div className="w-full flex flex-row items-center justify-center h-full overflow-hidden">
                    <Spinner color="BLUE" size="LARGE" />
                  </div>
                )}

                {!isLoading && salesRankings.length === 0 && (
                  <div className="w-full">
                    <p>No data to display</p>
                  </div>
                )}

                {!isLoading && salesRankings.length > 0 && (
                  <div className="flex flex-row w-full h-full gap-spacing-05 justify-between">
                    <div className="flex flex-col h-full w-1/2 justify-start p-spacing-04 bg-primary">
                      {leftGroup.map((ranking, index) => {
                        const computedRank =
                          sortedRankCounts.indexOf(ranking.currentMonth ?? 0) +
                          1 +
                          pageOffset;
                        return (
                          <div className="flex flex-row w-full">
                            <RankingCard
                              key={ranking.userId}
                              firstName={ranking.firstName ?? "FirstName"}
                              lastName={ranking.lastName ?? "LastName"}
                              ranking={computedRank}
                              target={maxTarget}
                              thisMonth={ranking.currentMonth ?? 0}
                              lastMonth={ranking.lastMonth ?? 0}
                              last2Month={ranking.twoMonthsAgo ?? 0}
                              ordinalNumber={index + 1 + pageOffset}
                            />
                          </div>
                        );
                      })}
                    </div>

                    {rightGroup.length > 0 && (
                      <div className="flex flex-col h-full w-1/2 justify-start p-spacing-04 bg-primary">
                        {rightGroup.map((ranking, index) => {
                          const computedRank =
                            sortedRankCounts.indexOf(
                              ranking.currentMonth ?? 0
                            ) +
                            1 +
                            pageOffset;

                          return (
                            <RankingCard
                              key={ranking.userId}
                              firstName={ranking.firstName ?? "FirstName"}
                              lastName={ranking.lastName ?? "LastName"}
                              ranking={computedRank}
                              target={maxTarget}
                              thisMonth={ranking.currentMonth ?? 0}
                              lastMonth={ranking.lastMonth ?? 0}
                              last2Month={ranking.twoMonthsAgo ?? 0}
                              ordinalNumber={
                                index + leftGroup.length + 1 + pageOffset
                              }
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* Pagination */}
              <div className="flex flex-row space-x-spacing-02 items-center text-dark-gray w-full justify-end mt-2">
                {/* Previous Button */}
                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-sec justify-center items-center",
                    { "opacity-40": isPrevDisabled }
                  )}
                  disabled={isPrevDisabled}
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      currentPage: p.currentPage - 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square rotate-180" />
                  </div>
                </button>

                <div className="flex flex-row space-x-2">
                  <div className="flex h-8 px-2 rounded justify-center items-center bg-light-gray">
                    {page.currentPage}
                  </div>
                </div>

                {/* Next Button */}
                <button
                  className={classNames(
                    "flex w-8 aspect-square rounded bg-light-gray justify-center items-center",
                    { "opacity-40": isNextDisabled }
                  )}
                  disabled={isNextDisabled}
                  onClick={() => {
                    setPage((p) => ({
                      ...p,
                      currentPage: p.currentPage + 1,
                    }));
                  }}
                >
                  <div className="relative">
                    <ChevronRightIcon className="w-4 aspect-square" />
                  </div>
                </button>
              </div>
            </div>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
