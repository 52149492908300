import { useQuery } from "@tanstack/react-query";
import { Banner, DataStatus, Spinner } from "@thedealersconcierge/components";
import { RMRecallResValidationSchema } from "@thedealersconcierge/lib/codecs/recall-masters";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { FC } from "react";
import { Fragment } from "react/jsx-runtime";
import {
  FormCollectionType,
  FormSubmissionStatus,
  OrderBy,
} from "~/__generated__/backend/zeus";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import FormattedNumber from "~/components/FormattedNumber";
import { gqlQueryClient } from "~/lib/backend";
import { Link, useNavigate, useParams } from "~/router";

const RecallDetails: FC<{
  type: "MANDATORY" | "VOLUNTARY";
  data: RMRecallResValidationSchema["recalls"][number];
}> = ({ type, data }) => {
  /**
   * This takes the numerical profit rank provided by Recall Masters and returns
   * the meaning behind it.
   *
   * The descriptions are taken from the documentation: https://www.notion.so/Recall-Masters-1b2f6cfdda038078b9c9da2db5481b06
   *
   * @param profitRank: Based on the documentation, the ranks range from 1 to 5
   */
  const getHumanReadableProfitRank = (profitRank: number) => {
    switch (profitRank) {
      case 1:
        return "Short Duration Repair, Requires car lift";
      case 2:
        return "Medium Duration Repair, Requires car lift";
      case 3:
        return "Short Duration Repair, Software only or no lift required";
      case 4:
        return "Medium Duration Repair, Software only or no lift required";
      case 5:
        return "Long Duration Repair, Any repair difficulty";
      default:
        return profitRank;
    }
  };

  /**
   * This takes the numerical labor difficulty provided by Recall Masters and returns
   * the meaning behind it.
   *
   * The descriptions are taken from the documentation: https://www.notion.so/Recall-Masters-1b2f6cfdda038078b9c9da2db5481b06
   *
   * @param laborDifficulty: Based on the documentation, the diffuculties range from 1 to 5
   */
  const getHumanReadableLaborDifficulty = (laborDifficulty: number) => {
    switch (laborDifficulty) {
      case 1:
        return "Sticker";
      case 2:
        return "Nuisance";
      case 3:
        return "Programming Only";
      case 4:
        return "No Car Lift";
      case 5:
        return "Car Lift";
      default:
        return laborDifficulty;
    }
  };

  /**
   * This takes the numerical risk rank provided by Recall Masters and returns
   * the meaning behind it.
   *
   * The descriptions are taken from the documentation: https://www.notion.so/Recall-Masters-1b2f6cfdda038078b9c9da2db5481b06
   *
   * @param riskRank: Based on the documentation, the risk ranks range from 1 to 5
   */
  const getHumanReadableRiskRank = (riskRank: number) => {
    switch (riskRank) {
      case 1:
        return "Zero Risk. Nuisance, sticker/label, or owner's manual recall.";
      case 2:
        return "Low Risk. Low chance of injury. No chance of fire/crash.";
      case 3:
        return "Medium Risk. Mild chance of injury. No chance of fire/crash.";
      case 4:
        return "High Risk. Possible crash, fire, high risk of injury.";
      case 5:
        return "Extreme Risk. Possible crash/fire, high risk of injury.";
      default:
        return riskRank;
    }
  };

  const getHumanReadableOverallRank = (rank: number) => {
    switch (rank) {
      case 0:
        return "Forbidden, never seen or exported";
      case 1:
        return "Zero Risk: Nuisance, sticker/label, or owner's manual recall.";
      case 2:
        return "No Parts Available";
      case 3:
        return "Low to Medium Risk, Medium Profitability";
      case 4:
        return "Medium to High Risk, Medium to High Profitability";
      case 5:
        return "High to Extreme Risk, Medium to High Profitability";
      default:
        return rank;
    }
  };

  return (
    <div className="flex flex-col bg-secondary rounded-radius-02 p-spacing-04 gap-spacing-04 body-02">
      <h4 className="heading-04">{data.name}</h4>

      <div>
        <div className="grid grid-cols-5">
          <p className="text-tertiary">ID</p>

          <p className="col-span-4">{data.recall_id}</p>
        </div>

        {type === "MANDATORY" && (
          <div className="grid grid-cols-5">
            <p className="text-tertiary">NHTSA ID</p>

            <p className="col-span-4">{data.nhtsa_id}</p>
          </div>
        )}

        <div className="grid grid-cols-5">
          <p className="text-tertiary">OEM ID</p>

          <p className="col-span-4">{data.oem_id}</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Effective date</p>

          <p className="col-span-4">
            {format(parseISO(data.effective_date), "yyyy-MM-dd")}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Expiration date</p>

          <p className="col-span-4">
            {data.expiration_date
              ? format(parseISO(data.expiration_date), "yyyy-MM-dd")
              : "N/A"}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Age</p>

          <p className="col-span-4">{data.recall_age} days</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Overall rank</p>

          <p className="col-span-4">
            {getHumanReadableOverallRank(data.overall_rank)}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Last updated</p>

          <p className="col-span-4">
            {format(parseISO(data.recall_last_updated), "yyyy-MM-dd")}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-5">
        <p className="text-tertiary">Description</p>

        <p className="col-span-4">{data.description}</p>
      </div>

      <div>
        <div className="grid grid-cols-5">
          <p className="text-tertiary">Risk type</p>

          <p className="col-span-4">{data.risk_type}</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Risk rank</p>

          <p className="col-span-4">
            {getHumanReadableRiskRank(data.risk_rank)}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Risk</p>

          <p className="col-span-4">{data.risk}</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Stop sale</p>

          <p className="col-span-4">{data.stop_sale ? "Yes" : "No"}</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Don't drive</p>

          <p className="col-span-4">{data.dont_drive ? "Yes" : "No"}</p>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-5">
          <p className="text-tertiary">Remedy available</p>

          <DataStatus
            status={data.is_remedy_available ? "COMPLETE" : "INCOMPLETE"}
            size="SMALL"
          />
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Remedy</p>

          <p className="col-span-4">{data.remedy}</p>
        </div>
      </div>

      <div>
        <div className="grid grid-cols-5">
          <p className="text-tertiary">Pre-inspection required</p>

          <p className="col-span-4">
            {data.is_preinspection_required ? "Yes" : "No"}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Parts available</p>

          <DataStatus
            status={data.are_parts_available ? "COMPLETE" : "INCOMPLETE"}
            size="SMALL"
          />
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Estimated parts availability date</p>

          <p className="col-span-4">
            {data.parts_available_date
              ? format(parseISO(data.parts_available_date), "yyyy-MM-dd")
              : "N/A"}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Minimum labor</p>

          <p className="col-span-4">{data.labor_min} hours</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Maximum labor</p>

          <p className="col-span-4">{data.labor_max} hours</p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Labor difficulty</p>

          <p className="col-span-4">
            {getHumanReadableLaborDifficulty(data.labor_difficulty)}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Profit rank</p>

          <p className="col-span-4">
            {getHumanReadableProfitRank(data.profit_rank)}
          </p>
        </div>

        <div className="grid grid-cols-5">
          <p className="text-tertiary">Reimbursement</p>

          <FormattedNumber
            value={data.reimbursement}
            className="col-span-4"
            suffix="$"
          />
        </div>
      </div>
    </div>
  );
};

const RecallMastersRecallReportPageContent: FC<{
  transactionTitle: string;
  transactionId: string;
  data: RMRecallResValidationSchema;
  requestDate: Date;
  formSubmissions:
    | {
        id: string;
        formTitle?: string | null;
        recallDescription?: string | null;
        status?: string | null;
      }[]
    | undefined;
}> = ({
  transactionTitle,
  transactionId,
  data,
  requestDate,
  formSubmissions,
}) => {
  const navigate = useNavigate();
  /**
   * Mandatory recalls have a NHTSA ID
   */
  const mandatoryRecalls = data.recalls.filter((recall) =>
    Boolean(recall.nhtsa_id.length)
  );
  /**
   * Voluntary recalls don't have a NHTSA ID
   */
  const voluntaryRecalls = data.recalls.filter(
    (recall) => !Boolean(recall.nhtsa_id.length)
  );
  const openInDealJacket = (formSubmissionId: string) => {
    navigate(
      "/dashboard/transaction/:transactionId/deal-jacket/formSubmission/:formSubmissionId",
      {
        params: {
          transactionId,
          formSubmissionId,
        },
      }
    );
  };

  return (
    <Fragment>
      <BreadCrumbsContainer>
        <BreadCrumb title="Transactions">
          <Link to="/dashboard">Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title={transactionTitle}>
          <Link
            to="/dashboard/transaction/:transactionId"
            params={{ transactionId }}
          >
            {transactionTitle}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Purchase Vehicle">
          <Link
            to="/dashboard/transaction/:transactionId/vehicle/purchase"
            params={{ transactionId }}
          >
            Purchase Vehicle
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Recalls" />
      </BreadCrumbsContainer>

      <div className="flex flex-col bg-primary rounded-radius-03 shadow-shadow-03 p-spacing-06 space-y-spacing-06">
        <h2 className="heading-02">Recalls by Recall Masters</h2>

        <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 body-01 gap-spacing-04">
          <div>
            <div className="grid grid-cols-3">
              <p className="text-tertiary">VIN</p>

              <p className="col-span-2">{data.vin}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Make</p>

              <p className="col-span-2">{data.make}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Model</p>

              <p className="col-span-2">{data.model_name}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Year</p>

              <p className="col-span-2">{data.model_year}</p>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-3">
              <p className="text-tertiary">Total recalls</p>

              <p className="col-span-2">{data.recall_count}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Mandatory recalls</p>

              <p className="col-span-2">{mandatoryRecalls.length}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Voluntary recalls</p>

              <p className="col-span-2">{voluntaryRecalls.length}</p>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-3">
              <p className="text-tertiary">Request date</p>

              <p className="col-span-2">{format(requestDate, "yyyy-MM-dd")}</p>
            </div>

            <div className="grid grid-cols-3">
              <p className="text-tertiary">Data last updated</p>

              <p className="col-span-2">
                {format(parseISO(data.last_updated), "yyyy-MM-dd")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-primary rounded-radius-03 shadow-shadow-03 p-spacing-06 space-y-spacing-06">
        <h3 className="heading-03">Recall Forms</h3>

        <div className="body-01">
          {formSubmissions?.map((formSubmission) => {
            const isFinal =
              formSubmission.status === FormSubmissionStatus.FINAL;
            return (
              <div
                className={classNames("flex flex-row space-x-spacing-02", {
                  "cursor-pointer text-primary-blue": isFinal,
                })}
                onClick={() => {
                  if (isFinal) {
                    openInDealJacket(formSubmission.id);
                  }
                }}
              >
                <DataStatus status={isFinal ? "COMPLETE" : "INCOMPLETE"} />

                <p>
                  {formSubmission.formTitle +
                    (formSubmission.recallDescription
                      ? ` - ${formSubmission.recallDescription}`
                      : "")}
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col bg-primary rounded-radius-03 shadow-shadow-03 p-spacing-06 space-y-spacing-06">
        <h3 className="heading-03">
          Mandatory Recalls ({mandatoryRecalls.length})
        </h3>

        {mandatoryRecalls.map((recall) => {
          return (
            <RecallDetails
              key={recall.recall_id}
              type="MANDATORY"
              data={recall}
            />
          );
        })}
      </div>

      <div className="flex flex-col bg-primary rounded-radius-03 shadow-shadow-03 p-spacing-06 space-y-spacing-06">
        <h3 className="heading-03">
          Voluntary Recalls ({voluntaryRecalls.length})
        </h3>

        {voluntaryRecalls.map((recall) => {
          return (
            <RecallDetails
              key={recall.recall_id}
              type="VOLUNTARY"
              data={recall}
            />
          );
        })}
      </div>

      {/**
       * This is a spacer
       */}
      <div className="min-h-spacing-06" />
    </Fragment>
  );
};

export default function RecallMastersRecallReportPage() {
  const { transactionId, recallReportId } = useParams(
    "/dashboard/transaction/:transactionId/vehicle/purchase/recall-masters/:recallReportId"
  );
  const { isLoading, data } = useQuery({
    queryKey: [
      "transaction",
      transactionId,
      "vehicle",
      "recall-masters",
      recallReportId,
    ],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            title: true,
            vehicle: {
              recallMastersRecallReports: [
                {
                  first: 1,
                  filter: {
                    id: {
                      equals: recallReportId,
                    },
                  },
                },
                {
                  edges: {
                    node: {
                      response: true,
                      createdAt: true,
                    },
                  },
                },
              ],
            },
            formSubmissionCollections: [
              {
                first: 1,
                orderBy: {
                  createdAt: OrderBy.Desc,
                },
                filter: {
                  type: {
                    equals: FormCollectionType.VEHICLE_RECALL,
                  },
                },
              },
              {
                edges: {
                  node: {
                    formSubmissions: [
                      {},
                      {
                        edges: {
                          node: {
                            id: true,
                            status: true,
                            form: {
                              displayTitle: true,
                            },
                            formFieldSubmissions: [
                              {
                                filter: {
                                  field: {
                                    prefillPath:
                                      "enriched.transaction.vehicle.recallMasters.recall.description",
                                  },
                                },
                                first: 1,
                              },
                              {
                                edges: {
                                  node: {
                                    value: true,
                                  },
                                },
                              },
                            ],
                          },
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      }),
  });

  if (isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <Spinner size="LARGE" color="GREY" />
      </div>
    );
  }

  const recallReport =
    data?.transaction?.vehicle?.recallMastersRecallReports?.edges?.[0]?.node;
  const recallReportResponse = RMRecallResValidationSchema.safeParse(
    recallReport?.response
  );

  if (!recallReport?.createdAt || !recallReportResponse.success) {
    return (
      <Banner message={<span>Recall report not found</span>} type="ERROR" />
    );
  }

  const formCollection =
    data?.transaction?.formSubmissionCollections?.edges?.[0]?.node;
  const formSubmissions = formCollection?.formSubmissions?.edges?.map((e) => {
    return {
      id: e.node?.id ?? "no-formsubmission-id",
      formTitle: e.node?.form?.displayTitle,
      recallDescription: e.node?.formFieldSubmissions?.edges?.[0]?.node?.value, // In the query, we already filtered for the prefill path for the recall description
      status: e.node?.status,
    };
  });

  return (
    <RecallMastersRecallReportPageContent
      transactionId={transactionId}
      transactionTitle={data?.transaction?.title ?? transactionId}
      data={recallReportResponse.data}
      requestDate={recallReport.createdAt}
      formSubmissions={formSubmissions}
    />
  );
}
