import range from "lodash/range";
import { PDFDocument } from "pdf-lib";

/**
 * From a list of URLs pointing to PDF files this will give
 *
 * @param urls
 */
export const pdfUrlsToBlob = async (urls: string[]) => {
  // If there is only a single document, we don't process it further.
  // The reason is to support downloading a single encrypted file, which we can not generally work on
  if (urls.length === 1) {
    const pdfBuffer = await fetch(urls[0]).then((res) => res.arrayBuffer());
    return new Blob([pdfBuffer], { type: "application/pdf" });
  }

  const pdfDoc = await PDFDocument.create(); // create a new pdf document for bundling

  for (const url of urls) {
    const pdfBuffer = await fetch(url).then((res) => res.arrayBuffer());
    const srcPdfDoc = await PDFDocument.load(pdfBuffer);
    const srcPageIndices = range(srcPdfDoc.getPageCount());
    const copiedPages = await pdfDoc.copyPages(srcPdfDoc, srcPageIndices);
    copiedPages.forEach((page) => pdfDoc.addPage(page));
  }

  const combinedPdfBuffer = await pdfDoc.save();

  // Create a blob and trigger the download
  return new Blob([combinedPdfBuffer], { type: "application/pdf" });
};
