// Generouted, changes to this file will be overridden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/dashboard`
  | `/dashboard/customers`
  | `/dashboard/customers/:userId`
  | `/dashboard/customers/:userId/:transactionId/files`
  | `/dashboard/customers/:userId/:transactionId/files/document/:documentId`
  | `/dashboard/customers/:userId/:transactionId/files/formSubmission/:formSubmissionId`
  | `/dashboard/reports`
  | `/dashboard/reports/sales-ranking`
  | `/dashboard/staff`
  | `/dashboard/staff/:userId`
  | `/dashboard/transaction/:transactionId`
  | `/dashboard/transaction/:transactionId/co-buyer`
  | `/dashboard/transaction/:transactionId/co-buyer/add`
  | `/dashboard/transaction/:transactionId/customer/:userId`
  | `/dashboard/transaction/:transactionId/deal`
  | `/dashboard/transaction/:transactionId/deal-jacket`
  | `/dashboard/transaction/:transactionId/deal-jacket/document/:documentId`
  | `/dashboard/transaction/:transactionId/deal-jacket/formSubmission/:formSubmissionId`
  | `/dashboard/transaction/:transactionId/deal-jacket/view`
  | `/dashboard/transaction/:transactionId/vehicle/purchase`
  | `/dashboard/transaction/:transactionId/vehicle/purchase/recall-masters/:recallReportId`
  | `/dashboard/transaction/:transactionId/vehicle/trade`
  | `/profile`

export type Params = {
  '/dashboard/customers/:userId': { userId: string }
  '/dashboard/customers/:userId/:transactionId/files': { userId: string; transactionId: string }
  '/dashboard/customers/:userId/:transactionId/files/document/:documentId': { userId: string; transactionId: string; documentId: string }
  '/dashboard/customers/:userId/:transactionId/files/formSubmission/:formSubmissionId': { userId: string; transactionId: string; formSubmissionId: string }
  '/dashboard/staff/:userId': { userId: string }
  '/dashboard/transaction/:transactionId': { transactionId: string }
  '/dashboard/transaction/:transactionId/co-buyer': { transactionId: string }
  '/dashboard/transaction/:transactionId/co-buyer/add': { transactionId: string }
  '/dashboard/transaction/:transactionId/customer/:userId': { transactionId: string; userId: string }
  '/dashboard/transaction/:transactionId/deal': { transactionId: string }
  '/dashboard/transaction/:transactionId/deal-jacket': { transactionId: string }
  '/dashboard/transaction/:transactionId/deal-jacket/document/:documentId': { transactionId: string; documentId: string }
  '/dashboard/transaction/:transactionId/deal-jacket/formSubmission/:formSubmissionId': { transactionId: string; formSubmissionId: string }
  '/dashboard/transaction/:transactionId/deal-jacket/view': { transactionId: string }
  '/dashboard/transaction/:transactionId/vehicle/purchase': { transactionId: string }
  '/dashboard/transaction/:transactionId/vehicle/purchase/recall-masters/:recallReportId': { transactionId: string; recallReportId: string }
  '/dashboard/transaction/:transactionId/vehicle/trade': { transactionId: string }
}

export type ModalPath = `/dashboard/staff/create` | `/dashboard/transaction/[transactionId]/co-buyer/credit-report` | `/dashboard/transaction/[transactionId]/credit-report` | `/dashboard/transaction/[transactionId]/customer/[userId]/id` | `/dashboard/transaction/[transactionId]/dealScenarios` | `/dashboard/transaction/[transactionId]/lending-portal/buyer` | `/dashboard/transaction/[transactionId]/lending-portal/coBuyer` | `/dashboard/transaction/create`

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
