import { ArrowDownward, ArrowLeft, ArrowUpward } from '@mui/icons-material';
import classNames from 'classnames';
import { cloneElement, FC } from 'react';

export type TagColorTypes =
  | 'RED'
  | 'LIGHT_RED'
  | 'YELLOW'
  | 'GREEN'
  | 'LIGHT_GREEN'
  | 'TEAL'
  | 'BLUE'
  | 'LIGHT_PINK'
  | 'PINK'
  | 'GRAY';

export type TrendDirection = 'UP' | 'DOWN' | 'STABLE';

/**
 * The Tag Component is a Base component to build any tag systems
 * e.g: TransactionSourceBadge
 */
interface TagProps {
  /**
   * Background color type
   */
  color: TagColorTypes;

  /**
   * Indicates the direction (trend) of a metric.
   * - "UP":   A positive/increasing trend
   * - "DOWN": A negative/decreasing trend
   * - "STABLE": No meaningful change
   *
   * When provided, the text color can be influenced by both
   * the `color` and the chosen trend direction.
   */
  trendDirection?: TrendDirection;

  /**
   * The label displayed inside the tag
   */
  label: string;

  /**
   * Should be an imported component from '@mui/icons-material'
   */
  iconLeft?: JSX.Element;

  /**
   * Should be an imported component from '@mui/icons-material'
   */
  iconRight?: JSX.Element;

  /**
   * Optional additional classes for the container
   */
  className?: string;

  /**
   * For E2E testing
   */
  dataTestId?: string;
}

const Tag: FC<TagProps> = ({
  color,
  trendDirection,
  label,
  iconLeft,
  iconRight,
  className,
  dataTestId
}) => {
  const trendIconMap: Record<TrendDirection, JSX.Element> = {
    UP: <ArrowUpward />,
    DOWN: <ArrowDownward />,
    STABLE: <ArrowLeft />
  };
  const trendIcon = trendDirection ? trendIconMap[trendDirection] : undefined;

  return (
    <div
      data-test-id={dataTestId}
      className={classNames(
        'inline-flex items-center justify-center gap-1 rounded-radius-03 font-medium px-spacing-02 py-spacing-01 text-xs',
        {
          /* These color tokens are matched from figma to tailwind.config */
          'bg-red-300': color === 'RED',
          'bg-red-200': color === 'LIGHT_RED',
          'bg-yellow-200': color === 'YELLOW',
          'bg-green-200': color === 'GREEN',
          'bg-green-100': color === 'LIGHT_GREEN',
          'bg-sky-200': color === 'TEAL',
          'bg-info-light': color === 'BLUE',
          'bg-fuchsia-100': color === 'LIGHT_PINK',
          'bg-fuchsia-200': color === 'PINK',
          'bg-gray-200': color === 'GRAY'
        },
        className
      )}
    >
      {iconLeft && (
        <span className="flex h-fit">
          {cloneElement(iconLeft, { sx: { fontSize: 12 } })}
        </span>
      )}

      {/* Trend icon, if trendDirection is set */}
      {trendIcon && (
        <span
          className={classNames('flex h-fit', {
            'text-primary': !trendDirection,
            'text-success-dark':
              trendDirection === 'UP' && color === 'LIGHT_GREEN',
            'text-danger': trendDirection === 'DOWN' && color === 'LIGHT_RED',
            'text-secondary': trendDirection === 'STABLE'
          })}
        >
          {cloneElement(trendIcon, { sx: { fontSize: 12 } })}
        </span>
      )}

      <span
        className={classNames({
          'text-primary': !trendDirection,
          'text-success-dark':
            trendDirection === 'UP' && color === 'LIGHT_GREEN',
          'text-danger': trendDirection === 'DOWN' && color === 'LIGHT_RED',
          'text-secondary': trendDirection === 'STABLE'
        })}
      >
        {label}
      </span>

      {iconRight && (
        <span className="flex h-fit">
          {cloneElement(iconRight, { sx: { fontSize: 12 } })}
        </span>
      )}
    </div>
  );
};

export default Tag;
