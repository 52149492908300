import { useSuspenseQuery } from "@tanstack/react-query"; // Ensure this hook is implemented in your codebase
import { Tooltip } from "@thedealersconcierge/components";
import { endOfDay, format, startOfDay } from "date-fns";
import { Fragment } from "react";
import {
  TransactionSource,
  TransactionStatus,
} from "~/__generated__/backend/zeus";
import { transactionAnalyticQuery } from "../dashboard/reports/_queries/analyticsQuery";

export default function AnalyticsBanner({
  dealershipId,
}: {
  dealershipId?: string;
}) {
  const today = new Date();
  const startToday = startOfDay(today);
  const endToday = endOfDay(today);

  // Showroom analytics: Kiosk source, Delivered status.
  const showroomTodayData = useSuspenseQuery(
    transactionAnalyticQuery({
      dealershipId,
      startDate: startToday,
      endDate: endToday,
      source: [TransactionSource.KIOSK],
      transactionStatus: [TransactionStatus.REGISTERED],
    })
  );
  const showroomToday =
    showroomTodayData?.data.dealership
      ?.transactionStatusUpdateDealershipAnalyticsCount ?? 0;

  // Web Prequals analytics: Web Prequal source, Delivered status.
  const webPrequalsTodayData = useSuspenseQuery(
    transactionAnalyticQuery({
      dealershipId,
      startDate: startToday,
      endDate: endToday,
      source: [TransactionSource.WEB_PREQUAL],
      transactionStatus: [TransactionStatus.REGISTERED],
    })
  );
  const webPrequalsToday =
    webPrequalsTodayData?.data.dealership
      ?.transactionStatusUpdateDealershipAnalyticsCount ?? 0;

  // Delivered analytics: All delivered transactions.
  const deliveredTodayData = useSuspenseQuery(
    transactionAnalyticQuery({
      dealershipId,
      startDate: startToday,
      endDate: endToday,
      transactionStatus: [TransactionStatus.DELIVERED],
    })
  );
  const deliveredToday =
    deliveredTodayData?.data.dealership
      ?.transactionStatusUpdateDealershipAnalyticsCount ?? 0;

  const formattedToday = format(today, "EEEE, MMMM d, yyyy");

  return (
    <div className="flex flex-col space-y-spacing-04">
      {/* Date */}
      <div className="text-medium font-bold">{formattedToday}</div>

      {/* Boxes Container */}
      <div className="flex flex-row gap-spacing-04">
        {/* Showroom Box */}

        <Tooltip
          content={<p>Registered transaction via Showroom</p>}
          anchor={
            <div className="bg-primary p-spacing-05 rounded-radius-02 flex flex-col space-y-spacing-01">
              <p className="text-tertiary font-bold">Showroom</p>
              <div className="flex justify-start items-center gap-spacing-02">
                <Fragment>
                  <div className="flex flex-row items-baseline gap-spacing-02">
                    <p className="text-large">Today:</p>
                    <p className="font-bold text-xlarge">{showroomToday}</p>
                  </div>
                </Fragment>
              </div>
            </div>
          }
        />

        {/* Web-Prequals Box */}
        <Tooltip
          content={<p>Registered transaction via Web-Prequals</p>}
          anchor={
            <div className="bg-primary p-spacing-05 rounded-radius-02 flex flex-col space-y-spacing-01">
              <p className="text-tertiary font-bold">Web-Prequals</p>
              <div className="flex justify-start items-center gap-spacing-02">
                <Fragment>
                  <div className="flex flex-row items-baseline gap-spacing-02">
                    <p className="text-large">Today:</p>
                    <p className="font-bold text-xlarge">{webPrequalsToday}</p>
                  </div>
                </Fragment>
              </div>
            </div>
          }
        />

        {/* Delivered Box */}
        <Tooltip
          content={<p>Total Delivered Transaction</p>}
          anchor={
            <div className="bg-primary p-spacing-05 rounded-radius-02 flex flex-col space-y-spacing-01">
              <p className="text-tertiary font-bold">Delivered</p>
              <div className="flex justify-start items-center gap-spacing-02">
                <Fragment>
                  <div className="flex flex-row items-baseline gap-spacing-02">
                    <p className="text-large">Today:</p>
                    <p className="font-bold text-xlarge">{deliveredToday}</p>
                  </div>
                </Fragment>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
