import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import { zodValidator, ZodValidator } from "@tanstack/zod-form-adapter";
import Typeahead, {
  TypeaheadOption,
} from "@thedealersconcierge/components/src/input/typeahead";
import { useAtomValue } from "jotai";
import stringify from "json-stable-stringify";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VehicleLifeCycleStage } from "~/__generated__/backend/zeus";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import vehicleBodyTypeOptions from "~/config/formSelectionOptions/vehicleBodyTypeOptions";
import { g } from "~/globals";
import { useDebounce } from "~/hooks/useDebounce";
import { gqlMutationClient } from "~/lib/backend";
import { stringToVehicleBodyType } from "~/lib/enumMap";
import { queryClient } from "~/lib/query";
import { Link, useNavigate, useParams } from "~/router";
import { VehicleType as Vehicle } from "~/selectors/vehicleSelector";
import { PrefillVehicleData } from "~/service/utils/fetchHomenetByStocknumberQuery";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../../_queries/transactionQuery";
import VehicleForm, {
  ValidVehicleSchema,
  VehicleFormType,
} from "../_components/VehicleForm";

const VehiclePageContent: FC<{
  transactionId: string;
  transactionTitle?: string;
  vehicle: Partial<Vehicle | undefined>;
}> = ({ transactionId, transactionTitle, vehicle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };

  const form = useForm<VehicleFormType, ZodValidator>({
    defaultValues: {
      stockNumber: vehicle?.stockNumber ?? "",
      vin: vehicle?.vin ?? "",
      bodyType: vehicle?.bodyType ?? undefined,
      make: vehicle?.make ?? "",
      model: vehicle?.model ?? "",
      year: vehicle?.year ?? "",
      color: vehicle?.color ?? "",
      condition: vehicle?.condition ?? undefined,
      lifeCycleStage: vehicle?.lifeCycleStage ?? undefined,
      mileage: vehicle?.mileage?.toString() ?? "",
      trim: vehicle?.trim ?? "",
      principalPriorUse: vehicle?.principalPriorUse ?? undefined,
      msrp: vehicle?.msrp?.toString() ?? "",
    },
    validators: { onSubmit: ValidVehicleSchema(t, "PURCHASE") },
    validatorAdapter: zodValidator(),
    onSubmit: async (value) => {
      try {
        setIsSubmitting(true);

        const values = value.value;

        if (!values.lifeCycleStage) {
          throw new Error("Please specifiy whether the vehicle is new or used");
        }

        if (vehicle?.id) {
          // Vehicle already present, we update
          const resp = await gqlMutationClient()({
            dealershipUpdatePurchaseVehicle: [
              {
                vehicleId: vehicle.id,
                vehicle: {
                  stockNumber: searchStockNumber,
                  vin: values.vin,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  trim: values.trim,
                  // It is safe to do this since mileage is required
                  mileage: parseFloat(values.mileage),
                  lifeCycleStage: values.lifeCycleStage,
                  principalPriorUse: values.principalPriorUse,
                  msrp:
                    values.msrp && values.msrp.length > 0
                      ? parseFloat(values.msrp)
                      : undefined,
                  bodyType: values.bodyType,
                },
              },
              {
                __typename: true,
                "...on GraphQLError": { message: true },
                "...on MutationDealershipUpdatePurchaseVehicleSuccess": {
                  data: { status: true },
                },
              },
            ],
          });

          if (
            !resp.dealershipUpdatePurchaseVehicle ||
            resp.dealershipUpdatePurchaseVehicle.__typename === "GraphQLError"
          ) {
            throw Error(
              resp.dealershipUpdatePurchaseVehicle?.message ?? undefined
            );
          }

          await queryClient.invalidateQueries({
            queryKey: ["transaction", transactionId],
          });

          toast.success("Successfully updated vehicle");

          navigate("/dashboard/transaction/:transactionId", {
            params: { transactionId },
          });
        } else {
          // No vehicle, we create one
          const resp = await gqlMutationClient()({
            dealershipCreatePurchaseVehicle: [
              {
                transactionId,
                vehicle: {
                  stockNumber: searchStockNumber,
                  vin: values.vin,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  trim: values.trim,
                  // It is safe to do this since mileage is required
                  mileage: parseFloat(values.mileage),
                  lifeCycleStage: values.lifeCycleStage,
                  principalPriorUse: values.principalPriorUse,
                  msrp:
                    values.msrp && values.msrp.length > 0
                      ? parseFloat(values.msrp)
                      : undefined,
                  bodyType: values.bodyType,
                },
              },
              {
                __typename: true,
                "...on GraphQLError": { message: true },
                "...on MutationDealershipCreatePurchaseVehicleSuccess": {
                  data: { status: true },
                },
              },
            ],
          });

          if (
            !resp.dealershipCreatePurchaseVehicle ||
            resp.dealershipCreatePurchaseVehicle.__typename === "GraphQLError"
          ) {
            throw Error(
              resp.dealershipCreatePurchaseVehicle?.message ?? undefined
            );
          }

          await queryClient.invalidateQueries({
            queryKey: ["transaction", transactionId],
          });

          toast.success("Successfully created vehicle");
          navigate("/dashboard/transaction/:transactionId", {
            params: { transactionId },
          });
        }
      } catch (e) {
        console.error(e);
        captureException(e);

        if (e instanceof Error && e.message) {
          toast.error(e.message);
        } else {
          toast.error("Failed to save vehicle data");
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const dealership = useAtomValue(dealershipAtom);
  const [searchStockNumber, setSearchStockNumber] = useState(
    vehicle?.stockNumber
  );
  const debouncedSearchString = useDebounce(searchStockNumber ?? "", 400);

  const { data, isLoading } = useQuery({
    queryKey: [
      "fetchPrefillVehicles",
      stringify(dealership?.activeDealershipPerms.dealershipId),
      stringify(searchStockNumber),
    ],
    queryFn: () =>
      g.reg.prefillVehicle.fetchCandidate(
        dealership?.activeDealershipPerms.dealershipId,
        debouncedSearchString
      ),
  });

  const vehicleOptions: TypeaheadOption<PrefillVehicleData>[] =
    data?.map((vehicle) => {
      return {
        key: vehicle?.stockNumber ?? vehicle?.id ?? "no-key",
        label: `${vehicle?.stockNumber}`,
        subtext: `${vehicle?.make} ${vehicle?.model} (${vehicle?.year}) - ${vehicle?.color}, ${vehicle?.bodyType}`,
        value: vehicle,
      };
    }) ?? [];

  const handleSelectStockNr = (
    selected: TypeaheadOption<PrefillVehicleData>
  ) => {
    const bodyType = vehicleBodyTypeOptions.find(
      (el) => el.label === selected.value.bodyType
    );
    const stockNumber = selected.value.stockNumber ?? "";

    form.setFieldValue("stockNumber", stockNumber);
    form.setFieldValue("vin", selected.value.vin ?? "");
    form.setFieldValue("msrp", selected.value.msrp ?? "");
    form.setFieldValue("make", selected.value.make ?? "");
    form.setFieldValue("model", selected.value.model ?? "");
    form.setFieldValue("year", selected.value.year ?? "");
    form.setFieldValue("color", selected.value.color ?? "");
    form.setFieldValue(
      "lifeCycleStage",
      selected.value.type
        ? selected.value.type.toUpperCase() === "USED"
          ? VehicleLifeCycleStage.IS_USED
          : VehicleLifeCycleStage.IS_NEW
        : undefined
    );
    form.setFieldValue("mileage", selected.value.mileage ?? "");
    form.setFieldValue("trim", selected.value.trim ?? "");
    form.setFieldValue(
      "bodyType",
      stringToVehicleBodyType(bodyType?.value ?? "")
    );
    form.setFieldValue("condition", undefined); // Condition is not part of the homenet data
    form.setFieldValue("principalPriorUse", undefined); // Principle prior use is not part of the homenet data: https://developer.homenetauto.com/#!/doc-gateway
    setSearchStockNumber(stockNumber);
  };

  return (
    <Fragment>
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transactionTitle}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Co-Buyer">
          <span>{t("Purchase Vehicle")}</span>
        </BreadCrumb>
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h2 className="text-heading-2">{t("Purchase Vehicle")}</h2>

          <form
            className="flex flex-col space-y-6"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <div className="grid grid-cols-2 gap-6 w-full">
              <div className="col-span-2 w-full">
                <div className="flex flex-row gap-4 w-full flex-grow">
                  <Typeahead
                    options={vehicleOptions}
                    onSelect={handleSelectStockNr}
                    onSearch={(searchString: string) => {
                      setSearchStockNumber(searchString);
                    }}
                    title="Enter Stock Number"
                    isLoading={isLoading}
                    selectedItemKey={vehicle?.stockNumber ?? undefined} // To preselect
                    disabled={false}
                    label="Stock Number"
                    placeHolder="E.g: J1232"
                    required={false}
                    assistiveMessage="Input vehicle stock number to start your search."
                  />
                </div>
              </div>
            </div>

            <VehicleForm
              form={form}
              vehicleType="PURCHASE"
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
            />
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default function PurchaseVehiclePage() {
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/vehicle/purchase"
  );
  const dealership = useAtomValue(dealershipAtom);
  const { data: transactionData, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );

  return (
    <div className="flex flex-col space-y-4">
      {!isLoading && transactionData?.transaction && (
        <VehiclePageContent
          transactionId={transactionId}
          transactionTitle={transactionData.transaction.title ?? undefined}
          vehicle={transactionData.transaction.vehicle}
        />
      )}
      {isLoading && (
        <div className="flex justify-center min-h-dvh">
          <Spinner />
        </div>
      )}
    </div>
  );
}
