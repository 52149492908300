import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

const selector = (dealershipId: string) => {
  return Selector("Query")({
    dealership: [
      { id: dealershipId },
      {
        name: true,
        hasEnabledComplianceForms: true,
        hasEnabledPostPurchaseDocs: true,
        hasEnabledChatBot: true,
        hasEnabledIdVerifOverAgility: true,
        hasEnabledReportingDashboard: true,
      },
    ],
  });
};

export default function dealershipQuery(dealershipId?: string) {
  return {
    enabled: Boolean(dealershipId),
    queryKey: ["dealership", dealershipId],
    queryFn: () => {
      return gqlQueryClient({ throwOnError: true })(
        selector(dealershipId ?? "never")
      );
    },
  };
}

export type DealershipQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof selector>
>;
