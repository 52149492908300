import {
  GraphQLTypes,
  InputType,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";

// Still not sure where should i put the query and other helpers
// Mig need to move it to more suitable place

const nodeSelector = Selector("HomenetVehicleData")({
  id: true,
  vin: true,
  make: true,
  model: true,
  year: true,
  mileage: true,
  transmission: true,
  fuelType: true,
  stockNumber: true,
  trim: true,
  type: true,
  color: true,
  bodyType: true,
  msrp: true,
});

const homenetDataSelector = (
  dealershipId: string,
  searchStockNumber: string
) => {
  return Selector("Query")({
    dealership: [
      {
        id: dealershipId,
      },
      {
        homenetVehicles: [
          {
            stockNumberSearchString: searchStockNumber,
            first: 10,
          },
          {
            edges: {
              node: nodeSelector,
            },
          },
        ],
      },
    ],
  });
};

export const fetchHomenetVehicleDataByStockNumber = (
  dealershipId?: string,
  searchStockNumber?: string
) => ({
  queryKey: ["homenetVehicleData", dealershipId, searchStockNumber],
  enabled: Boolean(dealershipId) && Boolean(searchStockNumber),
  queryFn: async () =>
    dealershipId && searchStockNumber
      ? gqlQueryClient()(homenetDataSelector(dealershipId, searchStockNumber))
      : undefined,
});

export type PrefillVehicleData = InputType<
  GraphQLTypes["HomenetVehicleData"],
  typeof nodeSelector
>;
