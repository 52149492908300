import { useQuery } from "@tanstack/react-query";
import { Banner } from "@thedealersconcierge/components";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import { getFormStatus } from "~/lib/formStatus";
import dealershipQuery from "~/query/dealershipQuery";
import meQuery from "~/query/meQuery";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import TransactionCoBuyerCard from "./_components/TransactionCoBuyerCard";
import TransactionCompliance from "./_components/TransactionCompliance";
import TransactionCredit from "./_components/TransactionCredit";
import TransactionCustomerPersonalInformationBar from "./_components/TransactionCustomerPersonalInformationBar";
import TransactionDealCard from "./_components/TransactionDealCard";
import TransactionDocuments from "./_components/TransactionDocuments";
import TransactionHistory from "./_components/TransactionHistory";
import TransactionPurchaseVehicle from "./_components/TransactionPurchaseVehicle";
import TransactionTradeVehicle from "./_components/TransactionTradeVehicle";
import { transactionQuery } from "./_queries/transactionQuery";

export default function TransactionPage() {
  const { t } = useTranslation();
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const dealership = useAtomValue(dealershipAtom);
  const { data, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const { data: dealershipData } = useQuery(
    dealershipQuery(dealership?.activeDealershipPerms.dealershipId)
  );
  const { data: meData } = useQuery(meQuery());

  const transaction = data?.transaction;
  const customer = transaction?.buyer;
  const formStatus = getFormStatus({
    prePurchaseCollection:
      transaction?.latestPrePurchaseCollection?.edges?.[0]?.node,
    postPurchaseCollection:
      transaction?.latestPostPurchaseCollection?.edges?.[0]?.node,
    latestTransactionLogThatShouldRetriggerComplianceDocs:
      transaction?.latestTransactionLogThatShouldRetriggerComplianceDocs
        ?.edges?.[0]?.node,
    hasEnabledPostPurchaseDocs: Boolean(
      dealershipData?.dealership?.hasEnabledPostPurchaseDocs
    ),
  });

  return (
    <>
      {isLoading && (
        <div className="flex relative flex-grow justify-center items-center">
          <Spinner />
        </div>
      )}

      {!isLoading && (
        <div
          className="flex flex-col space-y-8 max-h-dvh overflow-hidden"
          data-test-id={"page-transaction-loaded"}
        >
          <BreadCrumbsContainer>
            <BreadCrumb title="Transaction">
              <Link to={"/dashboard"}>{t("Transactions")}</Link>
            </BreadCrumb>

            <BreadCrumb title="User">
              <span>{transaction?.title}</span>
            </BreadCrumb>
          </BreadCrumbsContainer>

          {/**
           * Something is seriously wrong if there is no buyer on the transaction
           */}
          {!transaction?.buyerId && (
            <Banner
              type="ERROR"
              message={
                <span>{t("No buyer assigned to the transaction.")}</span>
              }
            />
          )}

          {/**
           * Check whether we have a compliance related change, such as a removed co-buyer, after compliance forms have been requested.
           * We show this prominently at the top of the transaction file to get maximum attention from dealership staff for the situation.
           */}
          {(formStatus.hasInvalidPrePurchaseForms ||
            formStatus.hasInvalidPostPurchaseForms) && (
            <Banner
              type="WARNING"
              message={
                <span>
                  Forms must be re-triggered and signed again due to transaction
                  changes. (
                  {[
                    formStatus.hasInvalidPrePurchaseForms
                      ? "Pre-Purchase"
                      : undefined,
                    formStatus.hasInvalidPostPurchaseForms
                      ? "Post-Purchase"
                      : undefined,
                  ]
                    .filter((f) => Boolean(f))
                    .join(", ")}
                  )
                </span>
              }
            />
          )}

          {/* Using grid for easier mobile optimization */}
          <div className="grid grid-cols-2 gap-4 overflow-y-scroll">
            <div className="col-span-2">
              {transaction && customer && (
                <TransactionCustomerPersonalInformationBar
                  isPrimaryBuyer={true}
                  transaction={transaction}
                />
              )}
            </div>

            <div className="col-span-2">
              {transaction && customer && (
                <TransactionDealCard transaction={transaction} />
              )}
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              {transaction && (
                <TransactionCredit
                  meData={meData}
                  transaction={transaction}
                  transactionRole="BUYER"
                />
              )}
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              {customer?.userId && (
                <TransactionCompliance
                  transactionId={transactionId}
                  userId={customer.userId}
                />
              )}
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              <TransactionCoBuyerCard
                transactionId={transactionId}
                coBuyerName={transaction?.customerSharedData?.coBuyerName ?? ""}
                coBuyer={transaction?.coBuyer}
              />
            </div>

            <div className="col-span-2 lg:col-span-1 h-full">
              {!!transaction && !!dealershipData?.dealership && (
                <TransactionDocuments
                  transactionId={transactionId}
                  transaction={transaction}
                />
              )}
            </div>

            <div className="col-span-2">
              <TransactionPurchaseVehicle />
            </div>

            {customer && (
              <div className="col-span-2 h-full">
                <TransactionTradeVehicle transaction={transaction} />
              </div>
            )}

            <div className="col-span-2 h-full">
              {transaction?.id && (
                <TransactionHistory
                  transactionId={transaction.id}
                  className="max-h-full overflow-y-scroll"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
