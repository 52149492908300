import { Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { ChatMessagePersistedSchema } from "@thedealersconcierge/lib/codecs/chat-messages";
import classNames from "classnames";
import markdownit from "markdown-it";
import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { siteMapForAi } from "~/lib/ai";
import { gqlMutationClient, gqlQueryClient } from "~/lib/backend";
const md = markdownit({
  typographer: true,
});

// Define types for our messages
type MessageSender = "user" | "bot";

interface Message {
  text: string;
  sender: MessageSender;
  timestamp?: Date;
  chatId: string;
}

interface Chat {
  id: string;
  title: string;
  messages: Message[];
  unreadCount: number;
  lastMessageTime?: Date;
}

interface ChatButtonProps {
  predefinedChats?: Chat[];
}

const MarkdownDiv: FC<{ markdown: string; className?: string }> = ({
  markdown,
  className,
}) => {
  return (
    <div
      className={classNames([className])}
      dangerouslySetInnerHTML={{
        __html: md.render(markdown),
      }}
    />
  );
};

const messageToText = (msg: ChatMessagePersistedSchema) => {
  // Not all messages are shown tot he user

  switch (msg?.type) {
    case "AI_MSG":
    case "USER_MSG":
    case "SUPPORT_MSG":
      return msg.message;
  }
};

/**
 * This is the Chat Button component
 *
 * NOTE: Do not use this component as a template anywhere else
 *
 * This is quickly done using AI. It does not adhere to the design guidelines and need an upgrade.
 *
 * For now this suffice because:
 *
 * 1. It is an experiment
 * 2. It is isolated from other parts of the application
 */
const ChatButton: React.FC<ChatButtonProps> = ({ predefinedChats = [] }) => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activeChatId, setActiveChatId] = useState<string | null>(null);
  const [showChatList, setShowChatList] = useState<boolean>(true);

  const msgBox = useRef<HTMLDivElement | null>(null);

  const allChats = useQuery({
    queryKey: ["all-chats"],
    queryFn: () => {
      return gqlQueryClient()({
        me: {
          chats: [
            { first: 50 },
            {
              edges: {
                node: {
                  id: true,
                  title: true,
                  chatMessages: [
                    { first: 2 },
                    {
                      totalCount: true,
                      edges: {
                        node: {
                          id: true,
                          createdAt: true,
                          message: true,
                        },
                      },
                    },
                  ],
                  createdAt: true,
                },
              },
            },
          ],
        },
      });
    },
  });

  const activeChat = useQuery({
    // This will be the technique to get messages, for now
    // In the future, if this feature gains traction, we'd probably do something
    // with graphql subscriptions here.
    refetchInterval: 1000,

    // And we only enable when an active chat is running to not use too much bandwidth
    enabled: Boolean(activeChatId),
    queryKey: ["chat", activeChatId],
    queryFn: () => {
      return gqlQueryClient()({
        me: {
          chats: [
            { first: 1, id: activeChatId },
            {
              edges: {
                node: {
                  title: true,
                  chatMessages: [
                    { first: 50 },
                    {
                      edges: {
                        node: {
                          id: true,
                          createdAt: true,
                          message: true,
                        },
                      },
                    },
                  ],
                  createdAt: true,
                },
              },
            },
          ],
        },
      });
    },
  });

  useEffect(() => {
    // Scroll to bottom on new messages
    msgBox.current?.scrollIntoView({ behavior: "smooth" });
  }, [
    activeChat.data?.me?.chats?.edges?.[0].node?.chatMessages?.edges?.[0].node
      ?.id,
  ]);

  const toggleChat = (): void => {
    setIsChatOpen(!isChatOpen);
    if (!isChatOpen) {
      setShowChatList(true);
      if (activeChatId) {
        clearUnreadCount(activeChatId);
      }
    }
  };

  const openChat = (chatId: string): void => {
    setActiveChatId(chatId);
    setShowChatList(false);
    clearUnreadCount(chatId);
  };

  const goBackToList = (): void => {
    setShowChatList(true);
    setActiveChatId(null);
  };

  const clearUnreadCount = (chatId: string): void => {
    console.log("Clear unread");
  };

  const location = useLocation();

  const addNewChat = async () => {
    const context = `The user is currently on the page: "${location.pathname}"

The sitemap is as follows:

${Object.entries(siteMapForAi())
  .map(([key, value]) => `"${key}": ${value}`)
  .join("\n")}
`;

    const newChatResponse = await gqlMutationClient()({
      createChat: [
        {
          initialContext: context,
        },
        {
          __typename: true,
          "...on GraphQLError": { message: true },
          "...on MutationCreateChatSuccess": {
            data: { status: true, id: true },
          },
        },
      ],
    });

    if (
      newChatResponse.createChat?.__typename === "MutationCreateChatSuccess" &&
      newChatResponse.createChat.data.id
    ) {
      console.log("newChatResponse", newChatResponse);
      openChat(newChatResponse.createChat.data.id);
      await allChats.refetch();
      await activeChat.refetch();
    }
  };

  const handleSendMessage = async (): Promise<void> => {
    if (!activeChatId || inputValue.trim() === "" || isSubmitting) return;

    setIsSubmitting(true);

    // Add user message
    const message = inputValue;
    setInputValue("");

    try {
      const messageObj: ChatMessagePersistedSchema = {
        type: "USER_MSG",
        message: message,
      };

      const sendMessageResponse = await gqlMutationClient()({
        createMessage: [
          {
            chatId: activeChatId,
            message: JSON.stringify(messageObj),
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationCreateMessageSuccess": {
              data: {
                status: true,
              },
            },
          },
        ],
      });

      if (sendMessageResponse.createMessage?.__typename === "GraphQLError") {
        throw new Error(
          sendMessageResponse.createMessage.message ?? "Some error"
        );
      }

      // Refetch for good measure
      await allChats.refetch();
      await activeChat.refetch();
      console.log("msgBox.current", msgBox.current);

      // TODO: Handle response
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Calculate total unread count for the badge
  const totalUnreadCount = 2;

  // Sort chats by last message time
  const sortedChats = allChats.data?.me?.chats?.edges ?? [];
  const sortedActiveChatMessages = [
    ...(activeChat.data?.me?.chats?.edges?.[0]?.node?.chatMessages?.edges ??
      []),
  ];

  // We reverse them as we show results bottom up. They come sorted from the server

  return (
    <div className="fixed bottom-6 right-6 flex flex-col items-end z-50">
      {/* Chat Interface */}
      <Transition
        show={isChatOpen}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
        className="mb-4"
      >
        <div className="bg-white rounded-lg shadow-xl w-80 sm:w-96 flex flex-col overflow-hidden border border-gray-300 h-96 max-h-[80vh]">
          {/* Chat Header */}
          <div className="bg-primary-blue text-white p-4 flex justify-between items-center">
            <h3 className="font-medium">
              {showChatList
                ? "Your Conversations"
                : activeChat.data?.me?.chats?.edges?.[0].node?.title}
            </h3>
            <div className="flex items-center space-x-2">
              {!showChatList && (
                <button
                  onClick={goBackToList}
                  className="text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-blue-500 rounded-full p-1"
                  aria-label="Back to chat list"
                >
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
              )}
              {showChatList && (
                <button
                  onClick={addNewChat}
                  className="text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-blue-500 rounded-full p-1"
                  aria-label="New chat"
                >
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </button>
              )}
              <button
                onClick={toggleChat}
                className="text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-blue-500 rounded-full p-1"
                aria-label="Close chat"
              >
                <svg
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          {/* Chat List View */}
          {showChatList && (
            <div className="flex-1 overflow-y-auto">
              {sortedChats.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full p-6 text-center">
                  <svg
                    className="h-12 w-12 text-gray-400 mb-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                    />
                  </svg>
                  <p className="text-gray-600 mb-4">No conversations yet</p>
                  <button
                    onClick={addNewChat}
                    className="px-4 py-2 bg-primary-blue text-white rounded-md hover:bg-blue-500 transition"
                  >
                    Start a new conversation
                  </button>
                </div>
              ) : (
                <ul className="divide-y divide-gray-200">
                  {sortedChats.map((chat) => {
                    const latestMessage =
                      ChatMessagePersistedSchema.optional().parse(
                        chat.node?.chatMessages?.edges?.[0]?.node?.message
                      );

                    const latestMessageText = latestMessage
                      ? messageToText(latestMessage)
                      : undefined;

                    return (
                      <li key={chat.node?.id}>
                        <button
                          onClick={() => openChat(chat.node?.id ?? "")}
                          className="w-full p-4 flex items-start hover:bg-gray-50 transition focus:outline-none focus:bg-gray-50"
                        >
                          <div className="flex-1 text-left">
                            <div className="flex justify-between items-center mb-1">
                              <h4 className="font-medium text-gray-800">
                                {chat.node?.title}
                              </h4>
                              {chat.node?.chatMessages?.edges?.[0].node
                                ?.createdAt && (
                                <span className="text-xs text-gray-500">
                                  {chat.node?.chatMessages?.edges?.[0].node?.createdAt.toLocaleDateString(
                                    undefined,
                                    {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              )}
                            </div>
                            <p className="text-sm text-gray-500 truncate">
                              {latestMessageText
                                ? latestMessageText
                                : "No messages yet"}
                            </p>
                          </div>

                          {/* Unread count */}
                          {2 > 0 && (
                            <span className="ml-2 bg-primary-blue text-white text-xs font-bold rounded-full h-5 min-w-[20px] flex items-center justify-center px-1">
                              {2}
                            </span>
                          )}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          {/* Chat Messages View */}
          {!showChatList && activeChat && (
            <div
              className="flex-1 p-4 overflow-y-auto space-y-3"
              role="log"
              aria-live="polite"
            >
              {sortedActiveChatMessages.length === 0 ? (
                <p className="text-gray-500 text-center text-sm">
                  How can we help you today?
                </p>
              ) : (
                sortedActiveChatMessages.reverse().map((msg, index) => {
                  const message = ChatMessagePersistedSchema.optional().parse(
                    msg.node?.message
                  );

                  return (
                    <div
                      key={index}
                      className={`flex ${message?.type === "USER_MSG" ? "justify-end" : "justify-start"}`}
                    >
                      <div
                        className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                          message?.type === "USER_MSG"
                            ? "bg-primary-blue text-white rounded-br-none"
                            : "bg-gray-200 text-gray-900 rounded-bl-none"
                        }`}
                      >
                        <MarkdownDiv
                          className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                            message?.type === "USER_MSG"
                              ? "bg-primary-blue text-white rounded-br-none"
                              : "bg-gray-200 text-gray-900 rounded-bl-none"
                          }`}
                          markdown={
                            message ? (messageToText(message) ?? "") : ""
                          }
                        />

                        {msg.node?.createdAt && (
                          <div
                            className={`text-xs mt-1 ${message?.type === "USER_MSG" ? "text-white" : "text-gray-700"}`}
                          >
                            {msg.node?.createdAt.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
              <div ref={msgBox}></div>
            </div>
          )}

          {/* Chat Input (only show when in a chat) */}
          {!showChatList && activeChat && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSendMessage();
              }}
              className="border-t border-gray-300 p-4"
            >
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInputValue(e.target.value)
                  }
                  placeholder="Type your message..."
                  className="flex-1 border border-gray-400 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-blue focus:border-transparent text-gray-900 bg-white"
                  disabled={isSubmitting}
                  aria-label="Message"
                />
                <button
                  type="submit"
                  className={`bg-primary-blue text-white rounded-full p-2 focus:outline-none hover:bg-indigo-800 transition ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
                  disabled={isSubmitting}
                  aria-label="Send message"
                >
                  {isSubmitting ? (
                    <svg
                      className="animate-spin h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </Transition>

      {/* Chat Button with Better Styling */}
      <button
        onClick={toggleChat}
        className="relative bg-primary-blue text-white rounded-full w-14 h-14 flex items-center justify-center shadow-xl
                   border-2 border-white outline outline-2 outline-gray-700
                   hover:bg-indigo-800 hover:outline-indigo-900
                   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all"
        aria-label={isChatOpen ? "Close chat" : "Open chat"}
        aria-expanded={isChatOpen}
      >
        {totalUnreadCount > 0 && !isChatOpen && (
          <span className="absolute -top-1 -right-1 bg-red-600 text-white text-xs font-bold rounded-full h-6 w-6 flex items-center justify-center">
            {totalUnreadCount > 9 ? "9+" : totalUnreadCount}
          </span>
        )}

        {isChatOpen ? (
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default ChatButton;
