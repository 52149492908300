import { Tag } from "@thedealersconcierge/components";
import MonthlyProgress from "./MonthlyProgress";
import PlainCard from "./PlainCard";

const getOrdinal = (n: number): string => {
  const j = n % 10;
  const k = n % 100;
  if (j === 1 && k !== 11) return n + "st";
  if (j === 2 && k !== 12) return n + "nd";
  if (j === 3 && k !== 13) return n + "rd";
  return n + "th";
};

export default function RankingCard({
  firstName,
  lastName,
  thisMonth,
  target,
  ranking,
  lastMonth,
  last2Month,
  ordinalNumber,
}: {
  firstName: string;
  lastName: string;
  thisMonth: number;
  target: number;
  ranking: number;
  lastMonth?: number;
  last2Month?: number;
  ordinalNumber: number;
}) {
  return (
    <PlainCard className="p-spacing-03 flex flex-row items-center w-full border-none">
      <div className="gap-spacing-03 flex flex-row items-center w-full">
        {/* Display ranking and, if applicable, the "faster" logo */}
        <div className="mr-spacing-02 relative inline-block">
          <Tag
            label={getOrdinal(ordinalNumber)}
            color="GRAY"
            className="w-10"
          />
        </div>

        <div className="flex flex-col w-[140px]">
          <p>{firstName}</p>
          <p>{lastName}</p>
        </div>

        <div className="flex flex-col w-full">
          <MonthlyProgress
            current={thisMonth}
            target={target}
            type="THIS_MONTH"
          />
          <MonthlyProgress
            current={lastMonth ?? 0}
            target={target}
            type="LAST_MONTH"
          />
          <MonthlyProgress
            current={last2Month ?? 0}
            target={target}
            type="LAST_2_MONTH"
          />
        </div>
      </div>

      <div style={{ height: 44, width: 44 }}>
        {ranking === 1 && <p className="text-4xl">🥇</p>}
        {ranking === 2 && <p className="text-4xl">🥈</p>}
        {ranking === 3 && <p className="text-4xl">🥉</p>}
      </div>
    </PlainCard>
  );
}
