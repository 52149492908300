import { useForm } from "@tanstack/react-form";
import { zodValidator, ZodValidator } from "@tanstack/zod-form-adapter";
import {
  Button,
  PhoneNumberInput,
  Select,
  TextInput,
} from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { email } from "@thedealersconcierge/lib/codecs/validation/email";
import { phoneNumber } from "@thedealersconcierge/lib/codecs/validation/phonenumber";
import { getFormFieldErrorMsg } from "@thedealersconcierge/lib/utils/forms";
import { TFunction } from "i18next";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { z } from "zod";
import Modal from "~/components/Modal";
import staffRoleOptions from "~/config/formSelectionOptions/staffRoleOptions";
import { gqlMutationClient } from "~/lib/backend";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";

type CreateStaffForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  role: string;
};

/**
 * This is done to reduce the redudancy between inline validator and submit validator
 */
const getValidatorAttributes = (t: TFunction) => {
  return {
    firstName: z.string().min(1, t("First Name is required")),
    lastName: z.string().min(1, t("Last Name is required")),
    phoneNumber: phoneNumber(),
    email: email(),
    role: z.string().min(1, t("Role is required")),
  };
};

export default function CreatePage() {
  const dealership = useAtomValue(dealershipAtom);
  const { t } = useTranslation();

  const canManageUser = dealership?.activeDealershipPerms
    ? permissionChecker("manageUsers", dealership.activeDealershipPerms)
    : false;

  const { close } = useModals();
  const navigate = useNavigate();

  const validatorAttributes = getValidatorAttributes(t);
  const ValidFormSchema = z.object(validatorAttributes);

  const form = useForm<CreateStaffForm, ZodValidator>({
    // This is create form so empty values is expected as default
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      role: "",
    },
    validatorAdapter: zodValidator(),
    validators: { onSubmit: ValidFormSchema },
    onSubmit: async ({ value }) => {
      if (!canManageUser) {
        toast.error(t("You don't have permissions to create users"));
        return;
      }
      if (!dealership?.activeDealershipPerms.dealershipId) {
        toast.error(t("You are not logged in"));
        return;
      }

      const createStaffResponse = await gqlMutationClient()({
        createStaff: [
          {
            dealershipId: dealership.activeDealershipPerms.dealershipId,
            user: {
              firstName: value.firstName,
              lastName: value.lastName,
              phoneNumber: value.phoneNumber,
              email: value.email,
              role: value.role,
            },
          },
          {
            __typename: true,
            "...on GraphQLError": { message: true },
            "...on MutationCreateStaffSuccess": { data: { id: true } },
          },
        ],
      });

      if (
        createStaffResponse.createStaff?.__typename ===
        "MutationCreateStaffSuccess"
      ) {
        toast.success(t("New user created"));
        navigate("/dashboard/staff/:userId", {
          params: {
            userId: createStaffResponse.createStaff.data.id ?? "no-user-id",
          },
        });
      } else {
        toast.error(createStaffResponse.createStaff?.message);
      }
    },
  });

  return (
    <Modal
      title="Create New User"
      containerClassName="w-1/2"
      isOpen
      onClose={close}
    >
      {canManageUser ? (
        <div
          className="p-10 flex flex-col h-full"
          data-test-id="create-new-user-modal"
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
            className="flex flex-col space-y-8 h-full"
          >
            <h2 className="text-heading-2 ml-2">Enter Staff Information</h2>
            <div className="flex flex-col space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <form.Field
                  name="firstName"
                  validators={{ onBlur: validatorAttributes.firstName }}
                >
                  {(field) => (
                    <TextInput
                      dataTestId="staff-first-name-input"
                      name={field.name}
                      value={field.state.value}
                      label={t("First Name")}
                      placeholder={t("First Name")}
                      required
                      disabled={false}
                      onChange={field.handleChange}
                      backgroundType="LIGHT"
                      errorMessage={getFormFieldErrorMsg(field)}
                    />
                  )}
                </form.Field>
                <form.Field
                  name="lastName"
                  validators={{ onBlur: validatorAttributes.lastName }}
                >
                  {(field) => (
                    <TextInput
                      dataTestId="staff-last-name-input"
                      name={field.name}
                      value={field.state.value}
                      label={t("Last Name")}
                      placeholder={t("Last Name")}
                      required
                      disabled={false}
                      onChange={field.handleChange}
                      backgroundType="LIGHT"
                      errorMessage={getFormFieldErrorMsg(field)}
                    />
                  )}
                </form.Field>
              </div>

              <form.Field
                name="phoneNumber"
                validators={{ onBlur: validatorAttributes.phoneNumber }}
              >
                {(field) => (
                  <PhoneNumberInput
                    dataTestId="staff-phone-input"
                    name={field.name}
                    value={field.state.value}
                    placeholder={t("Phone Number")}
                    label={t("Phone Number")}
                    onChange={field.handleChange}
                    required
                    disabled={false}
                    backgroundType="LIGHT"
                    errorMessage={getFormFieldErrorMsg(field)}
                  />
                )}
              </form.Field>

              <form.Field
                name="email"
                validators={{ onBlur: validatorAttributes.email }}
              >
                {(field) => (
                  <TextInput
                    dataTestId="staff-email-input"
                    name={field.name}
                    value={field.state.value}
                    label={t("Email")}
                    placeholder={t("Email")}
                    required
                    disabled={false}
                    onChange={field.handleChange}
                    backgroundType="LIGHT"
                    errorMessage={getFormFieldErrorMsg(field)}
                  />
                )}
              </form.Field>

              <form.Field
                name="role"
                validators={{ onBlur: validatorAttributes.role }}
              >
                {(field) => (
                  <Select
                    options={staffRoleOptions}
                    value={field.state.value}
                    label="Role"
                    placeholder="Role"
                    required
                    disabled={false}
                    onSelect={(option) => {
                      field.handleChange(option.value);
                    }}
                    dataTestId="staff-role-input"
                    backgroundType="LIGHT"
                    errorMessage={getFormFieldErrorMsg(field)}
                  />
                )}
              </form.Field>
            </div>

            <div className="flex flex-row justify-between">
              <Button
                variant="SECONDARY"
                onClick={() => {
                  close();
                }}
                dataTestId="staff-create-cancel-button"
                label={t("Cancel")}
              />

              <Button
                type="submit"
                disabled={!canManageUser}
                dataTestId="staff-create-submit-button"
                label={t("Create User")}
                onClick={() => {
                  void form.handleSubmit();
                }}
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="p-10 flex flex-col h-full">
          {t("You don't have permission to create user!")}
        </div>
      )}
    </Modal>
  );
}
