import {
  DashboardUserRole,
  FinanceType,
  TransactionSource,
  TransactionStatusSchema,
} from "@thedealersconcierge/lib/codecs/tdc";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import { z } from "zod";
import { VehicleLifeCycleStage } from "./schema/vehicle";

/**
 * Deprecated
 *
 * We don't use this as
 * 1. We can not derive statistics from the log using this.
 * 2. We can not translate it
 *
 * This is here to support earlier log events, that we do not use anymore.
 */
const TextEventLogPersistedSchema = z.object({
  type: z.literal("TEXT"),
  text: z.string(),
});

/**
 * Deprecated, Maybe
 *
 * This is really broad, and we can't really derive any valuable statistics
 * from it. so I think it needs to be deprecated.
 */
const UpdatedValuesTypeLogEventPersistedSchema = z.object({
  type: z.literal("UPDATED_VALUES"),

  changes: z
    .object({ key: z.string(), old: z.string().nullable(), new: z.string() })
    .array(),
});
export type UpdatedValuesTypeLogEventPersistedSchema = z.TypeOf<
  typeof UpdatedValuesTypeLogEventPersistedSchema
>;

const UpdateFinanceTypeLogEventPersistedSchema = z.object({
  type: z.literal("UPDATED_FINANCE_TYPE"),

  // String values are chosen here to loosen the type a bit
  // The information is more for auditing.
  // For most cases it should be parsable using regular finance type schema
  // with a fallback to the concrete value
  previous: z.string().nullable(),
  new: z.string(),
});
export type UpdateFinanceTypeLogEventPersistedSchema = z.TypeOf<
  typeof UpdateFinanceTypeLogEventPersistedSchema
>;

const SwitchBuyerAndCoBuyerEventPersistedSchema = z.object({
  type: z.literal("SWITCH_BUYER_AND_CO_BUYER"),

  // For the description
  oldBuyerFullName: z.string().nullable(),
  oldCoBuyerFullName: z.string().nullable(),

  // For auditing
  oldBuyerId: z.string().uuid(),
  oldCoBuyerId: z.string().uuid(),
});
export type SwitchBuyerAndCoBuyerEventPersistedSchema = z.TypeOf<
  typeof SwitchBuyerAndCoBuyerEventPersistedSchema
>;

const AssignedBuyerLogEventPersistedSchema = z.object({
  type: z.literal("ASSIGNED_BUYER"),

  // For the description
  fullName: z.string().nullable(),

  // For auditing
  userId: z.string().uuid(),
});
export type AssignedBuyerLogEventPersistedSchema = z.TypeOf<
  typeof AssignedBuyerLogEventPersistedSchema
>;

const UpdatePurchaseVehicleEventPersistedSchema = z.object({
  type: z.literal("UPDATED_PURCHASE_VEHICLE"),

  // For the description
  vehicleId: z.string().uuid(),
});
export type UpdatePurchaseVehicleEventPersistedSchema = z.TypeOf<
  typeof UpdatePurchaseVehicleEventPersistedSchema
>;

const UpdateTradeVehicleEventPersistedSchema = z.object({
  type: z.literal("UPDATED_TRADE_VEHICLE"),

  // For the description
  vehicleId: z.string().uuid(),
});
export type UpdateTradeVehicleEventPersistedSchema = z.TypeOf<
  typeof UpdateTradeVehicleEventPersistedSchema
>;

const RemoveTradeVehicleEventPersistedSchema = z.object({
  type: z.literal("REMOVED_TRADE_VEHICLE"),

  // For the description
  removedVehicleId: z.string().uuid(),
});
export type RemoveTradeVehicleEventPersistedSchema = z.TypeOf<
  typeof RemoveTradeVehicleEventPersistedSchema
>;

const AddedTradeVehicleEventPersistedSchema = z.object({
  type: z.literal("ADDED_TRADE_VEHICLE"),

  // For the description
  vehicleId: z.string().uuid(),
});
export type AddedTradeVehicleEventPersistedSchema = z.TypeOf<
  typeof AddedTradeVehicleEventPersistedSchema
>;

const UnAssignedBuyerLogEventPersistedSchema = z.object({
  type: z.literal("UNASSIGNED_BUYER"),

  // For the description
  fullName: z.string().nullable(),

  // For auditing
  userId: z.string().uuid(),
});
export type UnAssignedBuyerLogEventPersistedSchema = z.TypeOf<
  typeof UnAssignedBuyerLogEventPersistedSchema
>;

const AssignedCoBuyerLogEventPersistedSchema = z.object({
  type: z.literal("ASSIGNED_CO_BUYER"),

  // For the description
  fullName: z.string().nullable(),

  // For auditing
  userId: z.string().uuid(),
});
export type AssignedCoBuyerLogEventPersistedSchema = z.TypeOf<
  typeof AssignedCoBuyerLogEventPersistedSchema
>;

const UnAssignedCoBuyerLogEventPersistedSchema = z.object({
  type: z.literal("UNASSIGNED_CO_BUYER"),

  // For the description
  fullName: z.string().nullable(),

  // For auditing
  userId: z.string().uuid(),
});
export type UnAssignedCoBuyerLogEventPersistedSchema = z.TypeOf<
  typeof UnAssignedCoBuyerLogEventPersistedSchema
>;

const ReassignedCoBuyerLogEventPersistedSchema = z.object({
  type: z.literal("REASSIGNED_CO_BUYER"),

  // For the description
  fullName: z.string().nullable(),

  // For auditing
  userId: z.string().uuid(),
});
export type ReassignedCoBuyerLogEventPersistedSchema = z.TypeOf<
  typeof ReassignedCoBuyerLogEventPersistedSchema
>;

const InitializedLogEventPersistedSchema = z.object({
  type: z.literal("INITIALIZED_TRANSACTION"),
});
export type InitializedLogEventPersistedSchema = z.TypeOf<
  typeof InitializedLogEventPersistedSchema
>;

const UpdateStatusLogEventPersistedSchema = z.object({
  type: z.literal("UPDATED_STATUS"),

  // While using .optional is more space efficient, using null forces
  // the person creating the events to think about the values.
  // We use null to avoid a situation where someone forgets to set it.
  buyerId: z.string().nullable(),
  coBuyerId: z.string().nullable(),

  previous: z.string().nullable(),
  new: z.string(),

  // For analytics:
  salesPersonId: z.string().nullable(),
  salesManagerId: z.string().nullable(),
  fniManagerId: z.string().nullable(),
  bdcId: z.string().nullable(),
  dealershipId: z.string().nullish(),
  isLeaseBuyOut: z.boolean().nullish(),
  source: TransactionSource.nullish(),
  financeType: FinanceType.nullish(),
  purchaseVehicleLifeCycleStage: VehicleLifeCycleStage.nullish(),
});
export type UpdateStatusLogEventPersistedSchema = z.TypeOf<
  typeof UpdateStatusLogEventPersistedSchema
>;

const AssignStaffLogEventPersistedSchema = z.object({
  type: z.literal("ASSIGN_STAFF"),

  role: z.string(),

  // For display
  fullName: z.string().nullable(),

  // For Auditing
  assigneeUserId: z.string().uuid(),
});
export type AssignStaffLogEventPersistedSchema = z.TypeOf<
  typeof AssignStaffLogEventPersistedSchema
>;

const StageDocumentLogEventPersistedSchema = z.object(
  {
    type: z.literal("STAGED_DOCUMENT"),

    // For description
    title: z.string(),
    collectionName: z.string(),

    // For auditing
    submissionId: z.string().uuid(),
    collectionId: z.string().uuid(),
  },
  {
    description:
      "This event occurs when a document is added for signature on a transaction.",
  }
);
export type StageDocumentLogEventPersistedSchema = z.TypeOf<
  typeof StageDocumentLogEventPersistedSchema
>;

const FinishedOnboardingStepEventPersistedSchema = z.object({
  type: z.literal("FINISHED_ONBOARDING_STEP"),
  step: z.string(),
});
export type FinishedOnboardingStepEventPersistedSchema = z.TypeOf<
  typeof FinishedOnboardingStepEventPersistedSchema
>;

const ProvisionedFileLogEventPersistedSchema = z.object(
  {
    type: z.literal("PROVISIONED_FILE"),

    source: z.literal("DASHBOARD"),

    // For auditing
    fileId: z.string().uuid(),
  },
  {
    description: "A document was added to the transaction.",
  }
);
type ProvisionedFileLogEventPersistedSchema = z.TypeOf<
  typeof ProvisionedFileLogEventPersistedSchema
>;

const AddedDocumentLogEventPersistedSchema = z.object(
  {
    type: z.literal("ADDED_DOCUMENT"),

    // For description
    title: z.string(),

    // For auditing
    documentId: z.string().uuid(),
    fileId: z.string().uuid(),
  },
  {
    description: "A document was added to the customer.",
  }
);
type AddedDocumentLogEventPersistedSchema = z.TypeOf<
  typeof AddedDocumentLogEventPersistedSchema
>;

const AddedIdEventPersistedSchema = z.object(
  {
    type: z.literal("ADDED_ID"),

    // For auditing
    idCardId: z.string().uuid(),
  },
  {
    description: "An id was added to the customer.",
  }
);
type AddedIdEventPersistedSchema = z.TypeOf<typeof AddedIdEventPersistedSchema>;

const FailedCdkCrmPushPersistedSchema = z.object(
  {
    type: z.literal("FAILED_CDK"),

    // For description
    reason: z.union([
      z.literal("InvalidAddressLocation"),
      z.literal("InvalidEmail"),
    ]),
  },
  {
    description: "Failed pushing to CDK CRM.",
  }
);
export type FailedCdkCrmPushPersistedSchema = z.TypeOf<
  typeof FailedCdkCrmPushPersistedSchema
>;

const FailedPrequalApplicationPersistedSchema = z.object(
  {
    type: z.literal("FAILED_700_CREDIT"),

    // For description
    reason: z.union([z.literal("INVALID_DOB"), z.literal("INVALID_ZIP")]),
  },
  {
    description: "Failed retrieving a prequal.",
  }
);

export const TransactionLogEventPersistedSchema = z.union([
  // The items are here for legacy reasons, and should ideally not
  // be used
  TextEventLogPersistedSchema,
  UpdatedValuesTypeLogEventPersistedSchema,

  AddedTradeVehicleEventPersistedSchema,
  UpdatePurchaseVehicleEventPersistedSchema,
  UpdateTradeVehicleEventPersistedSchema,
  RemoveTradeVehicleEventPersistedSchema,
  StageDocumentLogEventPersistedSchema,
  AssignStaffLogEventPersistedSchema,
  SwitchBuyerAndCoBuyerEventPersistedSchema,
  InitializedLogEventPersistedSchema,
  AssignedCoBuyerLogEventPersistedSchema,
  UnAssignedCoBuyerLogEventPersistedSchema,
  ReassignedCoBuyerLogEventPersistedSchema,
  AssignedBuyerLogEventPersistedSchema,
  UnAssignedBuyerLogEventPersistedSchema,
  UpdateFinanceTypeLogEventPersistedSchema,
  UpdateStatusLogEventPersistedSchema,
  FailedCdkCrmPushPersistedSchema,
  FailedPrequalApplicationPersistedSchema,
  FinishedOnboardingStepEventPersistedSchema,

  // User related
  AddedIdEventPersistedSchema,
  ProvisionedFileLogEventPersistedSchema,
  AddedDocumentLogEventPersistedSchema,
  FinishedOnboardingStepEventPersistedSchema,
]);
export type TransactionLogEventPersistedSchema = z.TypeOf<
  typeof TransactionLogEventPersistedSchema
>;

export const LogEventPersistedSchema = TransactionLogEventPersistedSchema;
export type LogEventPersistedSchema = z.TypeOf<typeof LogEventPersistedSchema>;

export const logToDesc = (l: LogEventPersistedSchema) => {
  switch (l.type) {
    case "INITIALIZED_TRANSACTION":
      return "Initiated transaction";
    case "UPDATED_VALUES":
      // The deal info part should be optional
      return "Transaction was updated (Deal information)";
    case "UPDATED_STATUS": {
      const newStatus = TransactionStatusSchema.safeParse(l.new);
      return `Transaction status changed to '${newStatus.success ? getReadableTransactionStatus(newStatus.data) : "unknown status"}'`;
    }
    case "UNASSIGNED_BUYER":
      return `Removed buyer (${l.fullName})`;
    case "UNASSIGNED_CO_BUYER":
      return `Removed co-buyer (${l.fullName})`;

    case "SWITCH_BUYER_AND_CO_BUYER":
      return `Switched buyer and co-buyer (new buyer: ${l.oldCoBuyerFullName}) (new co-buyer: ${l.oldBuyerFullName})`;

    case "REASSIGNED_CO_BUYER":
      return `Reassigned co-buyer (${l.fullName}), previously was removed`;

    case "ASSIGN_STAFF":
      return `Assign ${getReadableRole(l.role)} to ${l.fullName}`;

    case "ASSIGNED_BUYER":
      return `Assigned buyer ${l.fullName ? `to ${l.fullName}` : ""}`;
    case "ASSIGNED_CO_BUYER":
      return `Assigned co-buyer ${l.fullName ? `to ${l.fullName}` : ""}`;

    case "UPDATED_FINANCE_TYPE":
      return "Updated finance type";

    case "ADDED_DOCUMENT":
      return `Added document ${l.title}`;

    case "ADDED_TRADE_VEHICLE":
      return "Added trade vehicle";

    case "FINISHED_ONBOARDING_STEP":
      return `Finished onboarding step: ${l.step}`;

    case "PROVISIONED_FILE":
      return "Provisioned file";

    case "STAGED_DOCUMENT":
      return `Send document "${l.title}" for signature as ${l.type}`;

    case "UPDATED_PURCHASE_VEHICLE":
      return "Updated purchase vehicle";

    case "UPDATED_TRADE_VEHICLE":
      return "Updated trade vehicle";

    case "ADDED_ID":
      return "Added ID";

    case "FAILED_CDK":
      return `Failed pushing data to CDK CRM due to: ${l.reason}`;

    case "FAILED_700_CREDIT":
      return `Failed fetching prequal due to: ${l.reason}`;

    case "TEXT":
      return l.text;
  }
};

/**
 * For now, we transform the log event into a string and serve that.
 *
 * In the future, we need to provide the events in a more raw format to allow for translations
 */
export const transLogToDesc = logToDesc;

/**
 * Temporarily added here, I don't know it this is ideal
 *
 * @param role
 * @returns
 */
export const getReadableRole = (role?: string | DashboardUserRole) => {
  if (!role) return;
  switch (role) {
    case "FNI_MANAGER":
      return "F&I manager";
    case "SALES_MANAGER":
      return "sales manager";
    case "SALES_PERSON":
      return "sales person";
    case "ADMIN":
      return "admin";
    case "BDC":
      return "BDC";
    default:
      return role;
  }
};
