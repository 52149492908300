import { ReactElement, useCallback, useState } from 'react';
import ConfirmationModal from './index';

/**
 * useConfirmationModal hook
 *
 * This hook allows you to show the modal and return a Promise that resolves with
 * true if confirmed or false if canceled.
 *
 *
 * useConfirmationModal - Usage Example:
 *
 * 1. Initialize the hook within your component
 *    Make sure to deconstruct the "showWarningModal" and "ConfirmationModalComponent":
 *
 *    const { showWarningModal, ConfirmationModalComponent } = useConfirmationModal();
 *
 * 2. Render the `ConfirmationModalComponent` in your component's JSX (it can be anywhere):
 *    return (
 *      <div>
 *        <button onClick={handleDelete}>Delete Item</button>
 *        {ConfirmationModalComponent}
 *      </div>
 *    );
 *
 * 3. Use the `showWarningModal` function to display the Warning modal and get the value back as promise
 *    const confirmed = await showWarningModal({
 *        message: "Are you sure to proceed with this action?"
 *    });
 */
const useConfirmationModal = () => {
  const [visible, setVisible] = useState(false);
  const [resolveCallback, setResolveCallback] = useState<
    ((value: boolean) => void) | null
  >(null);
  const [message, setMessage] = useState<string | ReactElement>('');

  // Default testId is set for better DX because it is almost impossible for two modal is opened and exists and same screen
  const [dataTestId, setDataTestId] = useState<string>('confirmation-modal');

  const showWarningModal = useCallback(
    ({
      message,
      dataTestId
    }: {
      message: string | ReactElement;
      dataTestId?: string;
    }): Promise<boolean> => {
      setMessage(message);
      if (dataTestId) setDataTestId(dataTestId);
      setVisible(true);

      return new Promise<boolean>((resolve) => {
        setResolveCallback(() => resolve);
      });
    },
    []
  );

  const ConfirmationModalComponent: React.ReactElement = (
    <ConfirmationModal
      message={message}
      onConfirm={() => {
        setVisible(false);
        if (resolveCallback) resolveCallback(true);
      }}
      onClose={() => {
        setVisible(false);
        if (resolveCallback) resolveCallback(false);
      }}
      isOpen={visible}
      dataTestId={dataTestId}
    />
  );

  return { showWarningModal, ConfirmationModalComponent };
};

export default useConfirmationModal;
