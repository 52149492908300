import { FormApi, useForm } from "@tanstack/react-form-old";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import Button from "~/components/Button";
import Header from "~/components/Header";
import Modal from "~/components/Modal";
import { gqlMutationClient } from "~/lib/backend";
import meQuery from "~/query/meQuery";
import { useModals, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";
import UserDropDownField from "./_components/UserDropDownField";

type FormType = {
  salesPersonId: string;
  salesManagerId: string;
  fniManagerId: string;
  bdcId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const Step1: FC<{
  form: FormApi<FormType, unknown>;
  onNext: () => void;
}> = ({ form, onNext }) => {
  const dealership = useAtomValue(dealershipAtom);

  const dealershipId = dealership?.activeDealershipPerms.dealershipId;

  return (
    <div className="flex flex-col space-y-4 h-full">
      <div className="text-heading-2 mb-4">Step 1: Staff</div>
      <div className="flex flex-col flex-grow">
        <form.Field name="salesPersonId">
          {(field) => {
            return (
              <UserDropDownField
                dealershipId={dealershipId}
                fieldName={field.name}
                value={field.state.value}
                labeltext="Sales Person"
                handleChange={field.handleChange}
                roleToDisplay={"SALES_PERSON"}
              />
            );
          }}
        </form.Field>

        <form.Field name="salesManagerId">
          {(field) => {
            return (
              <UserDropDownField
                dealershipId={dealershipId}
                fieldName={field.name}
                value={field.state.value}
                labeltext="Sales Manager"
                handleChange={field.handleChange}
                roleToDisplay={"SALES_MANAGER"}
              />
            );
          }}
        </form.Field>

        <form.Field name="fniManagerId">
          {(field) => {
            return (
              <UserDropDownField
                dealershipId={dealershipId}
                fieldName={field.name}
                value={field.state.value}
                labeltext="F&I Manager"
                handleChange={field.handleChange}
                roleToDisplay={"FNI_MANAGER"}
              />
            );
          }}
        </form.Field>

        <form.Field name="bdcId">
          {(field) => {
            return (
              <UserDropDownField
                dealershipId={dealershipId}
                fieldName={field.name}
                value={field.state.value}
                labeltext="BDC"
                handleChange={field.handleChange}
                roleToDisplay={"BDC"}
              />
            );
          }}
        </form.Field>
      </div>

      <div className="flex flex-row space-x-4 justify-end">
        <Button
          dataTestId="create-transaction-next"
          onClick={onNext}
          type="button"
          className="bg-blue-500 hover:bg-blue-600 disabled:bg-[#C4C4C4] rounded-lg py-2 px-10 text-white"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const Step2: FC<{ back: () => void; form: FormApi<FormType, unknown> }> = ({
  back,
  form,
}) => {
  return (
    <div className="flex flex-col space-y-4 h-full">
      <div className="text-heading-2 mb-4">Step 2: Customer</div>
      <div className="flex flex-col w-full space-y-6 flex-grow">
        <div>
          <form.Field
            name="firstName"
            onChange={(v) => (!v ? "First name is required" : undefined)}
            children={(field) => {
              // Avoid hasty abstractions. Render props are great!
              return (
                <>
                  <label htmlFor={field.name} className="hidden">
                    First Name
                  </label>
                  <input
                    data-test-id="create-transaction-first-name"
                    placeholder="First Name"
                    name={field.name}
                    value={field.state.value ?? ""}
                    onBlur={field.handleBlur}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    className="border-0 border-b border-grey focus:border-blue-500 w-full outline-0"
                  />
                </>
              );
            }}
          />
        </div>
        <div>
          <form.Field
            name="lastName"
            onChange={(v) => (!v ? "Last name is required" : undefined)}
            children={(field) => (
              <>
                <label htmlFor={field.name} className="hidden">
                  Last Name
                </label>
                <input
                  data-test-id="create-transaction-last-name"
                  placeholder="Last Name"
                  name={field.name}
                  value={field.state.value ?? ""}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                  }}
                  className="border-0 border-b border-grey focus:border-blue-500 w-full outline-0"
                />
              </>
            )}
          />
        </div>
        <div>
          <form.Field
            name="email"
            onChange={(v) => (!v ? "Email is required" : undefined)}
            children={(field) => {
              // Avoid hasty abstractions. Render props are great!
              return (
                <>
                  <label htmlFor={field.name} className="hidden">
                    Email
                  </label>
                  <input
                    data-test-id="create-transaction-email"
                    placeholder="Email"
                    name={field.name}
                    type="email"
                    value={field.state.value ?? ""}
                    onBlur={field.handleBlur}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    className="border-0 border-b border-grey focus:border-blue-500 w-full outline-0"
                  />
                </>
              );
            }}
          />
        </div>
        <div>
          <form.Field
            name="phoneNumber"
            onChange={(v) => (!v ? "Phone Number is required" : undefined)}
            children={(field) => (
              <>
                <label htmlFor={field.name} className="hidden">
                  Phone Number
                </label>
                <PhoneInput
                  data-test-id="create-transaction-phone-number"
                  defaultCountry="US"
                  placeholder="Phone Number"
                  name={field.name}
                  value={field.state.value ?? ""}
                  onBlur={field.handleBlur}
                  onChange={(v) => {
                    field.handleChange(v ?? "");
                  }}
                  className="border-0 border-b border-grey focus:border-blue-500 w-full outline-0"
                />
              </>
            )}
          />
        </div>
      </div>

      <form.Subscribe
        children={(form) => {
          return (
            <div className="flex flex-row space-x-4 justify-end w-full">
              <Button
                variant="SECONDARY"
                onClick={() => {
                  back();
                }}
                disabled={form.isSubmitting}
                type="button"
              >
                Back
              </Button>

              <Button
                dataTestId="create-transaction-submit"
                variant="PRIMARY"
                type="submit"
                loading={form.isSubmitting}
                disabled={
                  !form.canSubmit ||
                  form.isSubmitting ||
                  !form.values["email"] ||
                  !form.values["firstName"] ||
                  !form.values["lastName"] ||
                  !form.values["phoneNumber"]
                }
              >
                Create New Transaction
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default function CreatePage() {
  const { close } = useModals();
  const { data: meData } = useQuery(meQuery());
  const [step, setStep] = useState(1);
  const dealership = useAtomValue(dealershipAtom);
  const navigate = useNavigate();

  const currentPerms = dealership?.activeDealershipPerms;
  const userId = meData?.me?.user?.id ?? "";

  const form = useForm<FormType, unknown>({
    defaultValues: {
      salesPersonId:
        currentPerms?.role === "SALES_PERSON"
          ? userId // If the logged in user is a sales person themselves, we select them
          : "",
      salesManagerId:
        currentPerms?.role === "SALES_MANAGER"
          ? userId // If the logged in user is a sales manager themselves, we select them
          : "",
      fniManagerId:
        currentPerms?.role === "FNI_MANAGER"
          ? userId // If the logged in user is a FNI manager themselves, we select them
          : "",
      bdcId:
        currentPerms?.role === "BDC"
          ? userId // If the logged in user is a BDC themselves, we select them
          : "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },

    onChange: () => {
      return undefined;
    },
    onSubmit: async (values) => {
      if (!dealership?.activeDealershipPerms.dealershipId) {
        throw new Error("No dealership activte");
      }

      const transaction = await gqlMutationClient()({
        dealershipCreateTransaction: [
          {
            customer: {
              email: values.email,
              phoneNumber: values.phoneNumber,
              firstName: values.firstName,
              lastName: values.lastName,
            },
            staff: {
              bdcId: values.bdcId,
              fniManagerId: values.fniManagerId,
              salesManagerId: values.salesManagerId,
              salesPersonId: values.salesPersonId,
            },
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationDealershipCreateTransactionSuccess": {
              data: {
                status: true,
                id: true,
              },
            },
          },
        ],
      });
      if (
        !transaction.dealershipCreateTransaction ||
        transaction.dealershipCreateTransaction.__typename === "GraphQLError"
      ) {
        toast.error(
          transaction.dealershipCreateTransaction?.message ?? "Unexpected error"
        );
        return;
      }
      if (!transaction.dealershipCreateTransaction.data.id) {
        toast.error("Got no transaction ID");
        return;
      }

      navigate("/dashboard/transaction/:transactionId", {
        params: {
          transactionId: transaction.dealershipCreateTransaction.data.id,
        },
      });
    },
  });

  return (
    <Modal
      title="New Customer Transaction"
      containerClassName="w-1/2"
      isOpen
      onClose={close}
    >
      <div className="mx-10 py-6 flex flex-col h-full">
        <Header totalSteps={2} currentStep={step}></Header>

        <form.Provider>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <div className={classNames({ hidden: step !== 1 })}>
              <Step1
                onNext={() => {
                  setStep((s) => s + 1);
                }}
                form={form}
              />
            </div>

            <div className={classNames({ hidden: step !== 2 })}>
              <Step2
                back={() => {
                  setStep((s) => s - 1);
                }}
                form={form}
              />
            </div>
          </form>
        </form.Provider>
      </div>
    </Modal>
  );
}
