import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form-old";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { FC, Fragment, useState } from "react";
import { toast } from "react-toastify";
import setStaffInformationAction from "~/actions/staffInformation/setStaffInformationAction";

import Spinner from "~/components/Spinner";
import Button from "~/components/design-system-components/Button";
import TextInput from "~/components/inputs/TextInput";
import { gqlQueryClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";

const CDKConfigurationForm: FC<{
  userId: string;
  dealershipId: string;
  cdkCrmEmployeeId?: string;
  isSuspended: boolean;
}> = ({ userId, dealershipId, cdkCrmEmployeeId, isSuspended }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm({
    defaultValues: {
      cdkCrmEmployeeId: cdkCrmEmployeeId ?? "",
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        await setStaffInformationAction(userId, values);

        setIsEditing(false);

        await queryClient.resetQueries({
          queryKey: ["dealership", dealershipId, "staff", userId],
        });
      } catch (error) {
        console.error(error);
        captureException(error);
        toast.error("Failed to update staff.");
      } finally {
        setIsSubmitting(false);
      }
    },
  });
  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form.Provider>
      <form className="flex flex-col space-y-4">
        <div className="flex flex-row items-center space-x-4">
          <h2 className="text-heading-2">CDK Configuration</h2>

          {!isEditing && !isSuspended && (
            <Button variant="GHOST" onClick={handleEdit}>
              Edit
            </Button>
          )}

          {isEditing && (
            <div className="flex flex-row space-x-2 items-center">
              <Button
                variant="GHOST"
                onClick={form.handleSubmit}
                loading={isSubmitting}
              >
                Save
              </Button>

              <span className="text-dark-gray">|</span>

              <Button
                variant="GHOST"
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>

        {!isEditing && (
          <div className="flex flex-row space-x-4">
            <p className="text-body text-dark-gray">CDK CRM Employee ID</p>

            <p
              className={classNames("text-body", {
                "text-error": !cdkCrmEmployeeId,
              })}
            >
              {cdkCrmEmployeeId ?? "Not set"}
            </p>
          </div>
        )}

        {isEditing && (
          <form.Field name="cdkCrmEmployeeId">
            {(field) => {
              return (
                <TextInput
                  fieldName={field.name}
                  value={field.state.value}
                  labelText="CDK CRM Employee ID"
                  subtitleText="CDK CRM Employee ID"
                  placeholder="ED8S988DK"
                  disabled={isSubmitting}
                  error={field.state.meta.touchedErrors.at(0)}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                  }}
                />
              );
            }}
          </form.Field>
        )}
      </form>
    </form.Provider>
  );
};

const CDKConfiguration: FC<{
  userId: string;
  dealershipId: string;
  isSuspended: boolean;
}> = ({ userId, dealershipId, isSuspended }) => {
  const { data: dealershipData, isLoading } = useQuery({
    queryKey: ["dealership", dealershipId, "staff", userId],
    queryFn: async () =>
      gqlQueryClient()({
        dealership: [
          {
            id: dealershipId,
          },
          {
            hasEnabledCdkCrm: true,
            users: [
              {
                first: 1,
                filter: {
                  userId: {
                    equals: userId,
                  },
                },
              },
              {
                edges: {
                  node: {
                    user: {
                      staffInformation: [
                        {
                          first: 1,
                        },
                        {
                          edges: {
                            node: {
                              cdkCrmEmployeeId: true,
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              },
            ],
          },
        ],
      }),
  });

  return (
    <Fragment>
      {!isLoading && dealershipData?.dealership?.hasEnabledCdkCrm && (
        <CDKConfigurationForm
          userId={userId}
          dealershipId={dealershipId}
          cdkCrmEmployeeId={
            dealershipData.dealership.users?.edges
              ?.at(0)
              ?.node?.user?.staffInformation?.edges?.at(0)?.node
              ?.cdkCrmEmployeeId ?? undefined
          }
          isSuspended={isSuspended}
        />
      )}

      {isLoading && (
        <div className="flex w-full items-center justify-center">
          <Spinner className="size-6" />
        </div>
      )}
    </Fragment>
  );
};

export default CDKConfiguration;
