import classNames from "classnames";
import ErrorOutlineIcon from "./icons/ErrorOutlineIcon";

type Props = {
  className?: string;
  children: React.ReactNode;
  variant: "WARNING"; // | "ERROR" | "SUCCESS" | "DEFAULT";
};

/**
 * @deprecated: Use the Banner from the shared component library
 */
export default function Banner({ children, variant, className }: Props) {
  return (
    <div
      className={classNames(
        "py-2 px-4 flex space-x-4 align-middle rounded-lg text-primary border",
        className,
        {
          "bg-warning-light border-warning-dark": variant === "WARNING",
        }
      )}
    >
      {variant === "WARNING" && (
        <ErrorOutlineIcon className="text-warning-dark w-6 h-6" />
      )}
      {children}
    </div>
  );
}
