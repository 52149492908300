import { g } from "~/globals";
import {
  fetchHomenetVehicleDataByStockNumber,
  PrefillVehicleData,
} from "./utils/fetchHomenetByStocknumberQuery";

export interface PrefillVehicleInterface {
  // Definite this way indicates class membership
  fetchCandidate(
    dealershipId?: string,
    stockNumber?: string
  ): Promise<PrefillVehicleData[]>;
}

/**
 * This implementation only for initialization
 * It is expected to return an empty array
 */
export class NullPrefillVehicle implements PrefillVehicleInterface {
  async fetchCandidate(_dealershipId: string, _stockNumber: string) {
    return [];
  }
}

/**
 * Demo Class  return Demo data, this is used in staging or other non prodcution env
 * We can't use real production data in non Production envs (staging, dev)
 */
export class DemoPrefillVehicle implements PrefillVehicleInterface {
  async fetchCandidate(_dealershipId: string, stockNumber: string) {
    const { demoPrefillVehicleData } = await import("./utils/vehicleDemoData");
    const filteredData =
      stockNumber.length === 0
        ? demoPrefillVehicleData
        : demoPrefillVehicleData.filter(
            (vehicle) =>
              typeof vehicle.stockNumber === "string" &&
              stockNumber.includes(vehicle.stockNumber)
          );
    return filteredData;
  }
}

// Production class
export class PrefillVehicle implements PrefillVehicleInterface {
  async fetchCandidate(dealershipId: string, stockNumber: string) {
    const response = await g.reg.queryClient.fetchQuery(
      fetchHomenetVehicleDataByStockNumber(dealershipId, stockNumber)
    );

    const edges = response?.dealership?.homenetVehicles?.edges ?? [];
    const resp: PrefillVehicleData[] = [];
    // Since it is a `relatedConnection` edge.node can be undefined
    // This is done to filter out the undefined and ensure same uniform values returned
    for (const edge of edges) {
      if (edge.node) {
        resp.push(edge.node);
      }
    }

    return resp;
  }
}
