import { Menu, Transition } from "@headlessui/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Button, SelectBase } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import classNames from "classnames";
import { useAtom } from "jotai";
import { Fragment } from "react";
import { Outlet } from "react-router";
import logoutAction from "~/actions/logoutAction";
import LogoIcon from "~/components/icons/LogoIcon";
import { getInitials } from "~/lib/helpers";
import dealershipQuery from "~/query/dealershipQuery";
import meQuery from "~/query/meQuery";
import { Link, useNavigate } from "~/router";
import { dealershipAtom } from "~/state";

export default function Layout() {
  const { data } = useQuery(meQuery());
  const [dealership, setDealership] = useAtom(dealershipAtom);
  const dealershipId = dealership?.activeDealershipPerms.dealershipId;
  const navigate = useNavigate();

  const me = data?.me;

  const changeDealership = (v: string) => {
    const dp = me?.dealershipPerms?.find((dshp) => dshp.dealershipId === v);

    if (!dp) {
      return;
    }

    setDealership((r) => ({
      ...r,
      activeDealershipPerms: {
        ...dp,
        dealershipId: dp.dealershipId ?? "no-dealership-id",
        role: dp.role ?? "SALES_PERSON", // This should never happen
        dealershipName: dp.dealershipName ?? "no-dealership-name",
      },
    }));
  };

  const canManageUser = permissionChecker(
    "manageUsers",
    dealership?.activeDealershipPerms
  );

  const canViewReports = permissionChecker(
    "viewReports",
    dealership?.activeDealershipPerms
  );

  const dealershipOptions =
    me?.dealershipPerms?.map((dp) => {
      return {
        label: dp.dealershipName ?? "no-dealership-name",
        value: dp.dealershipId ?? "no-dealership-id",
      };
    }) ?? [];

  const { data: dealerData } = useQuery(dealershipQuery(dealershipId));

  return (
    <main className="flex min-h-dvh max-h-dvh flex-col space-y-spacing-06 bg-gray-100 font-roboto px-16 overflow-y-scroll">
      <nav className="flex flex-col items-center space-y-11">
        <div className="flex flex-row">
          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-gray-100 flex flex-1 rounded-tr-full" />
          </div>

          <div className="font-medium text-primary-blue bg-very-light-blue px-6 rounded-b-3xl">
            {(me?.dealershipPerms?.length ?? 0) >= 2 ? (
              <SelectBase
                value={dealership?.activeDealershipPerms.dealershipId}
                label={"Select Dealership"}
                placeholder={"Select Dealership"}
                required={false}
                disabled={false}
                onSelect={(option) => {
                  changeDealership(option.value);
                }}
                options={dealershipOptions}
                dataTestId="group-admin-dealership-dropdown"
                inputElement={(open) => {
                  return (
                    <div
                      className={
                        "flex justify-between items-center w-[160px] px-spacing-03 py-spacing-03 bg-transparent hover:text-interactive"
                      }
                    >
                      {dealershipId && dealershipId.length > 0
                        ? (dealershipOptions.find(
                            (o) => o.value === dealershipId
                          )?.label ?? dealershipId)
                        : "Select A Dealership"}
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  );
                }}
              />
            ) : (
              <p className="px-spacing-03 py-spacing-03">
                {dealership?.activeDealershipPerms.dealershipName}{" "}
              </p>
            )}
          </div>

          <div className="flex bg-very-light-blue w-6 h-6">
            <div className="bg-gray-100 flex flex-1 rounded-tl-full" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row space-x-6">
            <Link to={"/dashboard"} className="relative w-24">
              <LogoIcon className="h-12 text-primary-blue" />
            </Link>
          </div>

          <div className="flex flex-row space-x-6 items-center">
            <Button
              onClick={() => {
                void navigate("/dashboard/customers");
              }}
              label="Consumers"
              variant="SECONDARY"
              size="MEDIUM"
              data-test-id={"customers-page-button"}
            />

            {canManageUser && (
              <Button
                onClick={() => {
                  void navigate("/dashboard/staff");
                }}
                data-test-id={"staff-page-button"}
                label="Staff"
                variant="SECONDARY"
                size="MEDIUM"
              />
            )}

            {dealerData?.dealership?.hasEnabledReportingDashboard &&
              canViewReports && (
                <Button
                  onClick={() => {
                    void navigate("/dashboard/reports");
                  }}
                  data-test-id={"reports-page-button"}
                  label="Reports"
                  variant="SECONDARY"
                  size="MEDIUM"
                />
              )}

            <div className="flex">
              <Menu
                as="div"
                className="inline-block relative text-left font-roboto"
              >
                <Menu.Button className="flex w-14 aspect-square bg-light-gray items-center justify-center text-base text-dark-gray font-medium rounded-full overflow-hidden focus:outline-none">
                  {/* We don't have the concept of a profile picture, but one could use the active transaction customers profile picture here.
                  TODO: Figure out what to do. */}
                  {/* {me?.user.profilePicture?.url ? (
                    <img
                      src={me.user.profilePicture.url}
                      className="w-full h-full"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <span>
                      {getInitials(me?.user.firstName, me?.user.lastName)}
                    </span>
                  )} */}

                  <span>
                    {getInitials(me?.user?.firstName, me?.user?.lastName)}
                  </span>
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-light-gray rounded-lg bg-white shadow-lg focus:outline-none w-40 z-dropdown">
                    <Menu.Item>
                      {({ active }) => (
                        <Link to={"/profile"}>
                          <button
                            className={classNames(
                              "text-body w-full px-4 py-2 text-start text-dark-gray",
                              { "bg-very-light-blue": active }
                            )}
                          >
                            Profile
                          </button>
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => void logoutAction()}
                          className={classNames(
                            "text-body w-full px-4 py-2 text-start text-dark-gray",
                            { "bg-very-light-blue": active }
                          )}
                        >
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </main>
  );
}
