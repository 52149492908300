import { isAfter } from "date-fns";
import { FormCollectionStatus } from "~/__generated__/backend/zeus";
import { getDateFromUnknown } from "./helpers";

/**
 * This function is used to calculate the state of the compliance forms on a transaction.
 *
 * We are doing this client-side as it would require multiple database calls or complex persistence or caching on the backend.
 *
 * We are using these values in different parts of the application. So in order to keep the code readable and consistent,
 * we are using this lib function.
 *
 * @param prePurchaseCollection: The **latest** pre-purchase collection that has been requested
 * @param postPurchaseCollection: The **latest** post-purchase collection that has been requested
 * @param latestTransactionLogThatShouldRetriggerComplianceDocs: The latest transaction log that should retrigger the compliance docs
 * @param hasEnabledPostPurchaseDocs: Whether the dealership has the post-purchase forms feature enabled or not
 *
 * @returns requestedPrePurchaseFormsAt: When the pre-purchase docs have been requested
 * @returns requestedPostPurchaseFormsAt: When the post-purchase docs have been requested
 * @returns hasInvalidPrePurchaseForms: Whether there are invalid pre-purchase forms
 * @returns hasInvalidPostPurchaseForms: Whether there are invalid post-purchase forms
 * @returns hasCompletedPrePurchaseForms: Whether the pre-purchase forms have been completed
 * @returns hasCompletedPostPurchaseForms: Whether the post-purchase forms have been completed
 */
export const getFormStatus = ({
  prePurchaseCollection,
  postPurchaseCollection,
  latestTransactionLogThatShouldRetriggerComplianceDocs,
  hasEnabledPostPurchaseDocs,
}: {
  /**
   * Using hard coded types as we only use one or two props so using the
   * generated GraphQL types is excessive and harder to read, especially,
   * since we would need to overwrite the DateTime with unknown
   */
  prePurchaseCollection?: {
    status?: FormCollectionStatus | null;
    createdAt?: unknown;
  };
  postPurchaseCollection?: {
    status?: FormCollectionStatus | null;
    createdAt?: unknown;
  };
  latestTransactionLogThatShouldRetriggerComplianceDocs?: {
    createdAt?: unknown;
  };
  hasEnabledPostPurchaseDocs: boolean;
}): {
  requestedPrePurchaseFormsAt?: Date;
  requestedPostPurchaseFormsAt?: Date;
  hasInvalidPrePurchaseForms: boolean;
  hasInvalidPostPurchaseForms: boolean;
  hasCompletedPrePurchaseForms: boolean;
  hasCompletedPostPurchaseForms: boolean;
} => {
  /**
   * When were pre-purchase forms requested?
   */
  const requestedPrePurchaseFormsAt = getDateFromUnknown(
    prePurchaseCollection?.createdAt
  );
  /**
   * When were post-purchase forms requested?
   */
  const requestedPostPurchaseFormsAt = getDateFromUnknown(
    postPurchaseCollection?.createdAt
  );
  /**
   * When did the last change occur that should retrigger compliance docs?
   */
  const latestTransactionLogThatShouldRetriggerComplianceDocsCreatedAt =
    getDateFromUnknown(
      latestTransactionLogThatShouldRetriggerComplianceDocs?.createdAt
    );
  /**
   * Are there invalid pre-purchase forms?
   */
  const hasInvalidPrePurchaseForms =
    latestTransactionLogThatShouldRetriggerComplianceDocsCreatedAt &&
    requestedPrePurchaseFormsAt
      ? isAfter(
          latestTransactionLogThatShouldRetriggerComplianceDocsCreatedAt,
          requestedPrePurchaseFormsAt
        )
      : false;
  /**
   * Are there invalid post-purchase forms?
   */
  const hasInvalidPostPurchaseForms = (() => {
    if (!hasEnabledPostPurchaseDocs) {
      return false;
    }

    if (
      latestTransactionLogThatShouldRetriggerComplianceDocsCreatedAt &&
      requestedPostPurchaseFormsAt
    ) {
      return isAfter(
        latestTransactionLogThatShouldRetriggerComplianceDocsCreatedAt,
        requestedPostPurchaseFormsAt
      );
    }

    return false;
  })();
  /**
   * Has completed pre-purchase forms?
   */
  const hasCompletedPrePurchaseForms =
    prePurchaseCollection?.status === FormCollectionStatus.FINAL;
  /**
   * Has completed post-purchase forms?
   */
  const hasCompletedPostPurchaseForms = hasEnabledPostPurchaseDocs
    ? postPurchaseCollection?.status === FormCollectionStatus.FINAL
    : true;

  return {
    requestedPrePurchaseFormsAt,
    requestedPostPurchaseFormsAt,
    hasInvalidPrePurchaseForms,
    hasInvalidPostPurchaseForms,
    hasCompletedPrePurchaseForms,
    hasCompletedPostPurchaseForms,
  };
};
