import { z } from "zod";
import { UserDealershipSchema } from "../codecs/schema/user";
import { UserRole } from "../codecs/tdc";
import { Permission } from "./permissions";

type AndPermission = { and: Permissions[] };
function isAndPermission(perm: any): perm is AndPermission {
  return perm.and !== undefined;
}

type OrPermission = { or: Permissions[] };
function isOrPermission(perm: any): perm is OrPermission {
  return perm.or !== undefined;
}

export type Permissions = Permission | AndPermission | OrPermission;

const PermissoinCheckerUd = UserDealershipSchema.partial().required({
  role: true,
});
type PermissoinCheckerUd = z.TypeOf<typeof PermissoinCheckerUd>;

export const permissionChecker = (
  permissions: Permissions,
  ud?: PermissoinCheckerUd
): boolean => {
  if (!ud) {
    return false;
  }
  if (isAndPermission(permissions)) {
    return permissions.and.reduce(
      (p, c) => p && permissionChecker(c, ud),
      true
    );
  } else if (isOrPermission(permissions)) {
    return permissions.or.reduce(
      (p, c) => p || permissionChecker(c, ud),
      false
    );
  } else {
    return checkPermission(permissions, ud);
  }
};

const checkPermission = (perm: Permission, ud: { role: UserRole }): boolean => {
  let positiveRoles: UserRole[] = [];
  let negativeRoles: UserRole[] = [];
  switch (perm) {
    case "assignStaff":
      negativeRoles = ["CUSTOMER"];
      return !negativeRoles.includes(ud.role);

    case "uploadFileToTransction":
      negativeRoles = ["BDC", "CUSTOMER", "ADMIN"];
      return !negativeRoles.includes(ud.role);

    case "viewAllTransactions":
      positiveRoles = ["SALES_MANAGER", "ADMIN", "BDC", "FNI_MANAGER"];
      return positiveRoles.includes(ud.role);

    case "createTransction":
      positiveRoles = [
        "FNI_MANAGER",
        "BDC",
        "SALES_MANAGER",
        "SALES_PERSON",
        "ADMIN",
      ];
      return positiveRoles.includes(ud.role);

    // For Sales Person & F&I Manager can update transaction
    // But only for assigned transaction
    // This checks is done in the auth resolver
    case "updateTransaction":
      positiveRoles = ["BDC", "SALES_MANAGER", "ADMIN"];
      return positiveRoles.includes(ud.role);

    case "pushHardCreditApplication":
      positiveRoles = ["FNI_MANAGER", "SALES_MANAGER", "ADMIN"];
      return positiveRoles.includes(ud.role);

    case "viewAllCustomers":
      positiveRoles = ["FNI_MANAGER", "SALES_MANAGER", "SALES_PERSON", "ADMIN"];
      return positiveRoles.includes(ud.role);

    case "printDealerJacket":
      positiveRoles = ["ADMIN", "FNI_MANAGER", "SALES_MANAGER"];
      return positiveRoles.includes(ud.role);

    case "editCustomerDatabaseProfile":
      positiveRoles = ["ADMIN", "SALES_MANAGER"];
      return positiveRoles.includes(ud.role);

    case "viewCreditReports":
      negativeRoles = ["BDC", "CUSTOMER", "SALES_PERSON"];
      return !negativeRoles.includes(ud.role);

    case "downloadDealJacket":
      negativeRoles = ["BDC", "SALES_PERSON"];
      return !negativeRoles.includes(ud.role);

    case "viewDealJacket":
      negativeRoles = ["BDC"];
      return !negativeRoles.includes(ud.role);

    case "manageUsers":
      positiveRoles = ["ADMIN", "SALES_MANAGER"];
      return positiveRoles.includes(ud.role);

    case "pushFileToCdkDealJacket":
      positiveRoles = ["ADMIN", "FNI_MANAGER", "SALES_MANAGER"];
      return positiveRoles.includes(ud.role);

    case "groupViewAllDealerships":
      positiveRoles = ["ADMIN"];
      return positiveRoles.includes(ud.role);

    case "requestSignatures":
      negativeRoles = ["BDC"];
      return !negativeRoles.includes(ud.role);

    case "viewReports":
      positiveRoles = ["ADMIN"];
      return positiveRoles.includes(ud.role);

    default:
      return false;
  }
};
