import { useQuery } from "@tanstack/react-query";
import {
  FinanceType,
  TransactionSource,
  TransactionStatus,
  VehicleLifeCycleStage,
} from "~/__generated__/backend/zeus";
import { transactionAnalyticQuery } from "../reports/_queries/analyticsQuery";

/**
 * This hooks exists to reduce boilder plate
 * This is used in Reporting Dashboard & Main Dashboard (Banner)
 */
export const useGetAnalytics = ({
  dealershipId,
  startDate,
  endDate,
  isLbo,
  source,
  financeType,
  purchaseVehicleLifeCycleStage: vehicleLifeCycleStage,
  transactionStatus,
}: {
  dealershipId?: string;
  startDate?: Date;
  endDate?: Date;
  isLbo?: boolean;
  source?: TransactionSource[];
  financeType?: FinanceType[];
  purchaseVehicleLifeCycleStage?: VehicleLifeCycleStage;
  transactionStatus: TransactionStatus[];
}): {
  count?: number;
  isLoading: boolean;
} => {
  // Execute the query using your query function
  const { data, isLoading } = useQuery(
    transactionAnalyticQuery({
      dealershipId,
      startDate,
      endDate,
      isLbo,
      source,
      financeType,
      purchaseVehicleLifeCycleStage: vehicleLifeCycleStage,
      transactionStatus,
    })
  );

  // Extract the analytics count from the data
  const count =
    data?.dealership?.transactionStatusUpdateDealershipAnalyticsCount ?? 0;

  return { count, isLoading };
};
