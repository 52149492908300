import { Role } from "~/__generated__/backend/zeus";
import { gqlMutationClient } from "~/lib/backend";

const updateUserDealershipRoleAction = async (userId: string, role: Role) => {
  const resp = await gqlMutationClient()({
    updateStaffAttributes: [
      {
        userId,
        attributes: { role },
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationUpdateStaffAttributesSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (
    !resp.updateStaffAttributes ||
    resp.updateStaffAttributes.__typename === "GraphQLError"
  ) {
    throw new Error(resp.updateStaffAttributes?.message ?? "Unexpected error");
  }

  return resp.updateStaffAttributes.data;
};

export default updateUserDealershipRoleAction;
