import { useSuspenseQuery } from "@tanstack/react-query";
import {
  LogEventPersistedSchema,
  logToDesc,
} from "@thedealersconcierge/lib/codecs/event-logs";
import classNames from "classnames";
import { format } from "date-fns";
import { FC } from "react";
import { OrderBy } from "~/__generated__/backend/zeus";
import ClockIcon from "~/components/icons/ClockIcon";
import { gqlQueryClient } from "~/lib/backend";
import { getDateFromUnknown } from "~/lib/helpers";

const TransactionHistory: FC<{
  transactionId: string;
  className?: string;
}> = ({ className, transactionId }) => {
  // We load transaction here

  const { data } = useSuspenseQuery({
    queryKey: ["transactionLogs", transactionId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            id: true,
            logs: [
              {
                first: 20,
                orderBy: {
                  createdAt: OrderBy.Desc,
                },
              },
              {
                edges: {
                  node: {
                    event: true,
                    createdAt: true,
                    executingUser: {
                      firstName: true,
                      lastName: true,
                    },
                  },
                },
              },
            ],
          },
        ],
      }),
  });

  return (
    <div
      className={classNames(
        "rounded-2xl bg-white shadow-md p-8 space-y-8",
        className
      )}
    >
      <div className="flex flex-row space-x-2 items-center">
        <div className="relative">
          <ClockIcon className="w-5 aspect-square text-dark-gray" />
        </div>

        <h2 className="text-heading-2 text-very-dark-gray">History</h2>
      </div>

      {data.transaction?.logs?.edges?.length ? (
        <table className="w-full">
          <thead>
            <tr className="border-b border-light-gray">
              <td className="py-5 px-6 uppercase font-medium w-56">Date</td>

              <td className="py-5 px-6 uppercase font-medium w-56">User</td>

              <td className="py-5 px-6 uppercase font-medium">Action</td>
            </tr>
          </thead>

          <tbody className="px-10">
            {data.transaction?.logs.edges.map((log, idx) => {
              const d = getDateFromUnknown(log.node?.createdAt);
              const createdAt = d ? format(d, "MM/dd/yy hh:mm a") : "";
              return (
                <tr
                  className="even:bg-very-light-gray even:border even:border-light-gray"
                  key={idx}
                >
                  <td className="py-5 px-6 text-block font-medium text-dark-gray">
                    {createdAt}
                  </td>

                  <td className="py-5 px-6 text-block font-medium text-very-dark-gray">
                    {log.node?.executingUser
                      ? `${log.node?.executingUser.firstName ?? ""} ${log.node?.executingUser.lastName ?? ""}`
                      : "System"}
                  </td>

                  <td className="py-5 px-6 text-block font-medium text-very-dark-gray">
                    {logToDesc(LogEventPersistedSchema.parse(log.node?.event))}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="text-body text-dark-gray">No transaction logs yet</div>
      )}
    </div>
  );
};

export default TransactionHistory;
