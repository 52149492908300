import stringify from "json-stable-stringify";
import {
  FormSubmissionStatus,
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";
import { Pagination } from "~/pages/(authenticated)/dashboard/_dashboardUtils";

const formSubmissionSelector = Selector("FormSubmission")({
  id: true,
  status: true,
  access: true,
  formSubmissionData: true,
  userId: true,
  file: {
    id: true,
    url: true,
  },
  form: {
    displayTitle: true,
  },
  customer: {
    firstName: true,
    lastName: true,
  },
  createdAt: true,
  type: true,
  cdkDealJacketStatus: true,
});

const documentSelector = Selector("Document")({
  id: true,
  access: true,
  transactionId: true,
  title: true,
  category: true,
  createdAt: true,
  userId: true,
  file: {
    id: true,
    url: true,
  },
  customer: {
    firstName: true,
    lastName: true,
  },
  cdkDealJacketStatus: true,
  pdfIsEncrypted: true,
});

export const DealJacketTransactionSelector = (
  transactionId: string,
  fsPagination: Pagination,
  docPagination: Pagination
) => {
  return Selector("Query")({
    transaction: [
      {
        id: transactionId,
      },
      {
        id: true,
        buyerId: true,
        status: true,
        formSubmissions: [
          {
            first:
              fsPagination.direction === "after"
                ? fsPagination.pageSize
                : undefined,
            last:
              fsPagination.direction === "before"
                ? fsPagination.pageSize
                : undefined,
            after:
              fsPagination.direction === "after"
                ? fsPagination.cursor
                : undefined,
            before:
              fsPagination.direction === "before"
                ? fsPagination.cursor
                : undefined,
            filter: {
              status: {
                notIn: [
                  FormSubmissionStatus.PARTIAL,
                  FormSubmissionStatus.CANCELLED,
                ],
              },
            },
            orderBy: {
              createdAt: OrderBy.Desc,
            },
          },
          {
            totalCount: true,
            edges: {
              cursor: true,
              node: formSubmissionSelector,
            },
          },
        ],
        cdkDmsDealId: true,
        documents: [
          {
            first:
              docPagination.direction === "after"
                ? docPagination.pageSize
                : undefined,
            last:
              docPagination.direction === "before"
                ? docPagination.pageSize
                : undefined,
            after:
              docPagination.direction === "after"
                ? docPagination.cursor
                : undefined,
            before:
              docPagination.direction === "before"
                ? docPagination.cursor
                : undefined,
            orderBy: {
              createdAt: OrderBy.Desc,
            },
          },
          {
            totalCount: true,
            edges: {
              cursor: true,
              node: documentSelector,
            },
          },
        ],
        title: true,
        dealership: {
          hasEnabledScanner: true,
          hasEnabledCdkDms: true,
          hasEnabledPostPurchaseDocs: true,
          hasTdcCdkAppSubscriptionId: true,
        },
      },
    ],
  });
};

export const dealJacketQuery = (
  transactionId: string,
  fsPagination: Pagination,
  docPagination: Pagination
) => {
  return {
    queryKey: [
      "transaction-dealjacket",
      transactionId,
      stringify(fsPagination),
      stringify(docPagination),
    ],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: async () =>
      gqlQueryClient()(
        DealJacketTransactionSelector(
          transactionId,
          fsPagination,
          docPagination
        )
      ),
  };
};

export type DealJacketQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof DealJacketTransactionSelector>
>;
