import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import {
  fetchMFAPreference,
  FetchMFAPreferenceOutput,
  setUpTOTP,
  updateMFAPreference,
  verifyTOTPSetup,
} from "aws-amplify/auth";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "~/components/Button";
import TextInput from "~/components/design-system-components/input/TextInput";
import Checkbox from "~/components/inputs/Checkbox";
import Spinner from "~/components/Spinner";
import config from "~/config";

export default function Profile() {
  const { t } = useTranslation();

  const { data: preference, refetch } =
    useSuspenseQuery<FetchMFAPreferenceOutput>({
      refetchOnWindowFocus: false,
      queryKey: ["cognito", "fetchMFAPreference"],
      queryFn: () => {
        if (config.useCognito) return fetchMFAPreference();
        return {};
      },
    });

  const [totpCode, setTotpCode] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [selectedPrefMFA, setSelectedPrefMFA] = useState<
    "SMS" | "TOTP" | "EMAIL"
  >(preference?.preferred ?? "SMS");

  const setupApp = !preference?.enabled?.includes("TOTP");

  const { data: totpUri } = useQuery({
    enabled: setupApp,
    queryKey: ["cognito", "setUpTOTP"],
    queryFn: async () => {
      const totpSetupDetails = await setUpTOTP();
      const appName = "TDC Dashboard";
      const setupUri = totpSetupDetails.getSetupUri(appName);

      return [setupUri.toString(), totpSetupDetails.sharedSecret];
    },
  });

  const setPreferredMFA = async () => {
    try {
      setSaving(true);
      await updateMFAPreference({
        sms: selectedPrefMFA === "SMS" ? "PREFERRED" : "ENABLED",
        totp: selectedPrefMFA === "TOTP" ? "PREFERRED" : "ENABLED",
      });

      toast.success(t("MFA preference updated"));
    } catch (e) {
      toast.error(t("Failed to update MFA preference"));
    } finally {
      setSaving(false);
    }
  };

  const verifyTotp = async () => {
    if (!totpCode) {
      throw new Error("TOTP code is required");
    }
    try {
      setVerifying(true);
      await verifyTOTPSetup({ code: totpCode });

      // We set the preference immediately, so the user can use it and to avoid showing the TOTP setup again
      await updateMFAPreference({
        sms: selectedPrefMFA === "SMS" ? "PREFERRED" : "ENABLED",
        totp: selectedPrefMFA === "TOTP" ? "PREFERRED" : "ENABLED",
      });

      // Refresh the data, so we can see that it is enabled
      void refetch();
      toast.success(t("TOTP setup successful"));
    } catch (e) {
      toast.error(t("Failed to verify TOTP setup"));
    } finally {
      setVerifying(false);
    }
  };

  const enableSave = preference?.enabled?.includes("TOTP");

  return (
    <div className="flex justify-center p-8">
      <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
        <h1 className="text-heading-1">{t("Profile")}</h1>
        <hr></hr>
        <h2 className="text-heading-2">{t("Security")}</h2>

        <div
          className="flex flex-row space-x-2 cursor-pointer"
          onClick={() => {
            setSelectedPrefMFA("SMS");
          }}
        >
          <Checkbox
            onChange={(set) => {
              set ? setSelectedPrefMFA("SMS") : undefined;
            }}
            value={selectedPrefMFA === "SMS"}
            variant="RADIO_BUTTON"
            inputId="set-sms"
          />
          <p>Prefer SMS Code</p>
        </div>

        <div
          className="flex flex-row space-x-2 cursor-pointer"
          onClick={() => {
            setSelectedPrefMFA("TOTP");
          }}
        >
          <Checkbox
            onChange={(set) => {
              set ? setSelectedPrefMFA("TOTP") : undefined;
            }}
            value={selectedPrefMFA === "TOTP"}
            variant="RADIO_BUTTON"
            inputId="set-totp"
          />
          <p>Prefer Authenticator App</p>
        </div>

        {selectedPrefMFA === "TOTP" && setupApp && (
          <div className="flex flex-col space-y-2">
            <p>1. Scan the QR code with your authenticator app</p>

            {totpUri && (
              <div className="w-full flex flex-col space-y-2 items-center">
                <QRCodeSVG value={totpUri[0]} size={256} />
                <p>
                  Or click{" "}
                  <a href={totpUri[0]} className="hover:underline">
                    here
                  </a>{" "}
                  to setup.
                </p>
                <div className="flex space-x-1">
                  <p>secret:</p> <pre>{totpUri[1]}</pre>
                </div>
              </div>
            )}
            {!totpUri && <Spinner />}

            <p>2. Enter code from the app</p>
            <TextInput placeholder="Code" onChange={setTotpCode} />

            <Button
              variant="PRIMARY"
              onClick={() => void verifyTotp()}
              loading={verifying}
              disabled={verifying}
            >
              {t("Configure App")}
            </Button>
          </div>
        )}

        <Button
          variant="PRIMARY"
          onClick={() => void setPreferredMFA()}
          loading={saving}
          disabled={saving || !enableSave}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
}
