import { useQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Selector } from "~/__generated__/backend/zeus";
import DataIndicator from "~/components/DataIndicator";
import Modal from "~/components/Modal";
import { gqlQueryClient } from "~/lib/backend";
import { useModals, useParams } from "~/router";

const selector = (customerId: string, transactionId: string) =>
  Selector("Query")({
    customer: [
      {
        userId: customerId,
        transactionId: transactionId,
      },
      {
        selfieCapture: {
          url: true,
        },
        user: {
          email: true,
        },
        idCards: {
          id: true,
          state: true,
          type: true,
          issueDateOnly: true,
          expirationDateOnly: true,
          idNumber: true,
          firstName: true,
          lastName: true,
          middleName: true,
          dobDateOnly: true,
          suffix: true,
          stateInAddress: true,
          streetInAddress: true,
          zipCodeInAddress: true,
          captureBack: {
            url: true,
          },
          captureFront: {
            url: true,
          },

          class: true,
          eyeColor: true,
          height: true,
          sex: true,
        },
      },
    ],
  });

// No pun intended
const KeyBox: FC<{ title: string }> = ({ title }) => (
  <div className="opacity-60">{title}</div>
);
const ValueBox: FC<{ value?: string | null }> = ({ value }) => (
  <div className="truncate overflow-ellipsis" title={value ?? undefined}>
    {value ?? "-"}
  </div>
);

const DataIndicatorBox: FC<{
  done?: boolean;
  title: string;
  subscript: string;
}> = ({ done, title, subscript }) => (
  <div className="flex-1">
    <div className="flex flex-row">
      <div className="h-full justify-center items-center p-2">
        <DataIndicator val={done} />
      </div>

      <div className="flex flex-col">
        <div className="font-bold">{title}</div>
        <div className="text-sm font-light text-[#221F1F]">{subscript}</div>
      </div>
    </div>
  </div>
);

export default function IdModalPage() {
  const { close } = useModals();
  const { t } = useTranslation();
  const { transactionId, userId } = useParams(
    "/dashboard/transaction/:transactionId/customer/:userId"
  );
  const { data } = useQuery({
    queryKey: ["customerIdInfo", userId, transactionId],
    queryFn: async () => gqlQueryClient()(selector(userId, transactionId)),
  });

  const c = data?.customer;

  const idCard = c?.idCards?.[0];

  return (
    <Modal title="ID" isOpen onClose={close}>
      <div className="flex flex-row m-6 space-x-4">
        <div className="flex flex-col rounded-xl bg-[#EFF0EF] w-[300px] space-y-6 p-6 text-[#656666]">
          {c?.selfieCapture?.url && (
            <div className="flex flex-col space-y-2">
              <div>{t("Photo")}</div>

              <img
                className="object-contain h-[150px] w-full object-left"
                src={c.selfieCapture.url}
              />
            </div>
          )}

          {c?.idCards?.[0]?.captureFront?.url && (
            <div className="flex flex-col space-y-2">
              <div>{t("Front of ID")}</div>
              <div className="flex relative rotate-90">
                <img
                  src={c.idCards[0]?.captureFront?.url}
                  className="object-contain w-full aspect-square"
                  alt="Capture front"
                />
              </div>
            </div>
          )}
          {c?.idCards?.[0]?.captureBack?.url && (
            <div className="flex flex-col space-y-2">
              <div>{t("Back of ID")}</div>

              <div className="flex relative rotate-90">
                <img
                  src={c.idCards[0]?.captureBack?.url}
                  className="object-contain w-full aspect-square"
                  alt={t("Capture back")}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4 flex-1">
          <div className="flex flex-row space-x-4">
            <div className="font-bold flex-1">
              {/* Column 1, top */}
              {t("ID Card Information")}
            </div>
            <div className="flex-1">{/* Column 2, top */}</div>
          </div>
          <div className="flex flex-row space-x-4">
            <div className="flex-1 grid grid-cols-2 auto-cols-auto space-y-2">
              <KeyBox title="ID Type" />
              <ValueBox value={idCard?.type} />

              <KeyBox title="ID Number" />
              <ValueBox value={idCard?.idNumber} />

              <KeyBox title="ID State" />
              <ValueBox value={idCard?.state} />

              <KeyBox title="Date of Birth" />
              <ValueBox
                value={
                  idCard?.dobDateOnly
                    ? typeof idCard.dobDateOnly === "string"
                      ? idCard.dobDateOnly
                      : undefined
                    : undefined
                }
              />

              <KeyBox title="Issue Date" />
              <ValueBox
                value={
                  idCard?.issueDateOnly
                    ? typeof idCard.issueDateOnly === "string"
                      ? idCard.issueDateOnly
                      : undefined
                    : undefined
                }
              />
              <KeyBox title="Expiration Date" />
              <ValueBox
                value={
                  idCard?.expirationDateOnly
                    ? typeof idCard.expirationDateOnly === "string"
                      ? idCard.expirationDateOnly
                      : undefined
                    : undefined
                }
              />

              {/* Name stuff */}
              <KeyBox title={t("First Name")} />
              <ValueBox value={idCard?.firstName} />

              <KeyBox title={t("Middle name")} />
              <ValueBox value={idCard?.middleName} />

              <KeyBox title={t("Last name")} />
              <ValueBox value={idCard?.lastName} />

              <KeyBox title={t("Suffix")} />
              <ValueBox value={idCard?.suffix} />
            </div>
            <div className="flex-1">
              <div className="grid grid-cols-2 space-y-2">
                <KeyBox title="Email" />
                <ValueBox value={c?.user?.email} />

                <KeyBox title={t("Street Address")} />
                <ValueBox value={idCard?.streetInAddress} />

                <KeyBox title={t("State")} />
                <ValueBox value={idCard?.stateInAddress} />

                <KeyBox title={t("Zip Code")} />
                <ValueBox value={idCard?.zipCodeInAddress} />

                <KeyBox title={t("Class Type")} />
                <ValueBox value={idCard?.class} />

                <KeyBox title={t("Sex")} />
                <ValueBox value={idCard?.sex} />
                <KeyBox title={t("Height")} />
                <ValueBox value={idCard?.height} />
                <KeyBox title={t("Eye Color")} />
                <ValueBox value={idCard?.eyeColor} />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="font-bold">
              {/* Column 1, top */}
              {t("ID Verification")}
            </div>

            <DataIndicatorBox
              done
              title="Date"
              subscript="The Date Validity Of The Document"
            />

            <DataIndicatorBox
              title="Document"
              subscript="The Front and Back Validity of the Information on the Physical Document Using Barcode Decryption Technology"
            />

            <DataIndicatorBox
              title="Identity"
              subscript="The Validation of the Document Using Facial Recognition"
            />

            <DataIndicatorBox
              title="Data"
              subscript="The Identity Data on the Document Against Credit Bureau Databases"
            />
          </div>
        </div>
      </div>

      <div className="border-b border-[#E5E5E5]" />
    </Modal>
  );
}
